import { useMemo } from 'react'

import useSWR from 'swr'

export const useGenres = ({ mapById = false, metadataFields = [] } = {}) => {
  const queries = []

  const metadata = metadataFields?.join(',')
  if (metadata?.length) queries.push(`metadata=${metadata}`)

  const key = '/api/genres?' + queries.join('&')
  const { data, isValidating, error, ...rest } = useSWR(key)

  const genres = useMemo(() => {
    if (!mapById) return data

    return data?.reduce((acc, curr) => {
      return { ...acc, [curr._id]: curr }
    }, {})
  }, [data, mapById])

  return {
    genres,
    isLoading: isValidating && !data,
    isError: !!error,
    ...rest,
  }
}

export const useGenreById = (id) => {
  const { data, isValidating, ...rest } = useSWR(id && `/api/genres/${id}`)

  return {
    genre: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useGenresNodes = (genres) =>
  useMemo(
    () =>
      genres?.map((genre) => ({
        id: genre._id,
        label: genre.name,
      })),
    [genres]
  )

export const useGenresEdges = (genres) =>
  useMemo(
    () =>
      genres?.reduce((acc, curr) => {
        const newEdges = curr.relatedGenres.map((relatedGenreId) => ({
          from: curr._id,
          to: relatedGenreId,
        }))
        return [...acc, ...newEdges]
      }, []),
    [genres]
  )

export const useGenresGraph = (genres) => {
  const nodes = useGenresNodes(genres)
  const edges = useGenresEdges(genres)

  return useMemo(
    () => ({
      nodes,
      edges,
    }),
    [edges, nodes]
  )
}
