import React from 'react'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles, Box, CircularProgress } from '@material-ui/core'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Links from 'components/admin/Links'
import Contacts from 'components/admin/Contacts'
import OwnerClips from 'components/admin/owners/OwnerClips'
import GeneralInfo from 'components/admin/owners/GeneralInfo'

import { useSnackbar } from 'hooks/useSnackbar'
import { useOwnersById } from 'hooks/owners/useOwners'

import { addOwner, initialOwner, updateOwner } from 'utils/owners'

import ownerValidationSchema from 'schemas/owner'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    '& form': {
      maxWidth: theme.breakpoints.values.lg,
      flex: '1',
      '& > :not(:last-child)': {
        flex: '1',
        marginBottom: theme.spacing(3),
      },
    },
  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Owner = () => {
  const history = useHistory()
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const { id: ownerId } = useParams()
  const isNew = ownerId === 'new'
  const {
    owner,
    isLoading: isLoadingOwner,
    mutate: mutateOwners,
  } = useOwnersById(!isNew && ownerId)
  const isLoading = !isNew && isLoadingOwner

  const handleSubmit = async (ownerData) => {
    let action
    let messages
    try {
      if (isNew) {
        action = addOwner
        messages = {
          success: 'Successfully added owner',
          error: 'Failed to save owner',
        }
      } else {
        action = updateOwner
        messages = {
          success: 'Successfully updated owner',
          error: 'Failed to update owner',
        }
      }
      await action(ownerData)
      mutateOwners()
      popSnackbar('success', messages.success)
    } catch (error) {
      popSnackbar('error', messages.error)
    }
    history.replace('/admin/owners')
  }

  const handleCancel = () => {
    history.replace('/admin/owners')
  }

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={owner || initialOwner}
          isInitialValid={false}
          validationSchema={ownerValidationSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <Header
              title={isNew ? 'Add owner' : 'Owner'}
              description={owner?.name || ''}
            />
            <GeneralInfo />
            <Contacts />
            <Links />
            <OwnerClips />
            <Footer onCancel={handleCancel} />
          </Form>
        </Formik>
      )}
    </div>
  )
}

export default Owner
