import React, { useMemo } from 'react'

import EditCell from 'components/table/users/EditCell'
import ActiveCell from 'components/table/users/ActiveCell'

import { getAge } from 'utils/shared'

const useUsersColumns = (columnNames) => {
  return useMemo(() => {
    let columns = [
      {
        name: 'index',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue: ({ index }) => index || '',
      },
      {
        name: 'edit',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ _id }) {
          return <EditCell id={_id} />
        },
      },
      { name: '_id', title: 'ID' },
      { name: 'name', title: 'Name' },
      { name: 'username', title: 'Username' },
      { name: 'email', title: 'Email' },
      {
        name: 'birthDate',
        title: 'Age',
        getCellValue: ({ birthDate }) => getAge(birthDate),
      },
      { name: 'phoneNumber', title: 'Phone' },
      { name: 'role', title: 'Role' },
      {
        name: 'active',
        width: 64,
        fixed: 'right',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ _id, active }) {
          return <ActiveCell id={_id} active={active} />
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames])
}

export default useUsersColumns
