import React, { useState } from 'react'

import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { HiDotsVertical } from 'react-icons/hi'

import ClipsConfirmDeleteDialog from 'components/clips/ClipsConfirmDeleteDialog'

const OptionsCell = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setOpenDialog('')
    setAnchorEl(null)
  }

  const handleClickMenuItem = (dialogName) => () => setOpenDialog(dialogName)

  return (
    <>
      <IconButton
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <HiDotsVertical />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickMenuItem('delete')}>Delete</MenuItem>
      </Menu>
      <ClipsConfirmDeleteDialog
        ids={[id]}
        onClose={handleClose}
        open={openDialog === 'delete'}
      />
    </>
  )
}

export default OptionsCell
