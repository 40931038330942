import axios from 'axios'

export const initialArtist = {
  name: '',
  email: '',
  links: [],
  contacts: [],
}

export const addArtist = async (artistData) => {
  const { data } = await axios.post('/api/artists', artistData)
  return data
}

export const updateArtist = async (artistToUpdate) => {
  const { _id: artistId } = artistToUpdate
  const { data: updatedArtists } = await axios.put(
    `/api/artists/${artistId}`,
    artistToUpdate
  )
  return updatedArtists
}

export const deleteArtistsByIds = async (artistIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/artists', { data: { ids: artistIds } })
  return deletedCount
}
