import { makeStyles, Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
}))

const tabs = [
  {
    label: 'Users',
    path: '/admin/users',
  },
  {
    label: 'Owners',
    path: '/admin/owners',
  },
  {
    label: 'Genres',
    path: '/admin/genres',
  },
  {
    label: 'Permissions',
    path: '/admin/permissions',
  },
]

const Header = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const currentTabIndex = useMemo(
    () => tabs.findIndex((tab) => location.pathname.startsWith(tab.path)),
    [location.pathname]
  )

  useEffect(() => {
    if (currentTabIndex === -1) {
      history.replace(tabs[0].path)
    }
  }, [currentTabIndex, history])

  const handleChangeTab = (event, newTabIndex) => {
    if (newTabIndex === currentTabIndex) return

    const newTab = tabs[newTabIndex]
    history.replace(newTab.path)
  }

  return (
    <div className={classes.root}>
      <Tabs
        indicatorColor="primary"
        value={currentTabIndex}
        onChange={handleChangeTab}
      >
        {tabs.map((tab, index) => {
          return <Tab label={tab.label} key={index} />
        })}
      </Tabs>
    </div>
  )
}

export default Header
