import React from 'react'
import { useHistory } from 'react-router-dom'

import { MdExitToApp } from 'react-icons/md'
import { Button, IconButton, makeStyles } from '@material-ui/core'

import GenresBar from './GenresBar'
import RegionsBar from './RegionsBar'

import { useAuth } from 'hooks/useAuthContext'
import { useEventStream } from 'hooks/useEventStream'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
    color: theme.palette.common.white,
    pointerEvents: 'all',
    marginTop: theme.spacing(3),
  },
  dashboardButton: {
    position: 'absolute',
    right: theme.spacing(3),
  },
  logoutButton: {
    position: 'absolute',
    right: theme.spacing(3),
  },
}))

const Header = () => {
  const classes = useStyles()
  const history = useHistory()
  const { addClickEvent } = useEventStream()
  const { logout, isDemoOnlyRole } = useAuth()

  const handleClickDashboard = () => {
    history.push('/')
  }

  return (
    <div className={classes.root}>
      <GenresBar />
      <RegionsBar />
      {isDemoOnlyRole ? (
        <IconButton
          id="logoutButton"
          color="inherit"
          onClick={logout}
          onClickCapture={addClickEvent}
          className={classes.logoutButton}
        >
          <MdExitToApp />
        </IconButton>
      ) : (
        <Button
          id="backToDashboardButton"
          variant="outlined"
          color="inherit"
          className={classes.dashboardButton}
          onClick={handleClickDashboard}
          onClickCapture={addClickEvent}
        >
          Back to dashboard
        </Button>
      )}
    </div>
  )
}

export default Header
