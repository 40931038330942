import * as yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('phoneNumberTest', errorMessage, function (value) {
    if (!value) return true

    const { path, createError } = this
    return (
      isValidPhoneNumber(value) || createError({ path, message: errorMessage })
    )
  })
})

export default yup
