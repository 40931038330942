import React from 'react'
import { Form, Formik } from 'formik'

import PopupCard from '../PopupCard'
import SelectOwners from 'components/SelectOwners'

import yup from 'schemas/yup'
import { useOwnersById } from 'hooks/owners/useOwners'
import { CircularProgress } from '@material-ui/core'

const validationSchema = yup.object({
  owner: yup
    .object({
      _id: yup.string().min(1).required(),
    })
    .required(),
})

export const OwnerInput = ({ initialValues, onChange, onCancel }) => {
  const paramName = 'owner'

  const { owner: initialOwner, isLoading } = useOwnersById(initialValues.owner)

  const handleOnSubmit = (values) => {
    onChange(paramName, values[paramName]._id)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Formik
          validateOnMount
          validateOnChange
          initialValues={{ [paramName]: initialOwner }}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <PopupCard onCancel={onCancel}>
              <SelectOwners
                name={paramName}
                label="Owner"
                fullWidth
                size="small"
                disableClearable
                required
                placeholder="Select a owner"
              />
            </PopupCard>
          </Form>
        </Formik>
      )}
    </>
  )
}

export const OwnerLabel = ({ value }) => {
  const { owner } = useOwnersById(value)
  return owner?.name || '-'
}
