import React from 'react'

import { useFormikContext } from 'formik'
import { Box, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const Footer = ({ onCancel }) => {
  const classes = useStyles()
  const { handleSubmit, isValid } = useFormikContext()

  return (
    <Box className={classes.root}>
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={!isValid}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Box>
  )
}

export default Footer
