import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'

import Hero from 'components/demo/Hero'
import Playlists from 'components/demo/Playlists'

import { DemoProvider } from 'hooks/demo/useDemo'
import { useDefaultRegion } from 'hooks/regions/useRegions'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.demo.background,
  },
  playlists: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.demo.background,
  },
  hero: {
    display: 'flex',
    flex: 2.7,
  },
}))

const Demo = () => {
  const classes = useStyles()
  const history = useHistory()

  const { regionId } = useParams()
  const { region: defaultRegion } = useDefaultRegion()

  useEffect(() => {
    if (defaultRegion && !regionId) {
      history.replace(`/demo/${defaultRegion?._id}`)
    }
  }, [defaultRegion, history, regionId])

  return (
    <div className={classes.root}>
      {defaultRegion && (
        <DemoProvider>
          <div className={classes.hero}>
            <Hero />
          </div>
          <div className={classes.playlists}>
            <Playlists />
          </div>
        </DemoProvider>
      )}
    </div>
  )
}

export default Demo
