import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'

import Select from 'components/Select'

import { useOwners } from 'hooks/owners/useOwners'

const LIMIT = 10
const DEBOUNCE = 500

const SelectOwners = ({ name, label, multiple, ...props }) => {
  const history = useHistory()
  const [inputValue, setInputValue] = useState('')
  const [debouncedName] = useDebounce(inputValue, DEBOUNCE)

  const { owners } = useOwners({ name: debouncedName, limit: LIMIT })

  const ownersOptions = owners || []

  return (
    <Select
      name={name}
      label={label}
      onInputChange={(_, value) => setInputValue(value)}
      placeholder={`Select one ${name} or more`}
      multiple={multiple}
      autoSelect
      getOptionSelected={(option, value) => value._id === option._id}
      options={ownersOptions}
      getOptionLabel={(value) => value.name}
      onClickTag={(value) => history.push(`/admin/owners/${value._id}`)}
      {...props}
    />
  )
}

export default SelectOwners
