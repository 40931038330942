import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, makeStyles, Typography } from '@material-ui/core'

import PlaylistsList from 'components/playlists/PlaylistsList'
import AddClipsToPlaylistDialog from 'components/clips/AddClipsToPlaylistDialog'

import { useClipRelatedPlaylists } from 'hooks/playlists/usePlaylist'
import FormCard from 'components/FormCard'

const useStyles = makeStyles({
  addToPlaylistButton: {
    alignSelf: 'flex-end',
  },
})

const ClipPlaylists = () => {
  const classes = useStyles()
  const { id: clipId } = useParams()
  const [openDialog, setOpenDialog] = useState('')

  const isNew = clipId === 'new'

  const { totalCount, mutate: mutateClipPlaylists } = useClipRelatedPlaylists(
    !isNew && { clipId }
  )

  return (
    <FormCard title="Related Playlists" collapsible disabled={isNew}>
      <Button
        className={classes.addToPlaylistButton}
        color="primary"
        variant="outlined"
        onClick={() => setOpenDialog('addToPlaylist')}
      >
        Add clip to playlist
      </Button>
      {totalCount ? (
        <PlaylistsList clipId={clipId} />
      ) : (
        <Typography>No related playlists</Typography>
      )}
      {openDialog === 'addToPlaylist' && (
        <AddClipsToPlaylistDialog
          ids={[clipId]}
          open
          onClose={() => setOpenDialog('')}
          onSave={() => mutateClipPlaylists()}
        />
      )}
    </FormCard>
  )
}

export default ClipPlaylists
