/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react'
import update from 'immutability-helper'
import { FormikProvider, useFormik } from 'formik'

import { Autocomplete } from '@material-ui/lab'
import { TextField, Popover, makeStyles, withStyles } from '@material-ui/core'

import QueryTag from './QueryTag'

import options from './queries/Options'
import { useClipsContext } from 'hooks/clips/useClipsContext'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 240,
  },
}))

const PaddedAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      minWidth: 60,
    },
  },
  input: {},
})(Autocomplete)

const QuerySearch = () => {
  const classes = useStyles()
  const inputRef = useRef()
  const { query, setQuery } = useClipsContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const [editedParam, setEditedParam] = useState(null)

  const handleOnSubmit = (values) => {
    setQuery(values)
  }

  const formik = useFormik({ initialValues: query, onSubmit: handleOnSubmit })

  const handleOnChange = (_, value) => {
    const newValues = value.reduce((acc, curr) => {
      const currentParamInValues = formik.values[curr.name]
      if (!currentParamInValues) {
        setEditedParam(curr)
        setAnchorEl(inputRef.current)
        return acc
      } else {
        return { ...acc, [curr.name]: currentParamInValues }
      }
    }, {})
    formik.setValues(newValues).then(() => formik.submitForm())
  }

  const handleOnEditParam = (name, value) => {
    const newValues = update(formik.values, { [name]: { $set: value } })
    formik.setValues(newValues).then(() => formik.submitForm())
    closeEditParamDialog()
  }

  const handleOnCancelEditParam = () => {
    if (!formik.values[editedParam.name]) {
      handleOnRemoveParam(editedParam)
    }
    closeEditParamDialog()
  }

  const handleOnRemoveParam = (paramToRemove) => {
    const newValues = update(formik.values, { $unset: [paramToRemove.name] })
    formik.setValues(newValues).then(() => formik.submitForm())
  }

  const handleOnClickEditParam = (paramToEdit, anchorRef) => {
    setEditedParam(paramToEdit)
    setAnchorEl(anchorRef.current)
  }

  const closeEditParamDialog = () => {
    setEditedParam(null)
    setAnchorEl(null)
  }

  const showPopover = !!editedParam && !!anchorEl

  return (
    <FormikProvider value={formik}>
      <PaddedAutocomplete
        className={classes.root}
        size="small"
        multiple
        autoComplete
        autoHighlight
        options={options}
        value={Object.keys(formik.values).map((propName) =>
          options.find((option) => option.name === propName)
        )}
        onChange={handleOnChange}
        renderTags={(value) =>
          value.map((option, index) => {
            const value = formik.values[option.name]

            return (
              <QueryTag
                key={index}
                label={option.label}
                value={option.renderValueLabel({ value })}
                onClick={(anchorRef) =>
                  handleOnClickEditParam(option, anchorRef)
                }
                onRemove={() => handleOnRemoveParam(option)}
              />
            )
          })
        }
        filterSelectedOptions
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search"
            inputRef={inputRef}
          />
        )}
      />

      {showPopover && (
        <Popover
          open
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: -38, horizontal: 'left' }}
        >
          {editedParam.renderInput({
            initialValues: formik.values,
            onChange: handleOnEditParam,
            onCancel: handleOnCancelEditParam,
          })}
        </Popover>
      )}
    </FormikProvider>
  )
}

export default QuerySearch
