import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import usePlaylistsColumns from 'hooks/playlists/usePlaylistsColumns'
import { usePlaylistsContext } from 'hooks/playlists/usePlaylistsContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id', 'rating', 'editors']
const columnNames = [
  ...defaultHiddenColumnNames,
  'thumbnail',
  'name',
  'genres',
  'regions',
  'totalDuration',
  'clipCount',
  'createdAt',
  'updatedAt',
  'status',
]
const SEARCH_DEBOUNCE = 500

const PlaylistsTable = () => {
  const classes = useStyles()
  const [tempSearchValue, setTempSearchValue] = useState('')

  const {
    playlists,
    pageSize,
    isLoading,
    totalCount,
    setPageSize,
    currentPage,
    selectedPlaylists,
    setCurrentPage,
    setSearchValue,
    setSelectedPlaylists,
  } = usePlaylistsContext()

  const debouncedSetSearchValue = useDebouncedCallback(
    setSearchValue,
    SEARCH_DEBOUNCE
  )

  const handleOnChangeTempSearchValue = (value) => {
    setTempSearchValue(value)
    debouncedSetSearchValue(value)
  }

  const columns = usePlaylistsColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={playlists || []}
        columns={columns}
        selection={selectedPlaylists}
        onSelectionChange={setSelectedPlaylists}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={tempSearchValue}
        onChangeSearchValue={handleOnChangeTempSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default PlaylistsTable
