import React, { useState } from 'react'

import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import RegionDialog from 'components/regions/RegionDialog'

const EditCell = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton size="small" onClick={() => setIsOpen(true)}>
        <EditIcon fontSize="small" />
      </IconButton>
      {isOpen && <RegionDialog id={id} open onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default EditCell
