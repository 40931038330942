import React from 'react'

import { useField } from 'formik'
import MomentUtils from '@date-io/moment'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core'

const DEFAULT_FORMAT = 'DD/MM/YYYY'

const DateInput = ({
  name,
  label,
  required,
  helperText,
  ...keyboardDatePickerProps
}) => {
  const [, meta, helpers] = useField(name)
  const error = meta.touched && meta.error

  const handleChange = (newValue) => {
    helpers.setValue(newValue).then(() => helpers.setTouched(true))
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl required={required} margin="normal" error={!!error}>
        <FormLabel>{label}</FormLabel>
        <KeyboardDatePicker
          clearable
          size="small"
          margin="dense"
          inputVariant="outlined"
          disableFuture
          value={meta.value}
          format={DEFAULT_FORMAT}
          onChange={handleChange}
          {...keyboardDatePickerProps}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

export default DateInput
