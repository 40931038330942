import React from 'react'

import Select from 'components/Select'

import { useRegions } from 'hooks/regions/useRegions'

const SelectRegions = ({ multiple, excludeRegionIds = [], ...rest }) => {
  const { regions, isLoading: isLoadingRegions } = useRegions({ mapById: true })
  const options = regions
    ? Object.keys(regions).filter(
        (regionId) => !excludeRegionIds.includes(regionId)
      )
    : []

  return (
    <Select
      options={options}
      getOptionLabel={(regionId) => (regions && regions[regionId]?.name) || ''}
      disabled={isLoadingRegions}
      multiple={multiple}
      {...rest}
    />
  )
}

export default SelectRegions
