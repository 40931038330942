import React, { useEffect, useMemo, useRef, useState } from 'react'

import moment from 'moment'
import lottie from 'lottie-web'
import classnames from 'classnames'

import { BsDot } from 'react-icons/bs'
import { makeStyles, Typography } from '@material-ui/core'
import CurrentlyPlayingAnimation from 'assets/animations/currently_playing.json'

import { useDemo } from 'hooks/demo/useDemo'
import { useClipsById } from 'hooks/clips/useClips'
import { useEventStream } from 'hooks/useEventStream'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: 5,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  thumbnail: {
    height: '100%',
    width: '100%',
    filter: 'brightness(70%)',
    transition: '0.3s',
    objectFit: 'fill',
  },
  noThumbnail: {
    height: '100%',
    width: '100%',
    transition: '0.3s',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  thumbnailBlur: {
    filter: 'blur(2px)',
  },
  metadata: {
    width: '100%',
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textShadow:
      '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.2), 0px 18px 23px rgba(0,0,0,0.1)',
    transition: '0.3s',
    opacity: 0,
  },
  metadataRevealed: {
    opacity: 1,
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    transition: '0.3s',
    zIndex: 10,
  },
  overlayDim: {
    backgroundColor: 'rgba(25,25,25, 0.7)',
  },
  title: {
    maxWidth: '75%',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    color: 'white',
    textShadow:
      '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.2), 0px 18px 23px rgba(0,0,0,0.1)',
    transition: '0.3s',
    fontSize: 28,
    fontWeight: 600,
  },
  shrankTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 600,
  },
  currentlyPlaying: {
    height: 30,
    width: 30,
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const PLAYING_ANIMATION_NAME = 'currentlyPlaying'

const PlaylistCard = ({ playlist }) => {
  const classes = useStyles()
  const animationRef = useRef(null)
  const { addClickEvent, addHoverEvent } = useEventStream()

  const [showOverlay, setShowOverlay] = useState(false)
  const handleHover = (e) => {
    addHoverEvent(e)
    setShowOverlay(true)
  }
  const handleLeave = () => setShowOverlay(false)

  const {
    playlistsInCurrentRegion,
    setCurrentPlaylistIndex,
    setCurrentClipIndex,
    currentPlaylist,
  } = useDemo()

  const backgroundClipId = useMemo(() => {
    if (!playlist.clips?.length) return ''
    return playlist.clips.length && playlist.clips[0]
  }, [playlist])

  const { clip: backgroundClip } = useClipsById(backgroundClipId)

  const handleOnClick = () => {
    if (!playlistsInCurrentRegion?.length) return

    const playlistIndexInCurrentRegion = playlistsInCurrentRegion.findIndex(
      (playlistInRegion) => playlistInRegion._id === playlist._id
    )
    setCurrentPlaylistIndex(playlistIndexInCurrentRegion)
    setCurrentClipIndex(0)
  }

  const formattedPlaylistTotalDuration = `${
    moment.utc(playlist.totalDuration).hours() > 0
      ? moment.utc(playlist.totalDuration).format('H') + ' hr '
      : ''
  } ${moment.utc(playlist.totalDuration).format('m') + ' min '}`

  const isPlaying = currentPlaylist?._id === playlist?._id

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef.current,
      animationData: CurrentlyPlayingAnimation,
      loop: true,
      autoplay: true,
      name: PLAYING_ANIMATION_NAME,
    })

    return () => {
      lottie.destroy(PLAYING_ANIMATION_NAME)
    }
  }, [currentPlaylist])

  return (
    <div
      id={`playlistCard_${playlist._id}_${playlist.name}`}
      className={classes.root}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      onClick={handleOnClick}
      onClickCapture={addClickEvent}
    >
      <div
        className={classnames(
          classes.overlay,
          showOverlay && classes.overlayDim
        )}
      >
        {isPlaying && (
          <div ref={animationRef} className={classes.currentlyPlaying} />
        )}
        <Typography
          classes={{
            root: classnames(classes.title, showOverlay && classes.shrankTitle),
          }}
        >
          {playlist.name}
        </Typography>
        <div
          className={classnames(
            classes.metadata,
            showOverlay && classes.metadataRevealed
          )}
        >
          <Typography variant="h3">{playlist.clips?.length} clips</Typography>
          <BsDot size={30} color="white" />
          <Typography variant="h3">{formattedPlaylistTotalDuration}</Typography>
        </div>
      </div>
      <img
        className={classnames(
          classes.thumbnail,
          showOverlay && classes.thumbnailBlur
        )}
        src={backgroundClip?.thumbnailUrl}
        alt={playlist?.name}
      />
    </div>
  )
}

export default PlaylistCard
