import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import { useUsers } from 'hooks/users/useUsers'
import useUsersColumns from 'hooks/users/useUsersColumns'
import { useUsersContext } from 'hooks/users/useUsersContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id']
const columnNames = [
  ...defaultHiddenColumnNames,
  'name',
  'edit',
  'username',
  'email',
  'role',
  'active',
  'phoneNumber',
  'birthDate',
]

const SEARCH_DEBOUNCE = 500
const DEFAULT_PAGE_SIZE = 100

const UsersTable = () => {
  const classes = useStyles()
  const { selectedUsers, setSelectedUsers } = useUsersContext()

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_DEBOUNCE)

  const [currentPage, setCurrentPage] = useState(0)

  const { users, totalCount, isLoading } = useUsers({
    name: debouncedSearchValue,
    limit: pageSize,
    skip: currentPage * pageSize,
  })

  const columns = useUsersColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={users || []}
        columns={columns}
        selection={selectedUsers}
        onSelectionChange={setSelectedUsers}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default UsersTable
