import React, { useContext, createContext, useState, useEffect } from 'react'

import { DEFAULT_TABLE_PAGE_SIZE } from 'components/table/Table'

import { useClips } from 'hooks/clips/useClips'

const ClipsContext = createContext()

const ClipsProvider = ({ children }) => {
  const [query, setQuery] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [selectedClips, setSelectedClips] = useState([])
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE)

  const [currentPage, setCurrentPage] = useState(0)

  const { clips, totalCount, isLoading, mutate } = useClips({
    name: searchValue,
    limit: pageSize,
    skip: currentPage * pageSize,
    metadataFields: ['playlistCount'],
    ...query,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [query, searchValue])

  const value = {
    clips,
    query,
    mutate,
    setQuery,
    isLoading,
    totalCount,
    setPageSize,
    selectedClips,
    setCurrentPage,
    setSearchValue,
    setSelectedClips,
  }

  return <ClipsContext.Provider value={value}>{children}</ClipsContext.Provider>
}

const ClipsConsumer = ({ children }) => {
  return (
    <ClipsContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('ClipsConsumer must be used within a ClipsProvider')
        }
        return children(context)
      }}
    </ClipsContext.Consumer>
  )
}

const useClipsContext = () => {
  const context = useContext(ClipsContext)
  if (context === undefined) {
    throw new Error('useClipsContext must be used within a ClipsProvider')
  }
  return context
}

export { ClipsProvider, ClipsConsumer, useClipsContext }
