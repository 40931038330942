import React from 'react'

import Select from 'components/Select'

import { useUsers } from 'hooks/users/useUsers'

const SelectEditors = () => {
  const { users } = useUsers()

  const editorsOptions = users || []

  return (
    <Select
      name="editors"
      label="Editors"
      placeholder="Select one editor or more"
      multiple
      autoSelect
      disableClearable
      getOptionSelected={(option, value) => value._id === option._id}
      options={editorsOptions}
      getOptionLabel={(editor) => editor.name}
      required
    />
  )
}

export default SelectEditors
