import React, { forwardRef } from 'react'

import { makeStyles } from '@material-ui/core'

import ArtistInfo from 'components/artist/ArtistInfo'
import ArtistToolbar from 'components/artist/ArtistToolbar'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    height: ({ height }) => height,
  },
}))

const ArtistPageHeader = ({ height }, ref) => {
  const classes = useStyles({ height })

  return (
    <div className={classes.root} ref={ref}>
      <ArtistInfo />
      <ArtistToolbar />
    </div>
  )
}

export default forwardRef(ArtistPageHeader)
