import React, { useRef, useState } from 'react'

import 'swiper/swiper-bundle.css'
import classnames from 'classnames'
import { SwiperSlide } from 'swiper/react'

import { makeStyles } from '@material-ui/core'

import PlaylistCard from './PlaylistCard'
import ArrowsOverlay from './ArrowsOverlay'
import DemoPlaylistsSwiper from './DemoPlaylistsSwiper'

import { useDemo } from 'hooks/demo/useDemo'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.demo.background,
    position: 'relative',
    width: '100%',
    height: '70%',
    display: 'flex',
    alignItems: 'center',
  },
  leftPad: {
    paddingLeft: theme.spacing(2),
  },
  swiperContainer: {
    width: '100%',
    height: '100%',
  },
  slide: {
    height: '100%',
    display: 'flex',
  },
}))

const Playlists = () => {
  const classes = useStyles()
  const { playlistsInCurrentRegion } = useDemo()

  const [showArrows, setShowArrows] = useState(false)
  const handleHover = () => setShowArrows(true)
  const handleLeave = () => setShowArrows(false)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const showNavigationArrows = playlistsInCurrentRegion?.length >= 6

  if (!playlistsInCurrentRegion?.length) return null

  return (
    <div
      className={classnames(
        classes.root,
        !showNavigationArrows && classes.leftPad
      )}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      {showNavigationArrows && (
        <ArrowsOverlay
          visible={showArrows}
          prevRef={navigationPrevRef}
          nextRef={navigationNextRef}
        />
      )}

      <DemoPlaylistsSwiper
        navigationPrevRef={navigationPrevRef}
        navigationNextRef={navigationNextRef}
        containerClassname={classes.swiperContainer}
      >
        {playlistsInCurrentRegion?.map((playlist) => (
          <SwiperSlide className={classes.slide} key={playlist._id}>
            <PlaylistCard playlist={playlist} />
          </SwiperSlide>
        ))}
      </DemoPlaylistsSwiper>
    </div>
  )
}

export default Playlists
