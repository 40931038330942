import React, { forwardRef } from 'react'

import { makeStyles } from '@material-ui/core'

import PlaylistInfo from 'components/playlist/PlaylistInfo'
import PlaylistToolbar from 'components/playlist/PlaylistToolbar'

import { playlistTypeOptions } from 'consts/playlists'

import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    height: ({ height }) => height,
  },
}))

const PlaylistPageHeader = ({ height }, ref) => {
  const classes = useStyles(height)

  const { playlist } = usePlaylistContext()

  return (
    <div className={classes.root} ref={ref}>
      <PlaylistInfo />
      {playlist?.type === playlistTypeOptions.NORMAL && <PlaylistToolbar />}
    </div>
  )
}

export default forwardRef(PlaylistPageHeader)
