import React, { useMemo } from 'react'

import Select from 'components/Select'
import FormCard from 'components/FormCard'
import TextInput from 'components/TextInput'
import SelectUsers from 'components/SelectUsers'
import PhoneNumberInput from 'components/PhoneNumberInput'

import { useRegions } from 'hooks/regions/useRegions'

const GeneralInfo = () => {
  const { regions } = useRegions({ mapById: true })
  const regionOptions = useMemo(
    () => (regions ? Object.keys(regions) : []),
    [regions]
  )

  return (
    <FormCard title="General Info">
      <TextInput
        name="name"
        label="Owner name"
        placeholder="Enter owner name in english"
        required
      />
      <TextInput
        name="email"
        label="Email address"
        placeholder="Enter email address"
      />
      <Select
        name="regions"
        label="Regions"
        placeholder="Select one region or more"
        getOptionLabel={(regionId) =>
          (regions && regions[regionId]?.name) || ''
        }
        options={regionOptions}
        multiple
        required
      />
      <PhoneNumberInput name="phoneNumber" label="Phone number" />
      <SelectUsers
        name="user"
        label="User"
        placeholder="Select a user"
        getOptionLabel={(user) => `${user?._id} - ${user?.name}`}
        multiple={false}
        helperText="You can bind an existing user to this owner"
      />
    </FormCard>
  )
}

export default GeneralInfo
