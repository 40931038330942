import React, { useCallback, useEffect, useRef, useState } from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'

import { makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const dashboardUrl = import.meta.env.VITE_DASHBOARD_URL

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    height: '100%',
    width: '100%',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 17,
  },
}))

const ErrorPlaceholder = () => {
  const classes = useStyles()

  return (
    <Alert
      variant="outlined"
      severity="error"
      classes={{ root: classes.errorContainer }}
    >
      <Typography classes={{ root: classes.errorText }} variant="subtitle2">
        Failed Retrieving This Chart
      </Typography>
      <Typography classes={{ root: classes.errorText }} variant="subtitle1">
        Please try again later.
      </Typography>
    </Alert>
  )
}

const Chart = ({ filter, chartId }) => {
  const classes = useStyles()
  const sdk = new ChartsEmbedSDK({
    baseUrl: dashboardUrl,
    showAttribution: false,
    heightMode: 'scale',
    widthMode: 'scale',
  })
  const chartDiv = useRef(null)
  const [error, setError] = useState(null)
  const [rendered, setRendered] = useState(false)
  const [chart] = useState(sdk.createChart({ chartId }))

  const onError = (error) => setError(error)
  const onFinishRendering = useCallback(() => {
    setRendered(true)
    setError(null)
  }, [])

  useEffect(() => {
    chartDiv.current &&
      chart.render(chartDiv.current).then(onFinishRendering).catch(onError)
  }, [chart, onFinishRendering])

  useEffect(() => {
    if (rendered && filter) {
      chart.setFilter(filter).catch(onError)
    }
  }, [chart, filter, rendered])

  if (error) return <ErrorPlaceholder />

  return <div className={classes.root} ref={chartDiv} />
}

export default Chart
