import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link, useHistory } from 'react-router-dom'

import { BsPlayFill } from 'react-icons/bs'
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Link as MuiLink,
} from '@material-ui/core'

import ClipOptionsMenu from './ClipOptionsMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '3px',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      '& $options': {
        visibility: 'visible',
      },
      '& $play': {
        display: 'flex',
      },
      '& $index': {
        display: 'none',
      },
    },
  },
  dragging: {
    opacity: 0.6,
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
  grid: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(3),
    },
  },
  index: {
    width: '30px',
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  thumbnail: {
    '& img': {
      aspectRatio: '1 / 1',
      height: '48px',
      objectFit: 'cover',
    },
  },
  name: {
    display: 'flex',
    alignItems: 'normal',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  options: {
    visibility: 'hidden',
  },
  play: {
    width: '30px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'none',
  },
}))

function getStyle({ provided, style, isDragging }) {
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  }

  const marginBottom = 8
  return {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  }
}

const ClipItem = ({ clip, isDragging, provided, style, index, onRemove }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleOnClickPlay = () => {
    history.push(`/clips/${clip._id}`)
  }

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging })}
    >
      <div className={classnames(classes.root, isDragging && classes.dragging)}>
        <Grid className={classes.grid} container direction="row">
          <Grid item className={classes.index}>
            {index + 1}
          </Grid>
          <Grid item className={classes.play}>
            <IconButton size="small" onClick={handleOnClickPlay} disableRipple>
              <BsPlayFill />
            </IconButton>
          </Grid>
          <Grid item className={classes.thumbnail}>
            <img src={clip.thumbnailUrl} alt={clip.name} />
          </Grid>
          <Grid item className={classes.name} md="4">
            <Tooltip title={clip.name} placement="top">
              <Typography variant="body1" noWrap>
                {clip.name}
              </Typography>
            </Tooltip>
            <Typography variant="body2">
              {clip.artists.map((artist, index) => {
                const showComma = index < clip.artists.length - 1
                return (
                  <Link
                    component={MuiLink}
                    key={artist._id}
                    to={`/artists/${artist._id}`}
                  >
                    {artist.name}
                    {showComma && ', '}
                  </Link>
                )
              })}
            </Typography>
          </Grid>
          <Grid item>{moment(clip.createdAt).format('ll')}</Grid>
          <Box flex="1" />
          <Grid item>{moment(clip.duration).format('m:ss')}</Grid>
          <Grid item className={classes.options}>
            <ClipOptionsMenu onRemove={onRemove} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default React.memo(ClipItem)
