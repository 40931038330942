import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import Select from 'components/Select'

import { useUsers } from 'hooks/users/useUsers'

const LIMIT = 10
const DEBOUNCE = 500

const SelectUsers = ({ name, label, ...props }) => {
  const [inputValue, setInputValue] = useState('')
  const [debouncedName] = useDebounce(inputValue, DEBOUNCE)

  const { users } = useUsers({ name: debouncedName, limit: LIMIT })

  const usersOptions = users || []

  return (
    <Select
      name={name}
      label={label}
      onInputChange={(_, value) => setInputValue(value)}
      placeholder={`Select one ${name} or more`}
      multiple
      autoSelect
      getOptionSelected={(option, value) => value._id === option._id}
      options={usersOptions}
      getOptionLabel={(value) => value.name}
      {...props}
    />
  )
}

export default SelectUsers
