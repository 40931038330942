import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import { useGenres } from 'hooks/genres/useGenres'
import useGenresColumns from 'hooks/genres/useGenresColumns'

const DEFAULT_SORTING = [{ columnName: 'relatedClipsCount', direction: 'desc' }]

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id']
const columnNames = [
  ...defaultHiddenColumnNames,
  'edit',
  'name',
  'playlistCount',
  'clipCount',
  'relatedGenres',
]

const SEARCH_DEBOUNCE = 500
const DEFAULT_PAGE_SIZE = 100

const GenresTable = ({ selection, onSelectionChange }) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_DEBOUNCE)

  const { genres, isLoading } = useGenres({
    metadataFields: ['playlistCount', 'clipCount'],
  })

  const filteredGenres = useMemo(
    () =>
      genres?.filter((genre) =>
        new RegExp(`.*${debouncedSearchValue}.*`, 'i').test(genre.name)
      ) || [],
    [debouncedSearchValue, genres]
  )

  const rows = useMemo(
    () =>
      filteredGenres.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
      ),
    [filteredGenres, currentPage, pageSize]
  )

  const columns = useGenresColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        selection={selection}
        onSelectionChange={onSelectionChange}
        defaultSorting={DEFAULT_SORTING}
        totalCount={filteredGenres.length}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        pageSize={pageSize}
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default GenresTable
