import yup from './yup'

const contact = yup.object({
  type: yup.string().required('Contact type required').nullable(),
  name: yup.string().required('Contact name required'),
  email: yup.string().email('Invalid email'),
  phoneNumber: yup.string().phoneNumber('Invalid phone number'),
})

export default contact
