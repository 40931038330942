import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import useArtistsColumns from 'hooks/artists/useArtistsColumns'
import { useArtistsContext } from 'hooks/artists/useArtistsContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id', 'user']
const columnNames = [
  ...defaultHiddenColumnNames,
  'edit',
  'thumbnail',
  'name',
  'regions',
  'email',
  'clipCount',
  'phoneNumber',
]

const SEARCH_DEBOUNCE = 500

const ArtistsTable = () => {
  const classes = useStyles()
  const [tempSearchValue, setTempSearchValue] = useState('')

  const {
    artists,
    pageSize,
    isLoading,
    totalCount,
    setPageSize,
    currentPage,
    selectedArtists,
    setCurrentPage,
    setSearchValue,
    setSelectedArtists,
  } = useArtistsContext()

  const debouncedSetSearchValue = useDebouncedCallback(
    setSearchValue,
    SEARCH_DEBOUNCE
  )

  const handleOnChangeTempSearchValue = (value) => {
    setTempSearchValue(value)
    debouncedSetSearchValue(value)
  }

  const columns = useArtistsColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={artists || []}
        columns={columns}
        selection={selectedArtists}
        onSelectionChange={setSelectedArtists}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={tempSearchValue}
        onChangeSearchValue={handleOnChangeTempSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default ArtistsTable
