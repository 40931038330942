import axios from 'axios'
import * as yup from 'yup'

export const genreValidationSchema = yup.object().shape({
  name: yup.string('Enter genre name').required('genre name is required'),
  relatedGenres: yup.array(),
  status: yup.string(),
})

export const initialGenre = {
  name: '',
  relatedGenres: [],
}

export const addGenre = async (genreData) => {
  const { data } = await axios.post('/api/genres', genreData)
  return data
}

export const updateGenre = async (genreToUpdate) => {
  const { _id: genreId } = genreToUpdate
  const { data: updatedGenres } = await axios.put(
    `/api/genres/${genreId}`,
    genreToUpdate
  )
  return updatedGenres
}

export const deleteGenresByIds = async (genreIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/genres', { data: { ids: genreIds } })
  return deletedCount
}
