import React from 'react'
import { makeStyles } from '@material-ui/core'

import ArtistsTable from 'components/artists/ArtistsTable'
import ArtistsHeader from 'components/artists/ArtistsHeader'

import { ArtistsProvider } from 'hooks/artists/useArtistsContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Artists = () => {
  const classes = useStyles()

  return (
    <ArtistsProvider>
      <div className={classes.root}>
        <ArtistsHeader />
        <ArtistsTable />
      </div>
    </ArtistsProvider>
  )
}

export default Artists
