import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Autocomplete } from '@material-ui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core'

import { useSnackbar } from 'hooks/useSnackbar'
import { useClips } from 'hooks/clips/useClips'

import { addClipsToPlaylist } from 'utils/playlists'

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
})

const SEARCH_DEBOUNCE = 500
const MAX_PLAYLISTS = 10

const AddClipsToPlaylistDialog = ({ playlistId, open, onClose, onSave }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClips, setSelectedClips] = useState([])

  const [search, setSearch] = useState('')
  const [debouncedSearchValue] = useDebounce(search, SEARCH_DEBOUNCE)

  const { clips } = useClips({
    limit: MAX_PLAYLISTS,
    name: debouncedSearchValue,
  })

  const handleSubmit = async () => {
    setIsLoading(true)
    if (!selectedClips?.length) return

    try {
      const selectedClipsIds = selectedClips.map((clip) => clip._id)
      const { rejected, fulfilled } = await addClipsToPlaylist(
        playlistId,
        selectedClipsIds
      )

      let status
      let message
      if (!fulfilled.length) {
        status = 'error'
        message = 'Failed to add clips to playlist'
      } else if (fulfilled.length && rejected.length) {
        status = 'warning'
        message = `${fulfilled.length}/${selectedClipsIds.length} clips added to playlist successfuly`
      } else {
        status = 'success'
        message = 'All clips added to playlist successfuly'
      }

      popSnackbar(status, message)

      onSave && onSave()
      onClose()
    } catch (error) {
      popSnackbar('error', 'Could not add clips to playlist')
    }

    setIsLoading(false)
  }

  const handleOnChange = (e, value) => {
    setSelectedClips(value)
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>Add clips to playlist</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Autocomplete
            autoComplete
            multiple
            size="small"
            labelId="playlist-select-label"
            variant="outlined"
            renderValue={(selected) => selected.join(', ')}
            getOptionLabel={(option) => option?.name}
            onChange={handleOnChange}
            value={selectedClips}
            options={clips || []}
            renderInput={(params) => (
              <TextField
                placeholder="Select clips to add to playlist"
                size="small"
                {...params}
                variant="outlined"
                onChange={(event) => setSearch(event.target.value)}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedClips?.length}
            loading={isLoading}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddClipsToPlaylistDialog
