import React, { useContext, createContext, useState } from 'react'

const OwnersContext = createContext()

const OwnersProvider = ({ children }) => {
  const [selectedOwners, setSelectedOwners] = useState([])

  const value = { selectedOwners, setSelectedOwners }
  return (
    <OwnersContext.Provider value={value}>{children}</OwnersContext.Provider>
  )
}

const OwnersConsumer = ({ children }) => {
  return (
    <OwnersContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('OwnersConsumer must be used within a OwnersProvider')
        }
        return children(context)
      }}
    </OwnersContext.Consumer>
  )
}

const useOwnersContext = () => {
  const context = useContext(OwnersContext)
  if (context === undefined) {
    throw new Error('useOwnersContext must be used within a OwnersProvider')
  }
  return context
}

export { OwnersProvider, OwnersConsumer, useOwnersContext }
