import React, { useMemo } from 'react'

import EditCell from 'components/table/regions/EditCell'
import ActiveCell from 'components/table/regions/ActiveCell'

import { useRegions } from 'hooks/regions/useRegions'

const useRegionsColumns = (columnNames) => {
  const { regions } = useRegions({ mapById: true })

  return useMemo(() => {
    let columns = [
      {
        name: 'index',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue: ({ index }) => index || '',
      },
      {
        name: 'edit',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ _id }) {
          return <EditCell id={_id} />
        },
      },
      { name: '_id', title: 'ID' },
      { name: 'name', title: 'Name' },
      {
        name: 'parentRegions',
        title: 'Parent Regions',
        getCellValue: ({ parentRegions }) => {
          if (!regions || !parentRegions) return ''

          const parentRegionsNames = parentRegions.reduce(
            (acc, parentRegionId) => {
              const parentRegionName = regions[parentRegionId]?.name
              return parentRegionName ? [...acc, parentRegionName] : acc
            },
            []
          )

          return parentRegionsNames.join(', ')
        },
      },
      { name: 'playlistCount', title: '# of Related Playlists' },
      {
        name: 'active',
        width: 64,
        fixed: 'right',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ _id, active }) {
          return <ActiveCell id={_id} active={active} />
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, regions])
}

export default useRegionsColumns
