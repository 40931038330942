import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { usePlaylists } from 'hooks/playlists/usePlaylist'
import { useSnackbar } from 'hooks/useSnackbar'

import { deletePlaylistsByIds } from 'utils/playlists'

const PlaylistsConfirmDeleteDialog = ({ ids, open, onDelete, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutatePlaylists } = usePlaylists()

  const handleDelete = async () => {
    try {
      await deletePlaylistsByIds(ids)
      mutatePlaylists()
      popSnackbar('success', 'Playlist deleted successfuly')
      onDelete && onDelete()
    } catch (error) {
      popSnackbar('error', 'Could not deleted playlists')
    }
    onClose()
  }

  const title = `Delete playlist${ids.length > 1 ? 's' : ''}`
  const description = `Are you sure you want to delete ${
    ids.length > 1 ? 'these' : 'this'
  } playlist${ids.length > 1 ? 's' : ''}?`

  return (
    <ConfirmDialog
      title={title}
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      {description}
    </ConfirmDialog>
  )
}

export default PlaylistsConfirmDeleteDialog
