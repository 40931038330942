import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'

import PopupCard from '../PopupCard'
import Select from 'components/Select'

import yup from 'schemas/yup'
import { useRegions } from 'hooks/regions/useRegions'

const validationSchema = yup.object({
  region: yup.string().min(1).required(),
})

export const RegionInput = ({ initialValues, onChange, onCancel }) => {
  const paramName = 'region'
  const { regions: regions } = useRegions({ mapById: true })

  const countriesOptions = useMemo(
    () => (regions ? Object.keys(regions) : []),
    [regions]
  )

  const handleOnSubmit = (values) => {
    onChange(paramName, values[paramName])
  }

  return (
    <Formik
      validateOnMount
      initialValues={{ [paramName]: '', ...initialValues }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <PopupCard onCancel={onCancel}>
          <Select
            name={paramName}
            label="Region"
            fullWidth
            size="small"
            disableClearable
            placeholder="Select a region"
            options={countriesOptions}
            getOptionLabel={(countryId) =>
              (regions && regions[countryId]?.name) || ''
            }
          />
        </PopupCard>
      </Form>
    </Formik>
  )
}

export const RegionLabel = ({ value }) => {
  const { regions: regions } = useRegions({ mapById: true })
  return regions[value]?.name || '-'
}
