import React from 'react'
import { MdExitToApp } from 'react-icons/md'
import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core'

import { useAuth } from 'hooks/useAuthContext'

const useStyles = makeStyles((theme) => ({
  navbar: {
    height: theme.spacing(6),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    minHeight: theme.spacing(6),
    justifyContent: 'flex-end',
  },
}))

const Navbar = () => {
  const classes = useStyles()
  const { logout } = useAuth()

  return (
    <AppBar className={classes.navbar} position="fixed" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={logout}>
          <MdExitToApp />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
