import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  image: {
    objectFit: 'cover',
  },
  squared: {
    aspectRatio: '1 / 1',
  },
}))

const Thumbnail = ({ src, width, height, sqaured }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        src={src}
        className={classnames(classes.image, sqaured && classes.squared)}
        width={width}
        height={height}
      />
    </div>
  )
}

export default Thumbnail
