import axios from 'axios'
import * as yup from 'yup'

export const permissionValidationSchema = yup.object().shape({
  role: yup.string('Enter role name').required('role name is required'),
  resource: yup
    .string('Enter resource name')
    .required('resource name is required'),
  action: yup.string('Enter action name').required('action name is required'),
  possession: yup
    .string('Enter possession name')
    .required('possession name is required'),
})

export const initialPermission = {
  role: '',
  resource: '',
  action: '',
  possession: '',
}

export const addPermission = async (permissionData) => {
  const { data } = await axios.post('/api/permissions', permissionData)
  return data
}

export const updatePermission = async (permissionToUpdate) => {
  const { _id: permissionId } = permissionToUpdate
  const { data: updatedPermissions } = await axios.put(
    `/api/permissions/${permissionId}`,
    permissionToUpdate
  )
  return updatedPermissions
}

export const deletePermissionsByIds = async (permissionIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/permissions', { data: { ids: permissionIds } })
  return deletedCount
}
