import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'

import Thumbnail from 'components/Thumbnail'

import { useRegions } from 'hooks/regions/useRegions'

const useArtistsColumns = (columnNames) => {
  const { regions: regionsMappedById } = useRegions({ mapById: true })

  return useMemo(() => {
    let columns = [
      { name: '_id', title: 'ID' },
      {
        name: 'thumbnail',
        title: 'Thumbnail',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue(artist) {
          return (
            <Link to={`/artists/${artist._id}`}>
              <Thumbnail
                src={artist.playlist?.thumbnail}
                height="100px"
                sqaured
              />
            </Link>
          )
        },
        width: 140,
      },
      {
        name: 'name',
        title: 'Name',
        getCellValue({ name, _id }) {
          return (
            <MuiLink component={Link} to={`/artists/${_id}`}>
              {name}
            </MuiLink>
          )
        },
      },
      {
        name: 'regions',
        title: 'Regions',
        getCellValue: ({ regions }) => {
          if (!regionsMappedById || !regions) return ''

          const regionNames = regions.reduce((acc, regionId) => {
            const regionName = regionsMappedById[regionId]?.name
            return regionName ? [...acc, regionName] : acc
          }, [])

          return regionNames.join(', ')
        },
      },
      { name: 'email', title: 'Email' },
      { name: 'phoneNumber', title: 'Phone number' },
      { name: 'clipCount', title: '# of clips' },
      {
        name: 'user',
        title: 'Related user',
        getCellValue({ user }) {
          return (
            <MuiLink component={Link} to={`/admin/users/${user?._id}`}>
              {user?.name}
            </MuiLink>
          )
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, regionsMappedById])
}

export default useArtistsColumns
