import React, { useContext, createContext, useState } from 'react'

const UsersContext = createContext()

const UsersProvider = ({ children }) => {
  const [selectedUsers, setSelectedUsers] = useState([])

  const value = { selectedUsers, setSelectedUsers }
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

const UsersConsumer = ({ children }) => {
  return (
    <UsersContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('UsersConsumer must be used within a UsersProvider')
        }
        return children(context)
      }}
    </UsersContext.Consumer>
  )
}

const useUsersContext = () => {
  const context = useContext(UsersContext)
  if (context === undefined) {
    throw new Error('useUsersContext must be used within a UsersProvider')
  }
  return context
}

export { UsersProvider, UsersConsumer, useUsersContext }
