import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { IconButton, makeStyles } from '@material-ui/core'

import OptionsMenu from 'components/playlist/OptionsMenu'

import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const PlaylistToolbar = () => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)

  const { playlistId } = usePlaylistContext()

  return (
    <>
      <div className={classes.root}>
        <IconButton
          aria-controls="playlist-options"
          aria-haspopup="true"
          disableRipple
          onClick={(event) => setMenuAnchor(event.currentTarget)}
        >
          <HiDotsHorizontal />
        </IconButton>
      </div>
      <OptionsMenu
        id={playlistId}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
      />
    </>
  )
}

export default PlaylistToolbar
