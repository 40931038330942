import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useSnackbar } from 'hooks/useSnackbar'
import { useRecipes } from 'hooks/recipes/useRecipes'

import { deleteRecipesByIds } from 'utils/recipes'

const RecipesConfirmDeleteDialog = ({ ids, open, onDelete, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateRecipes } = useRecipes()

  const handleDelete = async () => {
    try {
      await deleteRecipesByIds(ids)
      mutateRecipes()
      popSnackbar('success', 'Recipe deleted successfuly')
      onDelete && onDelete()
    } catch (error) {
      popSnackbar('error', 'Could not deleted recipes')
    }
    onClose()
  }

  const title = `Delete recipe${ids.length > 1 ? 's' : ''}`
  const description = `Are you sure you want to delete ${
    ids.length > 1 ? 'these' : 'this'
  } recipe${ids.length > 1 ? 's' : ''}?`

  return (
    <ConfirmDialog
      title={title}
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      {description}
    </ConfirmDialog>
  )
}

export default RecipesConfirmDeleteDialog
