import React, { useRef } from 'react'
import { MdClose } from 'react-icons/md'
import { IconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  tag: {
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(0.5),
  },
  tagText: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  tagRemoveButton: {
    marginRight: theme.spacing(0.2),
    borderRadius: 3,
  },
  valueDialog: {
    minWidth: 300,
  },
}))

const QueryTag = ({ label, value, onClick, onRemove }) => {
  const classes = useStyles()
  const ref = useRef()

  const handleClickClose = (e) => {
    e.stopPropagation()
    onRemove()
  }

  const handleOnClick = () => {
    onClick(ref)
  }

  const tagLabel = `${label}: `

  return (
    <div className={classes.tag} ref={ref} onClick={handleOnClick}>
      <div className={classes.tagText}>
        {tagLabel}
        {value}
      </div>
      <IconButton
        size="small"
        onClick={handleClickClose}
        className={classes.tagRemoveButton}
      >
        <MdClose />
      </IconButton>
    </div>
  )
}

export default QueryTag
