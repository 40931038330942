import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import ClipsList from 'components/clips/ClipsList'

import { useSnackbar } from 'hooks/useSnackbar'
import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'
import {
  removeClipFromPlaylist,
  updatePlaylistClipsOrder,
} from 'utils/playlists'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  loaderContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const PlaylistClips = ({ topChildren, topChildrenHeight }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    clips,
    loadClips,
    totalCount,
    isLoadingClips,
    playlistId,
    mutatePlaylist,
  } = usePlaylistContext()

  const handleOnReorder = useCallback(
    async (origin, destination) => {
      setIsSubmitting(true)

      try {
        await updatePlaylistClipsOrder(playlistId, origin, destination)

        const destinationPageSkip = Math.max(0, destination - 50)
        await loadClips(destinationPageSkip)

        mutatePlaylist()
      } catch (err) {
        popSnackbar('error', 'Failed to reorder clips')
      }

      setIsSubmitting(false)
    },
    [loadClips, mutatePlaylist, playlistId, popSnackbar]
  )

  const handleOnRemove = useCallback(
    async (index) => {
      setIsSubmitting(true)

      try {
        await removeClipFromPlaylist(playlistId, index)

        const destinationPageSkip = Math.max(0, index - 50)
        await loadClips(destinationPageSkip)

        mutatePlaylist()
        popSnackbar('success', 'Clip removed from playlist')
      } catch (error) {
        popSnackbar('error', 'Failed to remove clip from playlist')
      }

      setIsSubmitting(false)
    },
    [loadClips, mutatePlaylist, playlistId, popSnackbar]
  )

  return (
    <div className={classes.root}>
      <ClipsList
        clips={clips}
        loadClips={loadClips}
        totalCount={totalCount}
        isLoading={isLoadingClips || isSubmitting}
        onRemove={handleOnRemove}
        onReorder={handleOnReorder}
        topChildren={topChildren}
        topChildrenHeight={topChildrenHeight}
      />
    </div>
  )
}

export default PlaylistClips
