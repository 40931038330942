import React from 'react'

import { Alert } from '@material-ui/lab'
import { makeStyles, Box } from '@material-ui/core'

import { getYoutubeIdFromUrl } from 'utils/clips'

const useStyles = makeStyles({
  container: {
    flex: '1',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  videoIframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
  },
})

const YoutubeClip = ({ url }) => {
  const classes = useStyles()
  const youtubeId = getYoutubeIdFromUrl(url)
  const embedUrl = `https://www.youtube.com/embed/${youtubeId}`

  const getContent = () => {
    return youtubeId ? (
      // eslint-disable-next-line react/no-unknown-property
      <iframe
        className={classes.videoIframe}
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    ) : (
      url && <Alert severity="error">Could not load youtube clip</Alert>
    )
  }

  return <Box className={classes.container}>{getContent()}</Box>
}

export default YoutubeClip
