import React from 'react'

import { Alert } from '@material-ui/lab'
import { makeStyles, Box } from '@material-ui/core'

import { getSpotifyIdFromUrl } from 'utils/clips'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  videoIframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
  },
}))

const SpotifyTrack = ({ url }) => {
  const classes = useStyles()
  const spotifyUrl = getSpotifyIdFromUrl(url)

  const embedUrl = `https://open.spotify.com/embed/track/${spotifyUrl}?utm_source=generator`

  const getContent = () => {
    return spotifyUrl ? (
      // eslint-disable-next-line react/no-unknown-property
      <iframe
        src={embedUrl}
        width="100%"
        height="152"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    ) : (
      url && <Alert severity="error">Could not load spotify track</Alert>
    )
  }

  return <Box className={classes.container}>{getContent()}</Box>
}

export default SpotifyTrack
