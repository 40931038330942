import React, { useState } from 'react'

import { Switch, Tooltip } from '@material-ui/core'

import { updateRegion } from 'utils/regions'

import { useSnackbar } from 'hooks/useSnackbar'
import { useRegions, useDefaultRegion } from 'hooks/regions/useRegions'

const getLabel = (active) => (active ? 'deactivate' : 'activate')

const ActiveCell = ({ id, active }) => {
  const { popSnackbar } = useSnackbar()
  const { region: defaultRegion } = useDefaultRegion()
  const { regions, mutate: mutateRegions } = useRegions()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleOnChange = async ({ target }) => {
    setTooltipOpen(false)
    setIsSubmitting(true)
    const newActive = target.checked
    const label = getLabel(!newActive)

    try {
      const regionToUpdate = {
        _id: id,
        active: newActive,
      }
      const updatedRegion = await updateRegion(regionToUpdate)

      const updatedRegions = regions.map((region) => {
        if (region._id === id) {
          return { ...region, active: newActive }
        }
        return region
      })
      mutateRegions(updatedRegions)
      popSnackbar(
        'success',
        `Region ${updatedRegion.name} successfully ${label}d`
      )
    } catch (error) {
      popSnackbar('error', `Failed to ${label} region`)
    }
    setIsSubmitting(false)
  }

  return (
    <Tooltip
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      title={`${getLabel(active)} region`}
      arrow
    >
      <Switch
        disabled={isSubmitting || defaultRegion?._id === id}
        size="small"
        checked={!!active}
        onChange={handleOnChange}
      />
    </Tooltip>
  )
}

export default ActiveCell
