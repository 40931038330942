import { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import axios from 'axios'
import produce from 'immer'
import { DEFAULT_POPULATE as DEFAULT_CLIP_POPULATION } from '../clips/useClips'

const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100
const DEFAULT_TYPES = ['normal', 'smart']
const DEFAULT_STATUS = 'active'
const DEFAULT_POPULATE = [
  'editors',
  'relatedArtists',
  'totalDuration',
  'thumbnail',
]

export const usePlaylists = ({
  populateFields = DEFAULT_POPULATE,
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
  name,
  status,
  relatedArtist,
  featuredArtist,
  types = DEFAULT_TYPES,
} = {}) => {
  const options = [`skip=${skip}`, `limit=${limit}`]

  if (name) options.push(`name=${name}`)
  if (status) options.push(`status=${status}`)
  if (relatedArtist) options.push(`relatedArtist=${relatedArtist}`)
  if (featuredArtist) options.push(`featuredArtist=${featuredArtist}`)

  const populate = populateFields?.join(',')
  if (populate?.length) options.push(`populate=${populate}`)

  const typesQuery = types?.join(',')
  if (types?.length) options.push(`types=${typesQuery}`)

  const key = `/api/playlists?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    playlists: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useClipRelatedPlaylists = ({
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
  clipId,
} = {}) => {
  const options = [
    `skip=${skip}`,
    `limit=${limit}`,
    `types=${DEFAULT_TYPES.join(',')}`,
  ]

  const key = `/api/playlists/clip/${clipId}?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    playlists: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const usePlaylistById = (
  id,
  { populateFields = DEFAULT_POPULATE } = {}
) => {
  const options = []

  const populate = populateFields?.join(',')
  if (populate?.length) options.push(`populate=${populate}`)

  const key = `/api/playlists/${id}?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(id && key)

  return {
    playlist: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const usePlaylistsByRegion = (
  regionId,
  genres,
  status = DEFAULT_STATUS,
  {
    populateFields = DEFAULT_POPULATE,
    skip = DEFAULT_SKIP,
    limit = DEFAULT_LIMIT,
  } = {}
) => {
  const options = [
    `region=${regionId}`,
    `status=${status}`,
    `skip=${skip}`,
    `limit=${limit}`,
    `types=${DEFAULT_TYPES.join(',')}`,
  ]

  if (genres?.length) {
    options.push(`genres=${genres.join(',')}`)
  }

  const populate = populateFields?.join(',')
  if (populate?.length) options.push(`populate=${populate}`)

  const key = `/api/playlists?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(regionId && key)

  return {
    playlists: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const usePlaylistClips = ({
  id,
  populateFields = DEFAULT_CLIP_POPULATION,
} = {}) => {
  const [clips, setClips] = useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const populate = populateFields?.join(',')

  const loadClips = useCallback(
    async (skip = DEFAULT_SKIP, limit = DEFAULT_LIMIT) => {
      if (!id) return null

      setIsLoading(true)

      const queries = [`skip=${skip}`, `limit=${limit}`]

      if (populate?.length) queries.push(`populate=${populate}`)

      const key = `/api/playlists/${id}/clips?` + queries.join('&')
      const { data } = await axios.get(key)

      setTotalCount(data.totalCount)

      setClips(
        produce((draft) => {
          data.result.forEach((clip, index) => {
            draft[skip + index] = clip
          })
        })
      )

      setIsLoading(false)
    },
    [id, populate]
  )

  useEffect(() => {
    setClips({})
    setTotalCount(0)
    loadClips()
  }, [loadClips])

  return {
    clips,
    isLoading,
    loadClips,
    totalCount,
  }
}
