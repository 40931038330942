import React from 'react'
import { Formik } from 'formik'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core'

import Select from 'components/Select'
import TextInput from 'components/TextInput'

import { useSnackbar } from 'hooks/useSnackbar'
import {
  usePermissionById,
  usePermissions,
} from 'hooks/permissions/usePermissions'

import {
  addPermission,
  initialPermission,
  permissionValidationSchema,
  updatePermission,
} from 'utils/permissions'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const PermissionDialog = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const isNew = !id
  const { mutate: mutatePermissions } = usePermissions()
  const {
    permission,
    isLoading,
    mutate: mutatePermissionById,
  } = usePermissionById(!isNew && id)

  const handleSubmit = async (permissionData) => {
    let action
    let messages
    try {
      if (isNew) {
        action = addPermission
        messages = {
          success: 'Successfully added permission',
          error: 'Failed to save permission',
        }
      } else {
        action = updatePermission
        messages = {
          success: 'Successfully updated permission',
          error: 'Failed to update permission',
        }
      }
      await action(permissionData)
      mutatePermissions()
      mutatePermissionById()
      popSnackbar('success', messages.success)
    } catch (error) {
      popSnackbar('error', messages.error)
    }
    onClose()
  }

  const summaryText = (values) => {
    const { role, action, possession, resource } = values
    if (!role || !action || !possession || !resource) return ''

    return `Allow ${role} to ${action} ${possession} ${resource}`
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${isNew ? 'Add' : 'Edit'} Permission`}</DialogTitle>
      {!isNew && isLoading ? (
        <DialogContent className={classes.loading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          validationSchema={permissionValidationSchema}
          initialValues={isNew ? initialPermission : permission}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <TextInput
                  name="role"
                  label="Role"
                  placeholder="Enter permission role"
                  required
                />
                <Select
                  name="action"
                  label="Action"
                  placeholder="Select action"
                  options={['create', 'read', 'update', 'delete']}
                />
                <Select
                  name="possession"
                  label="Possession"
                  placeholder="Select possession"
                  options={['own', 'any']}
                />
                <TextInput
                  name="resource"
                  label="Resource"
                  placeholder="Enter permission resource"
                  required
                />
                <Typography variant="h3">
                  {summaryText(formik.values)}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default PermissionDialog
