import React from 'react'
import { useField } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import {
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

const Select = ({
  label,
  required,
  helperText,
  fullWidth,
  multiple,
  placeholder,
  onClickTag,
  ...props
}) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)
  const error = meta.touched && meta.error

  const handleOnChange = (e, value) => {
    helpers.setValue(value).then(() => helpers.setTouched(true))
  }

  return (
    <FormControl
      required={required}
      margin="normal"
      error={!!error}
      fullWidth={fullWidth}
    >
      <FormLabel id={`${label}-select-label`}>{label}</FormLabel>
      <Autocomplete
        className={classes.select}
        autoComplete
        size="small"
        labelId={`${label}-select-label`}
        variant="outlined"
        {...field}
        fullWidth={fullWidth}
        onChange={handleOnChange}
        multiple={multiple}
        value={meta.value || (multiple ? [] : '')}
        renderTags={(values, getTagProps) => (
          <div className={classes.chipsContainer}>
            {values.map((value, index) => (
              <Chip
                size="small"
                key={index}
                label={props.getOptionLabel?.(value) || value}
                {...getTagProps({ index })}
                onClick={() => onClickTag(value)}
              />
            ))}
          </div>
        )}
        {...props}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
            placeholder={placeholder}
          />
        )}
      />
      {error && (
        <FormHelperText error>
          {typeof error !== 'string' ? JSON.stringify(error) : error}
        </FormHelperText>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
