import axios from 'axios'

export const addClip = async (clipData) => {
  const { data } = await axios.post('/api/clips', clipData)
  return data
}

export const updateClip = async (clipToUpdate) => {
  const { _id: clipId } = clipToUpdate
  const { data: updatedClip } = await axios.put(
    `/api/clips/${clipId}`,
    clipToUpdate
  )
  return updatedClip
}

export const deleteClipsByIds = async (clipIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/clips', { data: { ids: clipIds } })
  return deletedCount
}

export const getYoutubeIdFromUrl = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url?.match(regExp)

  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return ''
  }
}

export const getSpotifyIdFromUrl = (url) => {
  const regExp =
    /(https?:\/\/open.spotify.com\/(track|user|artist|album)\/([a-zA-Z0-9]+)(\/playlist\/[a-zA-Z0-9]+|)|spotify:(track|user|artist|album):[a-zA-Z0-9]+(:playlist:[a-zA-Z0-9]+|))/
  const match = url?.match(regExp)

  if (match && match[3].length == 22) {
    return match[3]
  } else {
    return ''
  }
}
