import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { useDebounce } from 'use-debounce'

import { useAuth } from 'hooks/useAuthContext'
import { getUserLocation, sendEventBatch } from 'utils/eventStream'

const EventStreamContext = createContext()

const BATCH_DEBOUNCE = 1000 * 3
const BATCH_DEBOUNCE_MAX_WAIT = 1000 * 5

const EventStreamProvider = ({ children }) => {
  const currentUserRef = useRef()
  const clientLocationRef = useRef()

  const { currentUser } = useAuth()
  const [eventsBatch, setEventsBatch] = useState([])

  const [debouncedEventsBatch] = useDebounce(eventsBatch, BATCH_DEBOUNCE, {
    maxWait: BATCH_DEBOUNCE_MAX_WAIT,
  })

  useEffect(() => {
    currentUserRef.current = currentUser
  }, [currentUser])

  useEffect(() => {
    const getClientLocation = async () => {
      try {
        const userLocation = await getUserLocation()
        clientLocationRef.current = userLocation
      } catch (error) {
        clientLocationRef.current = null
      }
    }
    getClientLocation()
  }, [])

  useEffect(() => {
    if (!debouncedEventsBatch.length) return

    const sendEventsBatch = async (batchToSend) => {
      await sendEventBatch(batchToSend)
      setEventsBatch((prev) => prev.slice(batchToSend.length))
    }
    sendEventsBatch(debouncedEventsBatch)
  }, [debouncedEventsBatch])

  const getEventMetadata = useCallback(
    () => ({
      pathname: window.location.pathname,
      user: currentUserRef.current,
      createdAt: new Date(),
      location: clientLocationRef.current,
      pageWidth: window.innerWidth,
      pageHeight: window.innerHeight,
      agent: window.navigator.userAgent,
      language: window.navigator.languages,
    }),
    []
  )

  const addEvent = useCallback(
    (eventData) => {
      const event = {
        ...getEventMetadata(),
        ...eventData,
      }
      setEventsBatch((prev) => [...prev, event])
    },
    [getEventMetadata]
  )

  const addPageLoadEvent = useCallback(
    (pathname) => {
      const eventData = {
        type: 'PAGE_LOADED',
        payload: {
          pathname,
        },
      }
      addEvent(eventData)
    },
    [addEvent]
  )

  const addHoverEvent = useCallback(
    (event) => {
      const eventData = {
        type: 'HOVER',
        payload: {
          mouseX: event.clientX,
          mouseY: event.clientY,
          elementId: event.currentTarget.id,
        },
      }
      addEvent(eventData)
    },
    [addEvent]
  )

  const addClickEvent = useCallback(
    (event) => {
      const eventData = {
        type: 'CLICK',
        payload: {
          mouseX: event.clientX,
          mouseY: event.clientY,
          elementId: event.currentTarget.id,
        },
      }
      addEvent(eventData)
    },
    [addEvent]
  )

  const addClipViewEvent = useCallback(
    (clipId, playlistId, regionId) => {
      const eventData = {
        type: 'VIEW_CLIP',
        payload: {
          clipId,
          regionId,
          playlistId,
        },
      }
      addEvent(eventData)
    },
    [addEvent]
  )

  const value = {
    addEvent,
    addPageLoadEvent,
    addHoverEvent,
    addClickEvent,
    addClipViewEvent,
  }

  return (
    <EventStreamContext.Provider value={value}>
      {children}
    </EventStreamContext.Provider>
  )
}

const useEventStream = () => {
  const context = useContext(EventStreamContext)
  if (context === undefined) {
    throw new Error('useEventStream must be used within a EventStreamProvider')
  }
  return context
}

export { EventStreamProvider, useEventStream }
