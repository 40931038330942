import React from 'react'

import 'swiper/swiper-bundle.css'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper } from 'swiper/react'

SwiperCore.use([Navigation])

const DEFAULT_PLAYLISTS_PER_SLIDE = 2
const DEFAULT_SLIDES_PER_GROUP = 2
const DEFAULT_SPACE_BETWEEN_SLIDES = 5
const SLIDER_BREAKPOINTS = {
  600: {
    spaceBetween: 20,
    slidesPerView: 3,
    slidesPerGroup: 2,
  },
  1200: {
    spaceBetween: 20,
    slidesPerView: 5,
    slidesPerGroup: 4,
  },
  1536: {
    spaceBetween: 25,
    slidesPerView: 6,
    slidesPerGroup: 5,
  },
  3840: {
    spaceBetween: 35,
    slidesPerView: 7,
    slidesPerGroup: 6,
  },
}

const DemoPlaylistsSwiper = ({
  children,
  navigationNextRef,
  navigationPrevRef,
  containerClassname,
}) => {
  const navigationSwiperObject = {
    prevEl: navigationPrevRef.current,
    nextEl: navigationNextRef.current,
  }

  const beforeSwiperInnit = (swiper) => {
    swiper.params.navigation.prevEl = navigationPrevRef.current
    swiper.params.navigation.nextEl = navigationNextRef.current
  }

  return (
    <Swiper
      rewind
      noSwiping
      allowTouchMove={false}
      slidesPerGroup={DEFAULT_SLIDES_PER_GROUP}
      spaceBetween={DEFAULT_SPACE_BETWEEN_SLIDES}
      slidesPerView={DEFAULT_PLAYLISTS_PER_SLIDE}
      className={containerClassname}
      breakpoints={SLIDER_BREAKPOINTS}
      navigation={navigationSwiperObject}
      onBeforeInit={beforeSwiperInnit}
    >
      {children}
    </Swiper>
  )
}

export default DemoPlaylistsSwiper
