import React, { useMemo } from 'react'

import Select from 'components/Select'
import TextInput from 'components/TextInput'
import SelectArtists from 'components/SelectArtists'
import SelectEditors from 'components/SelectEditors'

import { useGenres } from 'hooks/genres/useGenres'
import { useRegions } from 'hooks/regions/useRegions'
import { playlistStatusOptions } from 'consts/playlists'

const PlaylistForm = () => {
  const { genres, isLoading: isLoadingGenres } = useGenres({ mapById: true })
  const { regions, isLoading: isLoadingRegions } = useRegions({ mapById: true })

  const genresOptions = useMemo(
    () => (genres ? Object.keys(genres) : []),
    [genres]
  )
  const countriesOptions = useMemo(
    () => (regions ? Object.keys(regions) : []),
    [regions]
  )

  return (
    <>
      <TextInput
        name="name"
        label="Playlist title"
        placeholder="Enter playlist title"
        required
      />
      <SelectEditors />
      <Select
        name="regions"
        label="Related regions or region"
        placeholder="Select one region or more"
        multiple
        options={countriesOptions}
        getOptionLabel={(regionId) =>
          (regions && regions[regionId]?.name) || ''
        }
        isLoading={isLoadingRegions}
        required
      />
      <Select
        name="genres"
        label="Genres"
        placeholder="Select one genre or more"
        multiple
        options={genresOptions}
        getOptionLabel={(genreId) => (genres && genres[genreId]?.name) || ''}
        isLoading={isLoadingGenres}
        required
      />
      <TextInput
        name="description"
        label="Enter description for playlist"
        multiline
        rows={2}
        maxRows={4}
      />
      <SelectArtists
        name="relatedArtists"
        label="Related Artists"
        fullWidth
        size="small"
        disableClearable
        multiple
        placeholder="Select one or more artists"
      />
      <Select
        name="status"
        label="Status"
        options={playlistStatusOptions.map((option) => option.value)}
        getOptionLabel={(option) =>
          playlistStatusOptions.find((o) => o.value === option)?.label
        }
        disableClearable
        fullWidth
        required
      />
    </>
  )
}

export default PlaylistForm
