import React, { useState } from 'react'
import classnames from 'classnames'
import { Link, useRouteMatch } from 'react-router-dom'

import { FaRobot } from 'react-icons/fa'
import { BiWorld } from 'react-icons/bi'
import { ImDisplay } from 'react-icons/im'
import {
  MdPerson,
  MdDashboard,
  MdVideoLibrary,
  MdPlaylistPlay,
  MdAdminPanelSettings,
} from 'react-icons/md'
import {
  Box,
  List,
  Button,
  Drawer,
  Tooltip,
  ListItem,
  makeStyles,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    alignItems: 'stretch',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  drawerExpanded: {
    width: theme.spacing(30),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClosed: {
    width: theme.spacing(7),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerHeader: {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    height: theme.spacing(6),
    textDecoration: 'none',
    color: 'inherit',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  drawerHeaderCollapsed: {
    justifyContent: 'center',
    paddingLeft: theme.spacing(0),
  },
  collapseButton: {
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
  },
}))

const sidebarItems = [
  {
    label: 'Dashboard',
    exact: true,
    path: '/',
    icon: <MdDashboard />,
  },
  {
    label: 'Clips',
    path: '/clips',
    icon: <MdVideoLibrary />,
  },
  {
    label: 'Playlists',
    path: '/playlists',
    icon: <MdPlaylistPlay />,
  },
  {
    label: 'Recipes',
    path: '/recipes',
    icon: <FaRobot />,
  },
  {
    label: 'Artists',
    path: '/artists',
    icon: <MdPerson />,
  },
  {
    label: 'Regions',
    path: '/regions',
    icon: <BiWorld />,
  },
  {
    label: 'Demo',
    path: '/demo',
    icon: <ImDisplay />,
  },
  {
    label: 'Admin',
    path: '/admin',
    icon: <MdAdminPanelSettings />,
  },
]

const SidebarMenuItem = ({ label, exact, path, icon, collapsed }) => {
  const match = !!useRouteMatch({ path, exact })

  return (
    <Tooltip title={label} placement="right">
      <ListItem selected={match} button component={Link} to={path}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {!collapsed && <ListItemText primary={label} />}
      </ListItem>
    </Tooltip>
  )
}

const LOCAL_STORAGE_KEY = 'sidebarExpanded'

const SidebarMenu = () => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(
    () => !!localStorage.getItem(LOCAL_STORAGE_KEY)
  )

  const onToggleExpanded = () =>
    setIsExpanded((prev) => {
      prev
        ? localStorage.removeItem(LOCAL_STORAGE_KEY)
        : localStorage.setItem(LOCAL_STORAGE_KEY, 1)
      return !prev
    })

  const drawerClasses = classnames(
    classes.drawer,
    isExpanded ? classes.drawerExpanded : classes.drawerClosed
  )

  return (
    <Drawer
      className={drawerClasses}
      classes={{ paper: drawerClasses }}
      open
      anchor="left"
      variant="persistent"
    >
      <Button
        className={classnames(
          classes.drawerHeader,
          !isExpanded && classes.drawerHeaderCollapsed
        )}
        component={Link}
        to="/"
      >
        <Typography variant="h3">{isExpanded ? 'Trippiclips' : 'T'}</Typography>
      </Button>
      <List>
        {sidebarItems.map((item, index) => (
          <SidebarMenuItem {...item} key={index} collapsed={!isExpanded} />
        ))}
      </List>
      <Box flex="1" />
      <Button onClick={onToggleExpanded} className={classes.collapseButton}>
        {isExpanded ? (
          <>
            <ChevronLeft />
            Collapse sidebar
          </>
        ) : (
          <ChevronRight />
        )}
      </Button>
    </Drawer>
  )
}

export default SidebarMenu
