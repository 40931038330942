import React from 'react'
import { makeStyles } from '@material-ui/core'

import RecipesTable from 'components/recipes/RecipesTable'
import RecipeDialog from 'components/recipes/RecipeDialog'
import RecipesHeader from 'components/recipes/RecipesHeader'

import { RecipesProvider } from 'hooks/recipes/useRecipesContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Recipes = () => {
  const classes = useStyles()

  return (
    <RecipesProvider>
      <div className={classes.root}>
        <RecipesHeader />
        <RecipesTable />
        <RecipeDialog />
      </div>
    </RecipesProvider>
  )
}

export default Recipes
