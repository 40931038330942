import React from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core'

import TextInput from 'components/TextInput'
import RangeSlider from 'components/RangeSlider'
import SelectOwners from 'components/SelectOwners'
import TimeIntervalInput from 'components/TimeIntervalInput'
import SelectRegions from 'components/regions/SelectRegions'
import SelectGenres from 'components/admin/genres/SelectGenres'

import { addRecipe, updateRecipe } from 'utils/recipes'

import { useSnackbar } from 'hooks/useSnackbar'

import recipeValidationSchema from 'schemas/recipe'
import { useRecipeById } from 'hooks/recipes/useRecipes'
import { useRecipesContext } from 'hooks/recipes/useRecipesContext'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}))

const initialRecipe = {
  regions: [],
  genres: [],
  owners: [],
  interval: 1000 * 60 * 10,
  ratings: [0, 10],
  contentTypes: [],
  name: '',
  description: '',
  maxDuration: 1000 * 60 * 40,
}

const RecipeDialog = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id: recipeId } = useParams()
  const { popSnackbar } = useSnackbar()

  const { mutate } = useRecipesContext()

  const isNewRecipe = recipeId === 'new'

  const { recipe, isLoading } = useRecipeById(!isNewRecipe && recipeId)

  const handleOnClose = () => {
    history.push('/recipes')
  }

  const handleOnSubmit = async (values) => {
    try {
      isNewRecipe ? await addRecipe(values) : await updateRecipe(values)
      popSnackbar(
        'success',
        `Recipe ${isNewRecipe ? 'added' : 'updated'} successfully`
      )
      mutate()
      handleOnClose()
    } catch (error) {
      popSnackbar('error', `Failed to ${isNewRecipe ? 'add' : 'update'} recipe`)
    }
  }

  const formik = useFormik({
    onSubmit: handleOnSubmit,
    isInitialValid: false,
    validateOnMount: true,
    validateOnChange: true,
    initialValues: recipe || initialRecipe,
    enableReinitialize: true,
    validationSchema: recipeValidationSchema,
  })

  if (!recipeId) return null

  const disabled = !isNewRecipe && isLoading

  const canSave =
    formik.isValid && !formik.isSubmitting && (isNewRecipe || formik.dirty)

  return (
    <Dialog open fullWidth onClose={handleOnClose}>
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle> {isNewRecipe ? 'Add' : 'Update'} Recipe</DialogTitle>
          <DialogContent className={classes.form}>
            <TextInput
              name="name"
              label="Name"
              required
              fullWidth
              disabled={disabled}
            />
            <TextInput
              name="description"
              label="Description"
              required
              fullWidth
              multiline
              disabled={disabled}
            />
            <SelectRegions name="regions" label="Regions" multiple />
            <SelectGenres name="genres" label="Genres" multiple />
            <SelectOwners name="owners" label="Owners" multiple />
            <RangeSlider
              name="ratings"
              label="Ratings"
              marks
              min={0}
              max={25}
              helperText="The range of indexes (0 = first) to choose from a matching playlist."
            />
            <TimeIntervalInput name="interval" label="Interval" required />
            <TimeIntervalInput name="maxDuration" label="Max Duration" />
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!canSave || disabled}
            >
              {isNewRecipe ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
export default RecipeDialog
