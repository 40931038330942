import React from 'react'
import { FormikProvider, useFormik } from 'formik'

import yup from 'schemas/yup'

import Select from 'components/Select'
import ConfirmDialog from 'components/ConfirmDialog'

import { useSnackbar } from 'hooks/useSnackbar'
import { usePlaylists } from 'hooks/playlists/usePlaylist'

import { playlistStatusOptions } from 'consts/playlists'
import { setPlaylistsStatusByIds } from 'utils/playlists'

const validationSchema = yup.object({
  status: yup
    .string()
    .oneOf(playlistStatusOptions.map((option) => option.value))
    .required('Status is required'),
})

const PlaylistsSetStatusDialog = ({ ids, open, onUpdate, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutatePlaylists } = usePlaylists()

  const handleOnSubmit = async ({ status }) => {
    try {
      await setPlaylistsStatusByIds(ids, status)
      mutatePlaylists()
      popSnackbar('success', `Playlists status set to ${status} successfuly`)
      onUpdate && onUpdate()
    } catch (error) {
      popSnackbar('error', `Could not set playlists status to ${status} `)
    }
    onClose()
  }

  const formik = useFormik({
    isInitialValid: false,
    validateOnMount: true,
    initialValues: { status: '' },
    validationSchema,
    onSubmit: handleOnSubmit,
  })

  const title = `Update playlist${ids.length > 1 ? 's' : ''} status`
  const description =
    formik.values.status &&
    `Are you sure you want to set ${
      ids.length > 1 ? 'these' : 'this'
    } playlist${ids.length > 1 ? 's' : ''} status to ${formik.values.status}?`

  return (
    <FormikProvider value={formik}>
      <ConfirmDialog
        title={title}
        open={open}
        onClose={onClose}
        canConfirm={formik.isValid}
        onConfirm={formik.submitForm}
        labels={{ confirm: 'Update', reject: 'Cancel' }}
      >
        <Select
          name="status"
          label="Status"
          options={playlistStatusOptions.map((option) => option.value)}
          getOptionLabel={(option) =>
            playlistStatusOptions.find((o) => o.value === option)?.label
          }
          disableClearable
          fullWidth
        />
        {description}
      </ConfirmDialog>
    </FormikProvider>
  )
}

export default PlaylistsSetStatusDialog
