import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useUsers } from 'hooks/users/useUsers'
import { useSnackbar } from 'hooks/useSnackbar'

import { deleteUsersByIds } from 'utils/users'

const UsersConfirmDeleteDialog = ({ ids, open, onDelete, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateUsers } = useUsers()

  const handleDelete = async () => {
    try {
      await deleteUsersByIds(ids)
      mutateUsers()
      popSnackbar('success', 'User deleted successfuly')
      onDelete ? onDelete() : onClose()
    } catch (error) {
      popSnackbar('error', 'Could not deleted user')
    }
  }

  return (
    <ConfirmDialog
      title="Delete users"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these users?
    </ConfirmDialog>
  )
}

export default UsersConfirmDeleteDialog
