import React, { useContext, createContext, useState, useEffect } from 'react'

import { DEFAULT_TABLE_PAGE_SIZE } from 'components/table/Table'

import { usePlaylists } from 'hooks/playlists/usePlaylist'

const PlaylistsContext = createContext()

const PlaylistsProvider = ({ children }) => {
  const [query, setQuery] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [selectedPlaylists, setSelectedPlaylists] = useState([])
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE)

  const [currentPage, setCurrentPage] = useState(0)

  const { playlists, totalCount, isLoading, mutate } = usePlaylists({
    name: searchValue,
    limit: pageSize,
    skip: currentPage * pageSize,
    ...query,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [query, searchValue])

  const value = {
    playlists,
    query,
    mutate,
    setQuery,
    isLoading,
    totalCount,
    setPageSize,
    selectedPlaylists,
    setCurrentPage,
    setSearchValue,
    setSelectedPlaylists,
  }

  return (
    <PlaylistsContext.Provider value={value}>
      {children}
    </PlaylistsContext.Provider>
  )
}

const PlaylistsConsumer = ({ children }) => {
  return (
    <PlaylistsContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'PlaylistsConsumer must be used within a PlaylistsProvider'
          )
        }
        return children(context)
      }}
    </PlaylistsContext.Consumer>
  )
}

const usePlaylistsContext = () => {
  const context = useContext(PlaylistsContext)
  if (context === undefined) {
    throw new Error(
      'usePlaylistsContext must be used within a PlaylistsProvider'
    )
  }
  return context
}

export { PlaylistsProvider, PlaylistsConsumer, usePlaylistsContext }
