import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'

import { useRegions } from 'hooks/regions/useRegions'

const useOwnersColumns = (columnNames) => {
  const { regions: regionsMappedById } = useRegions({ mapById: true })

  return useMemo(() => {
    let columns = [
      { name: '_id', title: 'ID' },
      {
        name: 'name',
        title: 'Name',
        getCellValue({ name, _id }) {
          return (
            <MuiLink component={Link} size="small" to={`/admin/owners/${_id}`}>
              {name}
            </MuiLink>
          )
        },
      },
      {
        name: 'regions',
        title: 'Regions',
        getCellValue: ({ regions }) => {
          if (!regionsMappedById || !regions) return ''

          const regionNames = regions.reduce((acc, regionId) => {
            const regionName = regionsMappedById[regionId]?.name
            return regionName ? [...acc, regionName] : acc
          }, [])

          return regionNames.join(', ')
        },
      },
      { name: 'email', title: 'Email' },
      { name: 'phoneNumber', title: 'Phone number' },
      {
        name: 'user',
        title: 'Related user',
        getCellValue({ user }) {
          return (
            <MuiLink component={Link} to={`/admin/users/${user?._id}`}>
              {user?.name}
            </MuiLink>
          )
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, regionsMappedById])
}

export default useOwnersColumns
