import React from 'react'
import { Formik } from 'formik'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import TextInput from 'components/TextInput'

import { useSnackbar } from 'hooks/useSnackbar'
import { useOwners } from 'hooks/owners/useOwners'

import { addOwner, initialOwner } from 'utils/owners'

import ownerValidationSchema from 'schemas/owner'

const AddOwnerDialog = ({ open, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateOwners } = useOwners()

  const handleOnSubmit = async (ownerData) => {
    try {
      await addOwner(ownerData)
      mutateOwners()
      popSnackbar('success', 'Successfully added owner')
      onClose()
    } catch (error) {
      popSnackbar('error', 'Failed to save owner')
    }
  }

  return (
    <Formik
      initialValues={initialOwner}
      isInitialValid={false}
      validationSchema={ownerValidationSchema}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {(formik) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Add owner</DialogTitle>
          <DialogContent>
            <TextInput
              name="name"
              label="Owner name"
              placeholder="Enter owner name in english"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={formik.submitForm}
              disabled={!formik.isValid}
              color="primary"
              variant="contained"
            >
              Add owner
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default AddOwnerDialog
