import React, { useState } from 'react'

import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import UserDialog from 'components/admin/users/UserDialog'

const EditCell = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton size="small" onClick={() => setIsOpen(true)}>
        <EditIcon fontSize="small" />
      </IconButton>
      {isOpen && <UserDialog open id={id} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default EditCell
