import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Youtube from 'react-youtube'

import {
  MdFullscreen,
  MdFullscreenExit,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdPause,
  MdPlayArrow,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md'

import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'

import Rating from './Rating'
import Header from './Header'

import { useDemo } from 'hooks/demo/useDemo'
import { useRegionById } from 'hooks/regions/useRegions'
import { useEventStream } from 'hooks/useEventStream'

import { getYoutubeIdFromUrl } from 'utils/clips'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
  overlay: {
    visibility: 'visible',
    opacity: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    transition: 'visibility 0.3s linear, opacity 0.3s linear',
  },
  hideOverlay: {
    visibility: 'none',
    opacity: 0,
  },
  insetShadow: {
    height: '100%',
    marginLeft: '-100px',
    marginRight: '-100px',
    boxShadow: 'inset 0px 100px 100px rgba(0, 0, 0, 0.7)',
  },
  actionButtons: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    top: '50%',
    width: '100%',
    padding: theme.spacing(4),
    transform: 'translateY(-50%)',
    '& > *': {
      color: theme.palette.common.white,
      fontSize: '40px',
      transition: '.2s color',
      pointerEvents: 'all',
      '&:hover': {
        color: theme.palette.grey[400],
      },
    },
  },
  bottomActionButtons: {
    position: 'absolute',
    display: 'flex',
    bottom: '0',
    width: '100%',
    padding: theme.spacing(4),
    '& > *': {
      color: theme.palette.common.white,
      fontSize: '40px',
      transition: '.2s color',
      pointerEvents: 'all',
      '&:hover': {
        color: theme.palette.grey[400],
      },
    },
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  videoContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    transition:
      'margin 0.3s ease-in-out, padding 0.3s ease-in-out, height 0.3s ease-in-out',
  },
  videoContainerFullscreen: {
    height: '100vh',
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  videoTitle: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    bottom: 115,
    left: '0',
    width: '100%',
    zIndex: 1,
    paddingLeft: theme.spacing(6.5),
    color: theme.palette.common.white,
    pointerEvents: 'all',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(4),
    },
  },
}))

const OVERLAY_TIMEOUT = 3000

const Hero = () => {
  const classes = useStyles()
  const playerRef = useRef()
  const overlayRef = useRef()
  const hideOverlayTimout = useRef()
  const { addClickEvent } = useEventStream()
  const [isReady, setIsReady] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const { currentClip, nextClip, prevClip, hasNext, freezeOverlay } = useDemo()
  const currentClipRegionId = currentClip?.regions?.at(0)
  const { region: currentClipRegion } = useRegionById(currentClipRegionId)

  const youtubeId = getYoutubeIdFromUrl(currentClip?.youtubeUrl)

  const handleOnReady = ({ target: player }) => {
    setIsReady(true)
    playerRef.current = player

    isMuted ? player.mute() : player.unMute()
  }

  const handleOnStateChange = ({ target: player }) => {
    playerRef.current = player
  }

  const handleOnEnd = () => {
    if (hasNext) return nextClip()

    const player = playerRef.current
    player.seekTo(0)
  }

  const handleToggleMute = () => {
    const player = playerRef.current

    setIsMuted((prev) => {
      prev ? player.unMute() : player.mute()

      return !prev
    })
  }

  const handleToggleFullscreen = () => {
    setIsFullscreen((prev) => !prev)
  }

  const formattedVideoTitle =
    currentClip &&
    currentClipRegion &&
    `${currentClipRegion.name} / ${currentClip.artists
      .map((artist) => artist.name)
      .join(', ')} - ${currentClip.name}`

  const handleClickPlay = () => {
    const player = playerRef.current
    const currentIsPlaying = player.getPlayerState() === 1

    currentIsPlaying ? player.pauseVideo() : player.playVideo()

    setIsPlaying(!currentIsPlaying)
  }

  const resetOverlayTimout = useCallback(() => {
    setShowOverlay(true)
    clearTimeout(hideOverlayTimout.current)

    hideOverlayTimout.current = setTimeout(() => {
      !freezeOverlay && setShowOverlay(false)
    }, OVERLAY_TIMEOUT)
  }, [freezeOverlay])

  useEffect(() => {
    setShowOverlay(true)
  }, [currentClip])

  useEffect(() => {
    resetOverlayTimout()

    return () => {
      clearTimeout(hideOverlayTimout.current)
    }
  }, [resetOverlayTimout, currentClip])

  return (
    <div className={classes.root} onMouseMove={resetOverlayTimout}>
      <div
        className={classnames(
          classes.videoContainer,
          isFullscreen && classes.videoContainerFullscreen
        )}
      >
        <Youtube
          key={youtubeId}
          videoId={youtubeId}
          className={classes.video}
          onEnd={handleOnEnd}
          onReady={handleOnReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onStateChange={handleOnStateChange}
          opts={{
            playerVars: {
              controls: 0,
              iv_load_policy: 3,
              autoplay: 1,
              showinfo: 0,
              modestbranding: 1,
              rel: 0,
            },
          }}
        />
      </div>
      <div
        ref={overlayRef}
        className={classnames(
          classes.overlay,
          !showOverlay && classes.hideOverlay
        )}
      >
        <div className={classes.insetShadow} />
        <Header />
        <div className={classes.actionButtons}>
          <IconButton
            id="prevClipButton"
            disabled={!hasNext}
            onClick={prevClip}
            onClickCapture={addClickEvent}
          >
            <MdOutlineChevronLeft />
          </IconButton>
          <IconButton
            id="playButton"
            disabled={!isReady}
            onClickCapture={addClickEvent}
            onClick={handleClickPlay}
          >
            {isPlaying ? <MdPause /> : <MdPlayArrow />}
          </IconButton>
          <IconButton
            id="nextClipButton"
            disabled={!hasNext}
            onClick={nextClip}
            onClickCapture={addClickEvent}
          >
            <MdOutlineChevronRight />
          </IconButton>
        </div>
        <div className={classes.videoTitle}>
          <Typography variant="h2">{formattedVideoTitle}</Typography>
        </div>
        <div className={classes.bottomActionButtons}>
          <IconButton
            id="muteButton"
            onClick={handleToggleMute}
            onClickCapture={addClickEvent}
          >
            {isMuted ? <MdVolumeOff /> : <MdVolumeUp />}
          </IconButton>
          <Box flex="1" />
          <Rating />
          <IconButton
            id="fullscreenButton"
            onClick={handleToggleFullscreen}
            onClickCapture={addClickEvent}
          >
            {isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default Hero
