import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Button } from '@material-ui/core'

import Header from 'components/Header'
import OwnersTable from 'components/admin/owners/OwnersTable'
import OwnersConfirmDeleteDialog from 'components/admin/owners/OwnersConfirmDeleteDialog'

import { useOwnersContext } from 'hooks/owners/useOwnersContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Owners = () => {
  const classes = useStyles()
  const history = useHistory()

  const { selectedOwners, setSelectedOwners } = useOwnersContext()
  const [openDialog, setOpenDialog] = useState('')

  const handleOnAddOwner = () => {
    history.push('/admin/owners/new')
  }

  return (
    <div className={classes.root}>
      <Header title="Owners">
        <Button
          variant="outlined"
          color="secondary"
          disabled={!selectedOwners.length}
          onClick={() => setOpenDialog('deletedOwners')}
        >
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleOnAddOwner}>
          Add new owner
        </Button>
      </Header>
      <OwnersTable />
      {openDialog === 'deletedOwners' && (
        <OwnersConfirmDeleteDialog
          open
          ids={selectedOwners}
          onDelete={() => {
            setSelectedOwners([])
            setOpenDialog('')
          }}
          onClose={() => setOpenDialog('')}
        />
      )}
    </div>
  )
}

export default Owners
