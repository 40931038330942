import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'
import React, { useState } from 'react'

import GridLayout from 'react-grid-layout'
import { makeStyles } from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'

import theme from 'theme'

import Header from 'components/Header'
import Chart from 'components/Dashboard/Chart'
import TableChart from 'components/Dashboard/TableChart'
import FilterByRegion from 'components/Dashboard/FilterByRegion'

import useGenresMetadataRows, {
  getChildRows as getGenreChildren,
} from 'hooks/genres/useGenresMetadataRows'
import useRegionsMetadataRows, {
  getChildRows as getRegionChildren,
} from 'hooks/regions/useRegionsMetadataRows'
import { useDashboardLayout } from 'hooks/users/useDashboardLayout'

const DEFAULT_EXPANDED_ROWS = ['Worldwide']

const CLIPS_METADATA_FIELDS = [
  'clipCount',
  'clipsTotalDuration',
  'playlistCount',
  'playlistsTotalDuration',
]

const GENRES_METADATA_FIELDS = [
  'clipCount',
  'clipsTotalDuration',
  'playlistCount',
  'playlistsTotalDuration',
]

const CHARTS = [
  {
    name: 'clipCount',
    id: '7edafaa4-da36-4d92-932d-30c9ac1e18c5',
    filtered: true,
  },
  {
    name: 'ownersCount',
    id: 'f6d04878-06d6-4483-bf40-9d6bb43ba563',
    filtered: true,
  },
  {
    name: 'artistsCount',
    id: 'c348323d-19ac-4ac5-a0e9-33dfd87bd668',
    filtered: true,
  },
  {
    name: 'playlistsCount',
    id: '0d0c5091-bacd-4d36-a853-9b44e202ed93',
    filtered: true,
  },
  {
    name: 'clipCountByGenre',
    id: '62a34c45-1170-4355-8763-351b43f6b57d',
    filtered: true,
  },
  {
    name: 'totalClipDuration',
    id: '62a317ca-1269-4cf2-887d-4da63fad6df3',
    filtered: true,
  },
  {
    name: 'totalPlaylistsDuration',
    id: '37128e05-96f3-4233-87da-38f6ac442377',
    filtered: true,
  },
  {
    name: 'contentAmountByGenreInHours',
    id: '62a3691c-1170-4164-81ad-351b431565d9',
    filtered: true,
  },
]

const WithDragHandle = (widget) => {
  const classes = useStyles()

  return (
    <>
      {widget || <div />}
      <DragHandleIcon className={classes.dragHandle} fontSize="small" />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
  reactGrid: {
    flex: 1,
    position: 'relative',
    '& > *': {
      border: '1px solid',
      borderColor: theme.palette.divider,
    },
  },
  dragHandle: {
    top: 0,
    left: -4,
    position: 'absolute',
    transform: 'rotate(90deg)',
    '&:hover': {
      cursor: 'grab',
    },
  },
}))

const Dashboard = () => {
  const classes = useStyles()
  const [filter, setFilter] = useState()
  const { layout, changeLayout } = useDashboardLayout()
  const { rows: genreRows } = useGenresMetadataRows({
    metadataFields: GENRES_METADATA_FIELDS,
  })
  const { rows: regionRows, defaultExpandedRegionIndexes } =
    useRegionsMetadataRows({
      metadataFields: CLIPS_METADATA_FIELDS,
      defaultExpandedRowNames: DEFAULT_EXPANDED_ROWS,
    })

  const handleChangeFilter = (_, value) => {
    setFilter(
      value?.name && {
        'regions.name': {
          $in: [value?.name],
        },
      }
    )
  }

  const getWidget = (chart) => {
    const filterQuery = chart.filtered && filter ? filter : {}
    const currentChart = <Chart chartId={chart.id} filter={filterQuery} />

    return <div key={chart.name}>{WithDragHandle(currentChart)}</div>
  }

  return (
    <div className={classes.root}>
      <Header title="Analytics">
        <FilterByRegion onChange={handleChangeFilter} value={filter} />
      </Header>
      <GridLayout
        className={classes.reactGrid}
        layout={layout}
        cols={4}
        rowHeight={theme.spacing(10)}
        width={theme.breakpoints.values.lg}
        onLayoutChange={changeLayout}
      >
        {CHARTS.map(getWidget)}
        <div key="regionsTable">
          {WithDragHandle(
            <TableChart
              data={regionRows}
              getChildRows={getRegionChildren}
              defaultExpandedRowIds={defaultExpandedRegionIndexes}
            />
          )}
        </div>

        <div key="columnsTable">
          {WithDragHandle(
            <TableChart data={genreRows} getChildRows={getGenreChildren} />
          )}
        </div>
      </GridLayout>
    </div>
  )
}

export default Dashboard
