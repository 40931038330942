import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useSnackbar } from 'hooks/useSnackbar'
import { useRegions } from 'hooks/regions/useRegions'

import { deleteRegionsByIds } from 'utils/regions'

const RegionConfirmDeleteDialog = ({ ids, open, onClose, onDelete }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateRegions } = useRegions()

  const handleDelete = async () => {
    try {
      await deleteRegionsByIds(ids)
      mutateRegions()
      popSnackbar('success', 'Region deleted successfuly')
      onDelete ? onDelete() : onClose()
    } catch (error) {
      popSnackbar('error', 'Could not deleted region')
    }
    onClose()
  }

  return (
    <ConfirmDialog
      title="Delete regions"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these regions?
      <br />
      All playlist, clips, and other regions referencing these regions will be
      affected.
    </ConfirmDialog>
  )
}

export default RegionConfirmDeleteDialog
