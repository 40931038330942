import React, { useEffect, useState } from 'react'

import {
  IconButton,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { Rating as MuiRating } from '@material-ui/lab'

import { MdGrade } from 'react-icons/md'

import { useDemo } from 'hooks/demo/useDemo'
import { useAuth } from 'hooks/useAuthContext'
import { useSnackbar } from 'hooks/useSnackbar'
import { useRatings, useRatingTypeOptions } from 'hooks/ratings/useRatings'

import { setRating } from 'utils/ratings'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backdropFilter: 'blur(30px)',
    backgroundColor: theme.palette.controls.background,
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  ratingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  ratingRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const Rating = () => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const { currentClip } = useDemo()
  const { currentUser } = useAuth()

  const users = [currentUser?._id]
  const clips = [currentClip?._id]

  const [anchorEl, setAnchorEl] = useState(null)
  const [tempRatings, setTempRatings] = useState({})

  const { ratings, mutate } = useRatings({ users, clips })
  const { ratingTypeOptions } = useRatingTypeOptions()

  useEffect(() => {
    if (!ratings) return

    setTempRatings(
      ratings.reduce((acc, curr) => ({ ...acc, [curr.type]: curr.value }), {})
    )
  }, [ratings])

  const handleOnClickButton = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleOnChangeRating = (ratingType) => async (e, value) => {
    setTempRatings((prev) => ({ ...prev, [ratingType]: value }))

    try {
      await setRating({
        type: ratingType,
        value,
        clip: currentClip?._id,
        user: currentUser?._id,
      })
      popSnackbar('success', 'Rating added successfully')
    } catch (error) {
      popSnackbar('error', 'Could not rate clip. please try again later')
    }

    mutate()
  }

  return (
    <>
      <Tooltip title="Rate">
        <IconButton onClick={handleOnClickButton}>
          <MdGrade />
        </IconButton>
      </Tooltip>
      <Popover
        classes={{ paper: classes.root }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography variant="h2">Rate</Typography>
        <div className={classes.ratingsWrapper}>
          {Object.values(ratingTypeOptions || {}).map((ratingType) => (
            <div key={ratingType} className={classes.ratingRow}>
              <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                {ratingType}
              </Typography>
              <MuiRating
                name={ratingType}
                value={tempRatings[ratingType] || 0}
                onChange={handleOnChangeRating(ratingType)}
                size="large"
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default Rating
