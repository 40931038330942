import React from 'react'

import { Chip } from '@material-ui/core'

import { playlistStatusOptions } from 'consts/playlists'

const StatusChip = ({ status, ...props }) => {
  const option = playlistStatusOptions.find((o) => o.value === status)

  if (!option) return null

  const { label, color } = option

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      variant="outlined"
      {...props}
    />
  )
}

export default StatusChip
