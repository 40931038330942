import React, { useState } from 'react'

import moment from 'moment'
import { CircularProgress, makeStyles, Paper } from '@material-ui/core'
import { CustomTreeData, TreeDataState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui'

const formatDuration = (ms) => {
  const dur = moment.duration(ms, 'milliseconds')
  const hours = Math.floor(dur.asHours())
  const minutes = Math.floor(dur.asMinutes()) - hours * 60

  const formattedHours = hours ? `${hours}h` : ''
  const formattedMinutes = minutes ? `${minutes}m` : ''

  return hours || minutes ? `${formattedHours} ${formattedMinutes}` : '-'
}

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const TableChart = ({ data, getChildRows, defaultExpandedRowIds }) => {
  const classes = useStyles()
  const [columns] = useState([
    { name: 'name', title: 'Name' },
    { name: 'clipCount', title: 'Clips count' },
    {
      name: 'clipsTotalDuration',
      title: 'Clips total duration',
      getCellValue({ clipsTotalDuration }) {
        return formatDuration(clipsTotalDuration)
      },
    },
    { name: 'playlistCount', title: 'Playlists count' },
    {
      name: 'playlistsTotalDuration',
      title: 'Playlists total duration',
      getCellValue({ playlistsTotalDuration }) {
        return formatDuration(playlistsTotalDuration)
      },
    },
  ])
  const [tableColumnExtensions] = useState([{ columnName: 'name', width: 300 }])

  if (!data)
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )

  return (
    <Paper classes={{ root: classes.container }}>
      <Grid rows={data} columns={columns}>
        <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
        <CustomTreeData getChildRows={getChildRows} />
        <Table columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
        <TableTreeColumn for="name" />
      </Grid>
    </Paper>
  )
}

export default TableChart
