import yup from './yup'

const registerSchema = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  username: yup.string('Enter your username').required('Username is required'),
  password: yup.string('Enter your password').required('Password is required'),
  confirmPassword: yup
    .string('Enter your name')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
  email: yup
    .string('Enter you email address')
    .email('Invalid email address')
    .required('Email is required'),
  birthDate: yup
    .date('Enter your birth date')
    .required('Birth date is required'),
  phoneNumber: yup
    .string('Enter your phone number')
    .phoneNumber('Invalid phone number'),
})

export default registerSchema
