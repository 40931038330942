import useSWR from 'swr'
import { getYoutubeIdFromUrl } from 'utils/clips'

export const DEFAULT_POPULATE = ['owners', 'artists', 'authors', 'composers']
const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100

export const useClips = ({
  name,
  owner,
  genre,
  artist,
  region,
  playlist,
  metadataFields,
  populateFields = DEFAULT_POPULATE,
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
} = {}) => {
  const queries = [`skip=${skip}`, `limit=${limit}`]

  if (name) queries.push(`name=${name}`)
  if (genre) queries.push(`genre=${genre}`)
  if (owner) queries.push(`owner=${owner}`)
  if (artist) queries.push(`artist=${artist}`)
  if (region) queries.push(`region=${region}`)
  if (playlist) queries.push(`playlist=${playlist}`)

  const metadata = metadataFields?.join(',')
  if (metadata?.length) queries.push(`metadata=${metadata}`)

  const populate = populateFields?.join(',')
  if (populate?.length) queries.push(`populate=${populate}`)

  const key = '/api/clips?' + queries.join('&')
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    clips: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useClipsById = (
  id,
  { populateFields = DEFAULT_POPULATE } = {}
) => {
  const queries = []

  const populate = populateFields?.join(',')
  if (populate?.length) queries.push(`populate=${populate}`)

  const key = `/api/clips/${id}?` + queries.join('&')
  const { data, isValidating, ...rest } = useSWR(id && key)

  return {
    clip: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useClipYoutubeData = (youtubeUrl) => {
  const youtubeId = getYoutubeIdFromUrl(youtubeUrl)
  const { data, isValidating, ...rest } = useSWR(
    youtubeId && `/api/youtube/${youtubeId}`,
    { revalidateOnFocus: false }
  )

  return {
    youtubeData: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}
