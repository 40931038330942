import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useDebouncedCallback } from 'use-debounce'
import {
  Slider,
  FormLabel,
  FormControl,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(4),
  },
}))

const RangeSlider = ({
  label,
  required,
  debounce = 100,
  helperText,
  min = 0,
  max = 100,
  ...props
}) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(props)
  const [value, setValue] = useState(meta.value ?? [min, max])

  const debouncedSetFieldValue = useDebouncedCallback((value) => {
    helpers.setValue(value).then(() => helpers.setTouched(true))
  }, debounce)

  const error = meta.touched && meta.error

  useEffect(() => {
    setValue(meta.value ?? [min, max])
  }, [meta.value, min, max])

  const handleOnChange = (_, value) => {
    setValue(value)
    debouncedSetFieldValue(value)
  }

  return (
    <FormControl required={required} margin="normal" error={!!error}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Slider
        size="small"
        valueLabelDisplay="on"
        min={min}
        max={max}
        {...field}
        {...props}
        value={meta.value ?? [min, max]}
        onChange={(_, value) =>
          helpers.setValue(value).then(() => helpers.setTouched(true))
        }
        {...(debounce && { onChange: handleOnChange, value: value })}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default RangeSlider
