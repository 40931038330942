import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

import { useAuth } from 'hooks/useAuthContext'

const useStyles = makeStyles({
  backdrop: {
    backgroundColor: 'transparent',
  },
})

const PublicRoute = ({ children, ...rest }) => {
  const classes = useStyles()
  const { isAuth, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress />
      </Backdrop>
    )
  }

  if (isAuth) return <Redirect to="/" />

  return (
    <Route
      {...rest}
      render={() => {
        return children
      }}
    />
  )
}

export default PublicRoute
