import React from 'react'
import { useFormikContext } from 'formik'

import {
  Card,
  Button,
  makeStyles,
  CardContent,
  CardActions,
  ClickAwayListener,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  popoverCard: {
    minWidth: 240,
  },
  popoverCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  popoverCardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const PopupCard = ({ onSubmit, onCancel, children }) => {
  const classes = useStyles()
  const { isValid } = useFormikContext()

  return (
    <ClickAwayListener onClickAway={onCancel}>
      <Card className={classes.popoverCard}>
        <CardContent className={classes.popoverCardContent}>
          {children}
        </CardContent>
        <CardActions className={classes.popoverCardActions}>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            disabled={!isValid}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </ClickAwayListener>
  )
}

export default PopupCard
