import React from 'react'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles, Box, CircularProgress } from '@material-ui/core'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Track from 'components/clip/Track'
import Owners from 'components/clip/Owners'
import FormCard from 'components/FormCard'
import Authors from 'components/clip/Authors'
import Artists from 'components/clip/Artists'
import Composers from 'components/clip/Composers'
import ClipPlaylists from 'components/clip/ClipPlaylists'

import { useSnackbar } from 'hooks/useSnackbar'
import { useClipsById } from 'hooks/clips/useClips'
import { addClip, updateClip } from 'utils/clips'

import clipValidationSchema from 'schemas/clip'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    '& form': {
      maxWidth: theme.breakpoints.values.lg,
      flex: '1',
      '& > :not(:last-child)': {
        flex: '1',
        marginBottom: theme.spacing(3),
      },
    },
  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const initialClip = {
  name: '',
  originalName: '',
  duration: 0,
  regions: [],
  genres: [],
  artists: [],
  owners: [],
  authors: [],
  composers: [],
  contentTypes: ['clip'],
  youtubeUrl: '',
}

const Clip = () => {
  const history = useHistory()
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const { id: clipId } = useParams()
  const isNew = clipId === 'new'
  const {
    clip,
    isLoading: isLoadingClip,
    mutate: mutateClipById,
  } = useClipsById(!isNew && clipId)
  const isLoading = !isNew && isLoadingClip && !clip

  const handleSubmit = async (clipData) => {
    let action
    let messages
    try {
      if (isNew) {
        action = addClip
        messages = {
          success: 'Successfully added clip',
          error: 'Failed to save clip',
        }
      } else {
        action = updateClip
        messages = {
          success: 'Successfully updated clip',
          error: 'Failed to update clip',
        }
      }
      const { _id } = await action(clipData)
      mutateClipById()
      popSnackbar('success', messages.success)
      history.replace(`/clips/${_id}`)
    } catch (error) {
      popSnackbar('error', messages.error)
    }
  }

  const handleCancel = () => {
    history.replace('/clips')
  }

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={clip || initialClip}
          isInitialValid={false}
          enableReinitialize
          validationSchema={clipValidationSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <Header
              title={isNew ? 'Add clip' : 'Clip'}
              description={clip?.name || ''}
            />
            <FormCard>
              <Track />
              <Artists />
              <Owners />
              <Authors />
              <Composers />
            </FormCard>
            <ClipPlaylists />
            <Footer onCancel={handleCancel} />
          </Form>
        </Formik>
      )}
    </div>
  )
}

export default Clip
