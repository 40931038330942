import React, { useEffect, useMemo, useState } from 'react'

import _reduce from 'lodash/reduce'
import classnames from 'classnames'

import { Check as CheckIcon } from '@material-ui/icons'
import { Button, ListItemIcon, makeStyles } from '@material-ui/core'

import Menu from './controls/Menu'
import MenuItem from './controls/MenuItem'

import { useDemo } from 'hooks/demo/useDemo'
import { useGenres } from 'hooks/genres/useGenres'
import { useEventStream } from 'hooks/useEventStream'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: theme.spacing(3),
  },
  regionsButton: {
    backgroundColor: theme.palette.controls.background,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
  },
  regionsButtonSelected: {
    backgroundColor: theme.palette.controls.selected,
  },
}))

const GENRES_OPTIONS = [
  { name: 'Electronic', label: 'Electronic' },
  { name: 'Hip Hop', label: 'Hip-Hop / Rap' },
  { name: 'Pop', label: 'Pop' },
  { name: 'R&B', label: 'R&B' },
  { name: 'Folk', label: 'Folk / Country' },
  { name: 'Jazz', label: 'Jazz' },
  { name: 'Rock', label: 'Rock' },
  { name: 'World Music', label: 'World Music' },
]

const GenresBar = () => {
  const classes = useStyles()
  const { addClickEvent } = useEventStream()

  const { genresIds, setGenresIds, setFreezeOverlay } = useDemo()
  const { genres } = useGenres({ mapById: true })
  const [anchorEl, setAnchorEl] = useState(null)

  const genresOptions = useMemo(
    () =>
      _reduce(
        genres,
        (acc, genre) => {
          const genreOption = GENRES_OPTIONS.find(
            (option) => option.name === genre.name
          )
          return genreOption
            ? [...acc, { ...genre, label: genreOption.label }]
            : acc
        },
        []
      ),
    [genres]
  )

  const areAllGenresSelected = genresIds.length === genresOptions.length

  useEffect(() => {
    setFreezeOverlay(!!anchorEl)
  }, [anchorEl, setFreezeOverlay])

  const handleOnClickGenresButton = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleOnCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOnClickGenre = (genreId) => {
    setGenresIds((prev) =>
      prev.includes(genreId)
        ? prev.filter((id) => id !== genreId)
        : [...prev, genreId]
    )
  }

  const handleOnClickAllButton = () =>
    setGenresIds(
      areAllGenresSelected ? [] : genresOptions.map(({ _id }) => _id)
    )

  return (
    <div className={classes.root}>
      <Button
        className={classnames(
          classes.regionsButton,
          genresIds.length && classes.regionsButtonSelected
        )}
        variant="contained"
        onClick={handleOnClickGenresButton}
        classes={{ root: classes.genresButton }}
      >
        Genres
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleOnCloseMenu}>
        <MenuItem
          id={`genresMenuItem_selectAll_${areAllGenresSelected ? 'off' : 'on'}`}
          onClickCapture={addClickEvent}
          onClick={handleOnClickAllButton}
        >
          {areAllGenresSelected ? 'Clear All' : 'Select All'}
        </MenuItem>

        {genresOptions.map((genreOption) => {
          const isSelected = genresIds.includes(genreOption._id)

          return (
            <MenuItem
              id={`genresMenuItem_${genreOption._id}_${genreOption.label}_${
                isSelected ? 'off' : 'on'
              }`}
              key={genreOption._id}
              selected={isSelected}
              onClickCapture={addClickEvent}
              onClick={() => handleOnClickGenre(genreOption._id)}
            >
              {isSelected && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              {genreOption.label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default GenresBar
