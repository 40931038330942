import React from 'react'
import { useField } from 'formik'
import update from 'immutability-helper'

import { makeStyles, Button, Link } from '@material-ui/core'

import Select from 'components/Select'
import TextInput from 'components/TextInput'

const useStyles = makeStyles((theme) => ({
  addButton: {
    alignSelf: 'flex-end',
  },
  linkListContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  linkCard: {
    padding: theme.spacing(2),
    border: '1px solid',
    borderRadius: '3px',
    borderColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  linkActionButton: {
    marginTop: theme.spacing(5.1),
    height: theme.spacing(5),
  },
  linkTypeContainer: {
    display: 'flex',
    width: '250px',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  urlContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
}))

const createNewLink = () => ({
  platform: '',
  url: '',
})

const LINK_TYPES = ['Facebook', 'Instagram', 'Twitter', 'Website', 'Other']

const Links = () => {
  const classes = useStyles()
  const [, meta, helpers] = useField('links')

  const handleOnAddLink = () => {
    const updatedLinks = update(meta.value, { $push: [createNewLink()] })

    helpers.setValue(updatedLinks)
  }

  const handleOnRemoveLink = (index) => () => {
    const updatedLinks = update(meta.value, { $splice: [[index, 1]] })

    helpers.setValue(updatedLinks)
  }

  return (
    <>
      <Button
        className={classes.addButton}
        color="primary"
        variant="outlined"
        onClick={handleOnAddLink}
        disabled={meta.error}
      >
        Add link
      </Button>
      <div className={classes.linkListContainer}>
        {meta.value.map((link, index) => {
          return (
            <div className={classes.linkCard} key={index}>
              <div className={classes.linkTypeContainer}>
                <Select
                  label="Platform"
                  name={`links[${index}].platform`}
                  options={LINK_TYPES}
                  fullwidth
                  required
                />
              </div>
              <div className={classes.urlContainer}>
                <TextInput label="Url" name={`links[${index}].url`} required />
              </div>
              <Button
                className={classes.linkActionButton}
                as={Link}
                href={link.url}
                disabled={meta.error}
                variant="outlined"
              >
                Visit
              </Button>
              <Button
                className={classes.linkActionButton}
                onClick={handleOnRemoveLink(index)}
                variant="outlined"
              >
                Delete
              </Button>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Links
