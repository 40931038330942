import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useFormikContext } from 'formik'

import {
  makeStyles,
  Box,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core'

import Select from 'components/Select'
import TextInput from 'components/TextInput'
import YoutubeClip from 'components/YoutubeClip'
import SpotifyTrack from 'components/SpotifyTrack'

import { useGenres } from 'hooks/genres/useGenres'
import { useRegions } from 'hooks/regions/useRegions'
import { useClipYoutubeData } from 'hooks/clips/useClips'

const contentTypeOptions = ['clip', 'demo']

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  subContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  formControlRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const Track = () => {
  const classes = useStyles()
  const { values, setValues } = useFormikContext()
  const { youtubeData } = useClipYoutubeData(values.youtubeUrl)

  const { genres } = useGenres({ mapById: true })
  const genresOptions = useMemo(
    () => (genres ? Object.keys(genres) : []),
    [genres]
  )

  const { regions } = useRegions({ mapById: true })
  const regionOptions = useMemo(
    () => (regions ? Object.keys(regions) : []),
    [regions]
  )

  const durationString = useMemo(
    () => moment.utc(values.duration).format('mm:ss'),
    [values.duration]
  )

  const viewsDateString = useMemo(
    () =>
      values.youtubeViews?.[0]?.date &&
      moment(values.youtubeViews?.[0]?.date).format('DD/MM/YYYY'),
    [values.youtubeViews]
  )

  useEffect(() => {
    if (!youtubeData) return

    const updatedValues = {
      thumbnailUrl: (
        youtubeData.thumbnails.maxres || youtubeData.thumbnails.high
      )?.url,
      duration: youtubeData.duration,
      ...(!values.youtubeViews?.length && {
        youtubeViews: [{ date: new Date(), views: youtubeData.viewCount }],
      }),
    }

    setValues((prev) => ({ ...prev, ...updatedValues }))
  }, [youtubeData, setValues, values.youtubeViews?.length])

  return (
    <Box className={classes.container}>
      <Box className={classes.subContainer}>
        <TextInput
          name="name"
          label="Track name"
          placeholder="Enter track name in english"
          required
        />
        <TextInput
          name="originalName"
          label="Original Track name (if not english)"
          placeholder="Enter track original track name"
        />
        <Select
          name="genres"
          label="Genres"
          placeholder="Select one genre or more"
          multiple
          options={genresOptions}
          getOptionLabel={(genreId) => (genres && genres[genreId]?.name) || ''}
          required
        />
        <Select
          name="regions"
          label="Regions"
          placeholder="Select one region or more"
          getOptionLabel={(regionId) =>
            (regions && regions[regionId]?.name) || ''
          }
          options={regionOptions}
          multiple
          required
        />
        <Select
          name="contentTypes"
          label="Content Type"
          placeholder="Select one content type or more"
          multiple
          options={contentTypeOptions}
          required
        />
        <TextInput
          name="lyrics"
          label="Lyrics"
          multiline
          minRows={5}
          maxRows={15}
          placeholder="Paste clip lyrics here"
        />
      </Box>
      <Box className={classes.subContainer}>
        <TextInput
          name="youtubeUrl"
          label="Youtube clip URL"
          placeholder="Enter youtube clip URL"
          debounce={1000}
          required
        />
        <YoutubeClip url={values.youtubeUrl} />
        <div className={classes.formControlRow}>
          <FormControl margin="normal" fullWidth>
            <FormLabel>Duration</FormLabel>
            <TextField
              name="duration"
              size="small"
              margin="dense"
              variant="outlined"
              disabled
              value={durationString}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <FormLabel>{`Views (${viewsDateString})`}</FormLabel>
            <TextField
              name="duration"
              size="small"
              margin="dense"
              variant="outlined"
              fullWidth
              disabled
              value={values.youtubeViews?.[0]?.views.toLocaleString()}
            />
          </FormControl>
        </div>
        <TextInput
          name="spotifyUrl"
          label="Spotify track URL"
          placeholder="Enter spotify clip URL"
        />
        {values.spotifyUrl && <SpotifyTrack url={values.spotifyUrl} />}
      </Box>
    </Box>
  )
}

export default Track
