import yup from './yup'

const recipeValidationSchema = yup.object({
  name: yup.string('Enter recipe name').required('Playlist name is required'),
  description: yup
    .string('Enter recipe description')
    .required('Playlist description is required'),
  regions: yup.array(),
  genres: yup.array(),
  owners: yup.array(),
  ratings: yup.array().of(yup.number()).length(2).required(),
  interval: yup.number().positive().integer().required(),
  maxDuration: yup.number().positive().integer().nullable(),
})

export default recipeValidationSchema
