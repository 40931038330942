import { useMemo } from 'react'

import { sortBy } from 'lodash'

import { useRegions } from 'hooks/regions/useRegions'

const DEFAULT_SORT = 'clipCount'
const DEFAULT_EXPANDED_ROWS = []

/*A function to separate the tree nodes when one has more than one parent, in return each node will have maximum one parent but there will be multiple occurrences of the same region (for each parent).*/
const separateParentRegions = (nodes) => {
  return nodes.reduce((acc, node) => {
    if (node.parentRegions.length) {
      return [
        ...acc,
        ...node.parentRegions.map((relatedGenreId) => ({
          ...node,
          parentRegions: [relatedGenreId],
        })),
      ]
    }

    return [...acc, node]
  }, [])
}

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((rootRow) => {
    if (row === null) return !rootRow.parentRegions.length

    return rootRow.parentRegions.includes(row._id)
  })

  return childRows.length ? childRows : null
}

const useRegionsMetadataRows = ({
  metadataFields,
  defaultExpandedRowNames = DEFAULT_EXPANDED_ROWS,
  sortByField = DEFAULT_SORT,
}) => {
  const { regions, ...rest } = useRegions({
    metadataFields,
  })

  const rows = useMemo(() => {
    if (!regions) return null

    const allNodes = separateParentRegions(regions)

    return sortBy(allNodes, sortByField).reverse()
  }, [regions, sortByField])

  const defaultExpandedRegionIndexes = useMemo(() => {
    if (!defaultExpandedRowNames) return rows

    return defaultExpandedRowNames.reduce((indexes, rowName) => {
      const index = rows?.findIndex((row) => row.name === rowName)

      if (index >= 0) return [...indexes, index]

      return indexes
    }, [])
  }, [defaultExpandedRowNames, rows])

  return { rows, defaultExpandedRegionIndexes, ...rest }
}

export default useRegionsMetadataRows
