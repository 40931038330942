import React from 'react'
import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import { usePermissions } from 'hooks/permissions/usePermissions'
import usePermissionsColumns from 'hooks/permissions/usePermissionsColumns'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultGrouping = ['role', 'resource']
const defaultHiddenColumnNames = ['_id', 'index']
const columnNames = [
  ...defaultHiddenColumnNames,
  'edit',
  'role',
  'resource',
  'action',
  'possession',
]

const PermissionsTable = ({ selection, onSelectionChange }) => {
  const classes = useStyles()
  const { permissions, isLoading } = usePermissions()

  const columns = usePermissionsColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={permissions || []}
        columns={columns}
        isLoading={isLoading}
        selection={selection}
        onSelectionChange={onSelectionChange}
        hidePagination
        defaultGrouping={defaultGrouping}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default PermissionsTable
