import React, { useState } from 'react'

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Form, FormikProvider, useFormik } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'

import TextInput from 'components/TextInput'

import resetPasswordSchema from 'schemas/resertPassword'

import useQuery from 'hooks/useQuery'
import { useSnackbar } from 'hooks/useSnackbar'

import { resetPassword } from 'utils/users'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fieldsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: 0,
      width: '100%',
    },
  },
  registerButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    textDecoration: 'none',
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
}))

const initialValues = {
  password: '',
  confirmPassword: '',
}

const SuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Password Changed!</DialogTitle>
      <DialogContent>
        <Typography>Your password has been changed successfully!</Typography>
        <Typography>You may now log in to your account!</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

const ResetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const { token, id } = useQuery()
  const { popSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenSuccessDialog = () => {
    setIsOpen(true)
  }

  const handleCloseSuccessDialog = () => {
    setIsOpen(false)
    history.replace('/login')
  }

  const onSubmit = async (values) => {
    try {
      await resetPassword(token, id, values.password)

      handleOpenSuccessDialog()
    } catch (error) {
      if (error.response.status === 401) {
        popSnackbar(
          'error',
          'The token is invalid or expired, please request a password reset again.'
        )
      } else {
        popSnackbar('error', 'Could not reset password')
      }
    }
  }

  const formikConfig = {
    isInitialValid: false,
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit,
  }
  const formik = useFormik(formikConfig)

  if (!token || !id) return <Redirect to="/" />

  return (
    <>
      <Container
        className={classes.formContainer}
        component="main"
        maxWidth="xs"
      >
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Typography variant="h2" fontWeight="bold">
              Change Your Password
            </Typography>
            <div className={classes.fieldsContainer}>
              <TextInput
                name="password"
                label="Your New Password"
                type="password"
                required
                fullWidth
              />
              <TextInput
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
                fullWidth
              />
            </div>
            <Button
              disabled={!formik.isValid || formik.isSubmitting}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Change Password
            </Button>
          </Form>
        </FormikProvider>
      </Container>
      <SuccessDialog open={isOpen} onClose={handleCloseSuccessDialog} />
    </>
  )
}

export default ResetPassword
