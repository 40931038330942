import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useSnackbar } from 'hooks/useSnackbar'
import { usePermissions } from 'hooks/permissions/usePermissions'

import { deletePermissionsByIds } from 'utils/permissions'

const PermissionConfirmDeleteDialog = ({ ids, open, onClose, onDelete }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutatePermissions } = usePermissions()

  const handleDelete = async () => {
    try {
      await deletePermissionsByIds(ids)
      mutatePermissions()
      popSnackbar('success', 'Permission deleted successfuly')
      onDelete ? onDelete() : onClose()
    } catch (error) {
      popSnackbar('error', 'Could not deleted permission')
    }
    onClose()
  }

  return (
    <ConfirmDialog
      title="Delete permissions"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these permissions?
      <br />
      All users of this role will be denied of these permissions.
    </ConfirmDialog>
  )
}

export default PermissionConfirmDeleteDialog
