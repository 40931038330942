import React from 'react'
import { Formik } from 'formik'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress,
  Dialog,
} from '@material-ui/core'

import TextInput from 'components/TextInput'
import SelectRegions from './SelectRegions'

import { useSnackbar } from 'hooks/useSnackbar'
import { useRegionById, useRegions } from 'hooks/regions/useRegions'

import { addRegion, initialRegion, updateRegion } from 'utils/regions'

import regionSchema from 'schemas/regions'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const RegionDialog = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const isNew = !id
  const { mutate: mutateRegions } = useRegions()
  const {
    region,
    isLoading,
    mutate: mutateRegionById,
  } = useRegionById(!isNew && id)

  const handleSubmit = async (regionData) => {
    let action
    let messages
    try {
      if (isNew) {
        action = addRegion
        messages = {
          success: 'Successfully added region',
          error: 'Failed to save region',
        }
      } else {
        action = updateRegion
        messages = {
          success: 'Successfully updated region',
          error: 'Failed to update region',
        }
      }
      await action(regionData)
      mutateRegions()
      mutateRegionById()
      popSnackbar('success', messages.success)
    } catch (error) {
      popSnackbar('error', messages.error)
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${isNew ? 'Add' : 'Edit'} Region`}</DialogTitle>
      {!isNew && isLoading ? (
        <DialogContent className={classes.loading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          validationSchema={regionSchema}
          initialValues={isNew ? initialRegion : region}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Enter region name"
                  required
                />
                <SelectRegions
                  multiple
                  required
                  name="parentRegions"
                  label="Parent Regions"
                  placeholder="Select parent regions"
                  excludeRegionIds={[region?._id]}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default RegionDialog
