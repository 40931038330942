import { TextField } from '@material-ui/core'
import React, { forwardRef } from 'react'

const MuiTextFieldForPhoneInput = (props, ref) => {
  const { onChange, ...rest } = props

  const handleOnChange = ({ target }) => {
    const newValue = target.value
    onChange(newValue)
  }

  return (
    <TextField
      inputRef={ref}
      size="small"
      variant="outlined"
      margin="dense"
      onChange={handleOnChange}
      {...rest}
    />
  )
}

export default forwardRef(MuiTextFieldForPhoneInput)
