import React, { useState } from 'react'

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Form, FormikProvider, useFormik } from 'formik'

import TextInput from 'components/TextInput'

import { useSnackbar } from 'hooks/useSnackbar'
import { requestResetPassword } from 'utils/users'

import requestResetPasswordSchema from 'schemas/requestResetPassword'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fieldsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: 0,
      width: '100%',
    },
  },
  registerButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    textDecoration: 'none',
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
  title: {
    marginBottom: 10,
  },
}))

const initialValues = {
  email: '',
}

const EmailSentDialog = ({ open, onClose, email }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Instructions Sent!</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            If there is a trippiclips account registered to
          </Typography>
          <Typography variant="h3">{email}</Typography>
        </Box>

        <Typography>
          we have sent instructions for how to reset your password.
        </Typography>
        <Typography>
          Please check both your inbox and your spam folder.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}

const ForgotPassword = () => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenDialog = () => {
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const onSubmit = async (values) => {
    try {
      await requestResetPassword(values.email)

      handleOpenDialog(values.email)
    } catch (error) {
      popSnackbar('error', 'Could not request to reset password')
    }
  }

  const formikConfig = {
    isInitialValid: false,
    initialValues,
    validationSchema: requestResetPasswordSchema,
    onSubmit,
  }

  const formik = useFormik(formikConfig)

  return (
    <FormikProvider value={formik}>
      <Container
        className={classes.formContainer}
        component="main"
        maxWidth="xs"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Typography className={classes.title} variant="h2" fontWeight="bold">
            Forgot Password
          </Typography>
          <Typography>
            If you have forgotten your password you can enter your e-mail and we
            will send out instructions on how to reset it.
          </Typography>
          <div className={classes.fieldsContainer}>
            <TextInput name="email" label="Email" required fullWidth />
          </div>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Request Password Reset
          </Button>
        </Form>
      </Container>
      <EmailSentDialog
        email={formik.values.email}
        open={isOpen}
        onClose={handleCloseDialog}
      />
    </FormikProvider>
  )
}

export default ForgotPassword
