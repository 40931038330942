import React, { useContext, createContext } from 'react'

import { usePlaylistById, usePlaylistClips } from 'hooks/playlists/usePlaylist'

const PlaylistContext = createContext()

const CLIP_POPULATIONS = ['artists']

const PlaylistProvider = ({ id, children }) => {
  const {
    playlist,
    isLoading: isLoadingPlaylist,
    mutate: mutatePlaylist,
  } = usePlaylistById(id)

  const {
    clips,
    loadClips,
    totalCount,
    isLoading: isLoadingClips,
  } = usePlaylistClips({
    id,
    populateFields: CLIP_POPULATIONS,
  })

  const value = {
    playlistId: id,
    playlist,
    isLoadingPlaylist,
    mutatePlaylist,
    clips,
    loadClips,
    totalCount,
    isLoadingClips,
  }

  return (
    <PlaylistContext.Provider value={value}>
      {children}
    </PlaylistContext.Provider>
  )
}

const PlaylistConsumer = ({ children }) => {
  return (
    <PlaylistContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'PlaylistConsumer must be used within a PlaylistProvider'
          )
        }
        return children(context)
      }}
    </PlaylistContext.Consumer>
  )
}

const usePlaylistContext = () => {
  const context = useContext(PlaylistContext)
  if (context === undefined) {
    throw new Error('usePlaylistContext must be used within a PlaylistProvider')
  }
  return context
}

export { PlaylistProvider, PlaylistConsumer, usePlaylistContext }
