import yup from './yup'

const requestResetPasswordSchema = yup.object({
  email: yup
    .string('Enter your account\'s email')
    .email('Invalid email address')
    .required('Email is required'),
})

export default requestResetPasswordSchema
