import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'

import PopupCard from '../PopupCard'
import Select from 'components/Select'

import yup from 'schemas/yup'
import { useGenres } from 'hooks/genres/useGenres'

const validationSchema = yup.object({
  genre: yup.string().min(1).required(),
})

export const GenreInput = ({ initialValues, onChange, onCancel }) => {
  const paramName = 'genre'
  const { genres } = useGenres({ mapById: true })
  const genresOptions = useMemo(
    () => (genres ? Object.keys(genres) : []),
    [genres]
  )

  const handleOnSubmit = (values) => {
    onChange(paramName, values[paramName])
  }

  return (
    <Formik
      validateOnMount
      initialValues={{ [paramName]: '', ...initialValues }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <PopupCard onCancel={onCancel}>
          <Select
            name={paramName}
            label="Genre"
            fullWidth
            size="small"
            disableClearable
            placeholder="Select a genre"
            options={genresOptions}
            getOptionLabel={(genreId) =>
              (genres && genres[genreId]?.name) || ''
            }
          />
        </PopupCard>
      </Form>
    </Formik>
  )
}

export const GenreLabel = ({ value }) => {
  const { genres } = useGenres({ mapById: true })
  return genres[value]?.name || '-'
}
