import { useMemo } from 'react'

import { sortBy } from 'lodash'

import { useGenres } from 'hooks/genres/useGenres'

const DEFAULT_SORT = 'clipCount'
const DEFAULT_EXPANDED_ROWS = []

/*A function to separate the tree nodes when one has more than one related genre, in return each node will have maximum one related genres but there will be multiple occurrences of the same genre (the amount of the related genres).*/
const separateRelatedGenres = (nodes) => {
  return nodes.reduce((acc, node) => {
    if (node.relatedGenres.length) {
      return [
        ...acc,
        ...node.relatedGenres.map((relatedGenreId) => ({
          ...node,
          relatedGenres: [relatedGenreId],
        })),
      ]
    }

    return [...acc, node]
  }, [])
}

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((rootRow) => {
    if (row === null) return !rootRow.relatedGenres.length

    return rootRow.relatedGenres.includes(row._id)
  })

  return childRows.length ? childRows : null
}

const useGenresMetadataRows = ({
  metadataFields,
  defaultExpandedRowNames = DEFAULT_EXPANDED_ROWS,
  sortByField = DEFAULT_SORT,
}) => {
  const { genres, ...rest } = useGenres({
    metadataFields,
  })

  const rows = useMemo(() => {
    if (!genres) return null

    const allNodes = separateRelatedGenres(genres)

    return sortBy(allNodes, sortByField).reverse()
  }, [genres, sortByField])

  const defaultExpandedGenresIndexes = useMemo(() => {
    if (!defaultExpandedRowNames) return rows

    return defaultExpandedRowNames.reduce((indexes, rowName) => {
      const index = rows?.findIndex((row) => row.name === rowName)

      if (index >= 0) return [...indexes, index]

      return indexes
    }, [])
  }, [defaultExpandedRowNames, rows])

  return { rows, defaultExpandedGenresIndexes, ...rest }
}

export default useGenresMetadataRows
