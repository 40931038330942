import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import EditIcon from '@material-ui/icons/Edit'
import humanizeDuration from 'humanize-duration'
import { IconButton, Link as MuiLink, Tooltip } from '@material-ui/core'

import { useGenres } from 'hooks/genres/useGenres'
import { useRegions } from 'hooks/regions/useRegions'

const useRecipesColumns = (columnNames) => {
  const { genres: genresOptions } = useGenres({ mapById: true })
  const { regions: regionsOptions } = useRegions({ mapById: true })

  return useMemo(() => {
    let columns = [
      { name: '_id', title: 'ID' },
      {
        width: 70,
        getCellValue({ _id }) {
          return (
            <Tooltip title="Update recipe">
              <IconButton component={Link} size="small" to={`/recipes/${_id}`}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )
        },
      },
      {
        name: 'name',
        title: 'Recipe Name',
        getCellValue({ name, playlists }) {
          if (!playlists?.length) return name

          return (
            <Tooltip title="Navigate to playlist">
              <MuiLink
                component={Link}
                size="small"
                to={`/playlists/${playlists[0]?._id}`}
              >
                {name}
              </MuiLink>
            </Tooltip>
          )
        },
      },
      { name: 'description', title: 'Description' },
      {
        name: 'owners',
        title: 'Owners',
        getCellValue: ({ owners }) =>
          owners.map((editor) => editor?.name).join(', '),
      },
      {
        name: 'genres',
        title: 'Genres',
        getCellValue: ({ genres }) => {
          if (!genresOptions) return ''

          return genres
            .reduce((acc, genreId) => {
              const regionName = genresOptions?.[genreId]?.name
              if (!regionName) return acc
              return [...acc, regionName]
            }, [])
            .join(', ')
        },
      },
      {
        name: 'regions',
        title: 'Regions',
        getCellValue: ({ regions }) => {
          if (!regions || !regions) return ''

          const regionNames = regions.reduce((acc, regionId) => {
            const regionName = regionsOptions?.[regionId]?.name
            return regionName ? [...acc, regionName] : acc
          }, [])

          return regionNames.join(', ')
        },
      },
      {
        name: 'ratings',
        title: 'Ratings',
        getCellValue: ({ ratings }) => {
          return ratings.join(' - ')
        },
      },
      {
        name: 'interval',
        title: 'Interval',

        getCellValue: ({ interval }) => {
          return humanizeDuration(interval)
        },
      },
      {
        name: 'maxDuration',
        title: 'Max Duration',
        getCellValue: ({ maxDuration }) => {
          return maxDuration ? humanizeDuration(maxDuration) : 'no limit'
        },
      },
      {
        name: 'updatedAt',
        title: 'Last edited',
        getCellValue: ({ updatedAt }) =>
          updatedAt && moment.utc(updatedAt).format('DD/MM/YYYY'),
      },
      {
        name: 'createdAt',
        title: 'Created at',
        getCellValue: ({ createdAt }) =>
          createdAt && moment.utc(createdAt).format('DD/MM/YYYY'),
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, genresOptions, regionsOptions])
}

export default useRecipesColumns
