import { MenuItem, withStyles } from '@material-ui/core'

export default withStyles((theme) => ({
  root: {
    fontSize: 20,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    '&:focus': {
      backgroundColor: theme.palette.controls.focus,
    },
    '&:hover': {
      backgroundColor: theme.palette.controls.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.controls.selected,
      '&:hover': {
        backgroundColor: theme.palette.controls.hoverSelected,
      },
      boxShadow: theme.shadows[1],
    },
  },
}))(MenuItem)
