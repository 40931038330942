import { useMemo } from 'react'
import useSWR from 'swr'

export const usePermissions = ({ mapById = false } = {}) => {
  const { data, isValidating, error, ...rest } = useSWR('/api/permissions')

  const permissions = useMemo(() => {
    if (!mapById) return data
    const permissionsMappedById = data?.reduce((acc, curr) => {
      return { ...acc, [curr._id]: curr }
    }, {})

    return permissionsMappedById
  }, [data, mapById])

  return {
    permissions,
    isLoading: isValidating && !data,
    isError: !!error,
    ...rest,
  }
}

export const usePermissionById = (id) => {
  const { data, isValidating, ...rest } = useSWR(id && `/api/permissions/${id}`)

  return {
    permission: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}
