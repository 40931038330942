import React, { useState } from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { Nature as NatureIcon } from '@material-ui/icons'

import Header from 'components/Header'
import GenresTree from 'components/admin/genres/GenresTree'
import GenresTable from 'components/admin/genres/GenresTable'
import GenreDialog from 'components/admin/genres/GenreDialog'
import GenreConfirmDeleteDialog from 'components/admin/genres/GenreConfirmDeleteDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Genres = () => {
  const classes = useStyles()
  const [openTree, setOpenTree] = useState(false)
  const [openDialog, setOpenDialog] = useState('')
  const [selection, setSelection] = useState([])

  const handleOnDelete = () => {
    setOpenDialog('deleteGenres')
  }

  const handleOnAdd = () => {
    setOpenDialog('addGenre')
  }

  const handleOnClose = () => {
    setOpenDialog('')
  }

  return (
    <>
      <div className={classes.root}>
        <Header title="Genres">
          <Tooltip title="Genre relations tree">
            <IconButton onClick={() => setOpenTree(true)}>
              <NatureIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!selection.length}
            onClick={handleOnDelete}
          >
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={handleOnAdd}>
            Add new genre
          </Button>
          {openDialog === 'addGenre' && (
            <GenreDialog open onClose={handleOnClose} />
          )}
        </Header>
        <GenresTable selection={selection} onSelectionChange={setSelection} />
        <GenreConfirmDeleteDialog
          ids={selection}
          onClose={handleOnClose}
          onDelete={() => {
            setSelection([])
            handleOnClose()
          }}
          open={openDialog === 'deleteGenres'}
        />
      </div>
      <Dialog open={openTree} onClose={() => setOpenTree(false)}>
        <DialogContent>
          <GenresTree />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Genres
