import useSWR from 'swr'

const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100
const DEFAULT_POPULATE = ['user', 'playlist']

export const useArtists = ({
  name,
  populateFields = DEFAULT_POPULATE,
  metadataFields,
  sortFields,
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
} = {}) => {
  const queries = [`skip=${skip}`, `limit=${limit}`]

  if (name) queries.push(`name=${name}`)

  const populate = populateFields?.join(',')
  if (populate?.length) queries.push(`populate=${populate}`)

  const sort = sortFields?.join(',')
  if (sort?.length) queries.push(`sort=${sort}`)

  const metadata = metadataFields?.join(',')
  if (metadata?.length) queries.push(`metadata=${metadata}`)

  const key = `/api/artists?${queries.join('&')}`
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    artists: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useArtistsById = (
  id,
  { populateFields = DEFAULT_POPULATE } = {}
) => {
  const options = []

  const populate = populateFields?.join(',')
  if (populate?.length) options.push(`populate=${populate}`)

  const key = `/api/artists/${id}?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(id && key)

  return {
    artist: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}
