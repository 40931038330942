import React, { useState } from 'react'
import { Formik, Form } from 'formik'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Dialog,
} from '@material-ui/core'

import PlaylistForm from './PlaylistForm'

import { useSnackbar } from 'hooks/useSnackbar'
import { usePlaylistById } from 'hooks/playlists/usePlaylist'

import { updatePlaylist } from 'utils/playlists'
import playlistValidationSchema from 'schemas/playlist'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const PlaylistDialog = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { playlist, mutate: mutatePlaylistById } = usePlaylistById(id)

  const handleSubmit = async (playlistData) => {
    setIsSubmitting(true)

    try {
      await updatePlaylist(playlistData)
      mutatePlaylistById()
      popSnackbar('success', 'Successfully updated playlist')
    } catch (error) {
      popSnackbar('error', 'Failed to update playlist')
    }

    setIsSubmitting(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{'Edit details'}</DialogTitle>
      {playlist && (
        <Formik
          validationSchema={playlistValidationSchema}
          initialValues={playlist}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <PlaylistForm />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid || isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default PlaylistDialog
