import React, { useState } from 'react'
import humanizeDuration from 'humanize-duration'

import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { BsDot } from 'react-icons/bs'

import TypeChip from './TypeChip'
import StatusChip from './StatusChip'
import PlaylistDialog from './PlaylistDialog'

import { playlistTypeOptions } from 'consts/playlists'

import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: theme.spacing(24),
    '& > :not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&:hover': {
      '& $editOverlay': {
        opacity: 1,
      },
    },
  },
  editOverlay: {
    transition: '.2s opacity',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      color: 'white',
    },
    cursor: 'pointer',
  },
  thumbnailContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(24),
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  metadataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loader: {
    alignSelf: 'center',
    margin: theme.spacing(10),
  },
  title: {
    fontSize: 64,
  },
  topSubtitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 12,
  },
}))

const PlaylistInfo = () => {
  const classes = useStyles()
  const { playlist, isLoadingPlaylist } = usePlaylistContext()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  if (isLoadingPlaylist) {
    return <CircularProgress classes={{ root: classes.loader }} />
  }

  const clipsLabel = `${playlist.clips.length} clips`

  const editorsLabel =
    playlist.editors.map((editor) => editor.name).join(', ') || 'No editors'

  const totalDurationLabel = humanizeDuration(playlist.totalDuration)

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.thumbnailContainer}>
          <img src={playlist.thumbnail} alt={playlist.name} />
          {playlist.type === playlistTypeOptions.NORMAL && (
            <div
              className={classes.editOverlay}
              onClick={() => setIsDialogOpen(true)}
            >
              <Typography>Click to edit</Typography>
            </div>
          )}
        </div>
        <div className={classes.infoContainer}>
          <Typography className={classes.topSubtitle}>Playlist</Typography>
          <Typography className={classes.title} variant="h1">
            {playlist.name}
          </Typography>
          <Typography variant="body1">{playlist.description}</Typography>
          <div className={classes.metadataContainer}>
            <Typography variant="body2">{editorsLabel}</Typography>
            <BsDot />
            <Typography variant="body2">{clipsLabel}</Typography>
            <BsDot />
            <Typography variant="body2">{totalDurationLabel}</Typography>
            <BsDot />
            <TypeChip type={playlist.type} />
            <StatusChip status={playlist.status} />
          </div>
        </div>
      </Box>
      <PlaylistDialog
        id={playlist._id}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  )
}

export default PlaylistInfo
