import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'

import SelectArtists from 'components/SelectArtists'
import AddArtistDialog from 'components/artists/AddArtistDialog'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  addButton: {
    marginTop: theme.spacing(5),
    height: theme.spacing(5),
    whiteSpace: 'nowrap',
  },
}))

const Authors = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className={classes.container}>
        <SelectArtists
          name="authors"
          label="Authors"
          placeholder="Select one author or more"
          multiple
          fullWidth
          getOptionLabel={(author) => author.name}
        />
        <Button
          className={classes.addButton}
          color="primary"
          variant="outlined"
          onClick={() => setIsOpen(true)}
        >
          Add author
        </Button>
      </div>
      {isOpen && <AddArtistDialog open onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default Authors
