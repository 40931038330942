import yup from './yup'

import { playlistStatusOptions } from 'consts/playlists'

const playlistValidationSchema = yup.object({
  name: yup.string('Enter playlist name').required('Playlist name is required'),
  regions: yup
    .array()
    .min(1, 'You must select at least 1 region')
    .required('You must select at least 1 region'),
  genres: yup
    .array()
    .min(1, 'You must select at least 1 genre')
    .required('You must select at least 1 genre'),
  editors: yup
    .array()
    .of(
      yup
        .object({
          _id: yup.string().required(),
        })
        .nullable()
    )
    .required(),
  relatedArtists: yup.array().of(
    yup
      .object({
        _id: yup.string().required(),
      })
      .nullable()
  ),
  status: yup
    .string()
    .oneOf(playlistStatusOptions.map((option) => option.value))
    .required(),
  description: yup.string('Enter playlist description'),
  thumbnail: yup.string(''),
  clips: yup.array(),
})

export default playlistValidationSchema
