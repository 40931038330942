import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'

import Users from './Users'
import Genres from './Genres'
import Owner from './owners/Owner'
import Owners from './owners/Owners'
import Permissions from './Permissions'
import Header from 'components/admin/Header'

import { UsersProvider } from 'hooks/users/useUsersContext'
import { OwnersProvider } from 'hooks/owners/useOwnersContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const AdminMainPage = () => {
  const classes = useStyles()
  const { url } = useRouteMatch()

  return (
    <div className={classes.root}>
      <Header />
      <Switch>
        <Route path={`${url}/users`}>
          <UsersProvider>
            <Users />
          </UsersProvider>
        </Route>
        <Route path={`${url}/owners/:id`}>
          <Owner />
        </Route>
        <Route path={`${url}/owners`}>
          <OwnersProvider>
            <Owners />
          </OwnersProvider>
        </Route>
        <Route path={`${url}/genres`}>
          <Genres />
        </Route>
        <Route path={`${url}/permissions`}>
          <Permissions />
        </Route>
      </Switch>
    </div>
  )
}

export default AdminMainPage
