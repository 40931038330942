import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  summary: {
    paddingLeft: theme.spacing(3),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  summaryContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  optional: {
    fontStyle: 'italic',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
}))

const FormCard = ({
  title,
  subtitle,
  collapsible,
  optional,
  disabled,
  children,
  summaryActions,
  ...props
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion
      expanded={expanded === title || !collapsible}
      onChange={handleChange(title)}
      {...props}
    >
      <AccordionSummary
        disabled={disabled}
        className={classes.summary}
        expandIcon={collapsible && <ExpandMore />}
        aria-controls={`${title}bh-content`}
        id={`${title}bh-header`}
      >
        <div className={classes.summaryContent}>
          <div className={classes.titleContainer}>
            <span>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              {optional && (
                <Typography variant="h3" className={classes.optional}>
                  {'\xa0- optional'}
                </Typography>
              )}
            </span>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
          {summaryActions}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default FormCard
