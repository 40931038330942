import React, { useContext, createContext, useState } from 'react'

import { DEFAULT_TABLE_PAGE_SIZE } from 'components/table/Table'

import { useArtists } from 'hooks/artists/useArtists'

const ArtistsContext = createContext()

const ArtistsProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedArtists, setSelectedArtists] = useState([])
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE)

  const [currentPage, setCurrentPage] = useState(0)

  const { artists, totalCount, isLoading, mutate } = useArtists({
    name: searchValue,
    limit: pageSize,
    sortFields: ['playlistUpdatedAt_desc'],
    metadataFields: ['clipCount'],
    skip: currentPage * pageSize,
  })

  const value = {
    artists,
    mutate,
    isLoading,
    totalCount,
    setPageSize,
    selectedArtists,
    setCurrentPage,
    setSearchValue,
    setSelectedArtists,
  }

  return (
    <ArtistsContext.Provider value={value}>{children}</ArtistsContext.Provider>
  )
}

const ArtistsConsumer = ({ children }) => {
  return (
    <ArtistsContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'ArtistsConsumer must be used within a ArtistsProvider'
          )
        }
        return children(context)
      }}
    </ArtistsContext.Consumer>
  )
}

const useArtistsContext = () => {
  const context = useContext(ArtistsContext)
  if (context === undefined) {
    throw new Error('useArtistsContext must be used within a ArtistsProvider')
  }
  return context
}

export { ArtistsProvider, ArtistsConsumer, useArtistsContext }
