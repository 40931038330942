import useSWR from 'swr'

const DEFAULT_POPULATE = ['user', 'clip']
const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100

export const useRatingTypeOptions = () => {
  const key = '/api/ratings/options'
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    ratingTypeOptions: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useRatings = ({
  users,
  clips,
  populateFields = DEFAULT_POPULATE,
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
} = {}) => {
  const queries = [`skip=${skip}`, `limit=${limit}`]

  const userIds = users?.join(',')
  if (userIds?.length) queries.push(`user=${userIds}`)

  const clipIds = clips?.join(',')
  if (clipIds?.length) queries.push(`clip=${clipIds}`)

  const populate = populateFields?.join(',')
  if (populate?.length) queries.push(`populate=${populate}`)

  const key = '/api/ratings?' + queries.join('&')
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    ratings: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}
