import React, { useMemo, useState } from 'react'
import { Form, Formik } from 'formik'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

import Select from 'components/Select'
import TextInput from 'components/TextInput'
import DateInput from 'components/DateInput'
import PhoneNumberInput from 'components/PhoneNumberInput'

import { useSnackbar } from 'hooks/useSnackbar'
import { useUsers, useUsersById } from 'hooks/users/useUsers'
import { usePermissions } from 'hooks/permissions/usePermissions'

import userSchema from 'schemas/users'

import { addUser, initialUser, updateUser } from 'utils/users'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const UserDialog = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { permissions } = usePermissions()
  const roles = useMemo(() => {
    if (!permissions) return []

    const rolesObject = permissions.reduce((acc, { role }) => {
      return { ...acc, [role]: 1 }
    }, {})

    return Object.keys(rolesObject)
  }, [permissions])

  const isNew = !id
  const { mutate: mutateUsers } = useUsers()
  const { user, isLoading, mutate: mutateUserById } = useUsersById(!isNew && id)

  const handleSubmit = async (userData) => {
    setIsSubmitting(true)
    let action
    let messages

    try {
      if (isNew) {
        action = addUser
        messages = {
          success: 'Successfully added user',
          error: 'Failed to save user',
        }
      } else {
        action = updateUser
        messages = {
          success: 'Successfully updated user',
          error: 'Failed to update user',
        }
      }

      await action(userData)

      mutateUsers()
      mutateUserById()
      popSnackbar('success', messages.success)
    } catch (error) {
      popSnackbar('error', messages.error)
    }

    setIsSubmitting(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${isNew ? 'Add' : 'Edit'} User`}</DialogTitle>
      {!isNew && isLoading ? (
        <DialogContent className={classes.loading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          validationSchema={() => userSchema(isNew)}
          initialValues={isNew ? initialUser : user}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Enter user name"
                  required
                />
                <TextInput
                  name="username"
                  label="Username"
                  placeholder="Enter username"
                  required
                />
                <TextInput
                  name="email"
                  label="Email"
                  placeholder="Enter email address"
                  required
                />
                <DateInput name="birthDate" label="Birth Date" required />
                <PhoneNumberInput
                  name="phoneNumber"
                  label="Phone Number"
                  required
                />
                {isNew && (
                  <TextInput
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    helperText="Warning! only the user will be able to change his password."
                    required
                  />
                )}
                <Select
                  name="role"
                  label="Role"
                  placeholder="Enter role"
                  options={roles}
                  helperText="This user will inherit all the permissions of the selected role."
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid || isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default UserDialog
