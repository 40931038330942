import React from 'react'
import { Form, Formik } from 'formik'

import PopupCard from '../PopupCard'
import TextInput from 'components/TextInput'

import yup from 'schemas/yup'

const validationSchema = yup.object({
  name: yup.string().min(1).required(),
})

export const NameInput = ({ initialValues, onChange, onCancel }) => {
  const paramName = 'name'

  const handleOnSubmit = (values) => {
    onChange(paramName, values[paramName])
  }

  return (
    <Formik
      validateOnMount
      initialValues={{ [paramName]: '', ...initialValues }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <PopupCard onCancel={onCancel}>
          <TextInput
            name={paramName}
            label="Name"
            fullWidth
            size="small"
            disableClearable
            placeholder="Enter a name"
          />
        </PopupCard>
      </Form>
    </Formik>
  )
}

export const NameLabel = ({ value }) => {
  return value || '-'
}
