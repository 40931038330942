import yup from './yup'

const resetPasswordSchema = yup.object({
  password: yup.string('Enter your new password').required('Password is required'),
  confirmPassword: yup
      .string('Enter your name')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(),
})

export default resetPasswordSchema
