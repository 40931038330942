import React from 'react'
import { useParams } from 'react-router-dom'

import ArtistPageContent from 'components/artist/ArtistPageContent'

import { PlaylistProvider } from 'hooks/playlists/usePlaylistContext'
import { ArtistConsumer, ArtistProvider } from 'hooks/artists/useArtistContext'

const Artist = () => {
  const { id: artistId } = useParams()

  return (
    <ArtistProvider id={artistId}>
      <ArtistConsumer>
        {({ artist }) => (
          <PlaylistProvider id={artist?.playlist?._id}>
            <ArtistPageContent />
          </PlaylistProvider>
        )}
      </ArtistConsumer>
    </ArtistProvider>
  )
}

export default Artist
