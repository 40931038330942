import React from 'react'
import { useParams } from 'react-router-dom'

import { PlaylistProvider } from 'hooks/playlists/usePlaylistContext'

import PlaylistPageContent from 'components/playlist/PlaylistPageContent'

const Playlist = () => {
  const { id: playlistId } = useParams()

  return (
    <PlaylistProvider id={playlistId}>
      <PlaylistPageContent />
    </PlaylistProvider>
  )
}

export default Playlist
