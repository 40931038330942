import axios from 'axios'

export const initialUser = {
  name: '',
  email: '',
  password: '',
  username: '',
  role: 'guest',
  birthDate: null,
  phoneNumber: '',
}

export const register = async (userData) => {
  const { data } = await axios.post('/api/users/register', userData)
  return data
}

export const addUser = async (userData) => {
  const { data } = await axios.post('/api/users', userData)
  return data
}

export const updateUser = async (userToUpdate) => {
  const { _id: userId } = userToUpdate
  const { data: updatedUsers } = await axios.put(
    `/api/users/${userId}`,
    userToUpdate
  )

  return updatedUsers
}

export const deleteUsersByIds = async (userIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/users', { data: { ids: userIds } })
  return deletedCount
}

export const requestResetPassword = async (email) => {
  const { data } = await axios.post('/api/users/requestResetPassword', {
    email,
  })

  return data
}

export const resetPassword = async (token, userId, newPassword) => {
  const { data } = await axios.put('/api/users/resetPassword', {
    token,
    userId,
    password: newPassword,
  })

  return data
}
