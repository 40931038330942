import React from 'react'

import Graph from 'react-vis-network-graph'
import { CircularProgress } from '@material-ui/core'

import { useGenres, useGenresGraph } from 'hooks/genres/useGenres'

const options = {
  layout: {
    clickToUse: true,
    zoomSpeed: 0.5,
  },
  edges: {
    color: '#000000',
  },
  height: 500,
}

const GenresTree = () => {
  const { genres, isLoading } = useGenres()
  const graph = useGenresGraph(genres)

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Graph graph={graph} options={options} />
      )}
    </div>
  )
}

export default GenresTree
