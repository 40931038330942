import React from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { useRegions } from 'hooks/regions/useRegions'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  autocomplete: {
    width: 200,
    marginLeft: 20,
  },
}))

const FilterByRegion = ({ onChange, value }) => {
  const classes = useStyles()
  const { regions } = useRegions()

  return (
    <div className={classes.container}>
      <Typography>Filter By</Typography>
      <div className={classes.autocomplete}>
        <Autocomplete
          size="small"
          autoComplete
          value={value}
          onChange={onChange}
          options={regions || []}
          getOptionLabel={(region) => `${region?.name}`}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              variant="outlined"
              placeholder="All Regions"
            />
          )}
        />
      </div>
    </div>
  )
}

export default FilterByRegion
