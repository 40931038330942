import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import { useOwners } from 'hooks/owners/useOwners'
import useOwnersColumns from 'hooks/owners/useOwnersColumns'
import { useOwnersContext } from 'hooks/owners/useOwnersContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id']
const columnNames = [
  ...defaultHiddenColumnNames,
  'name',
  'regions',
  'edit',
  'username',
  'email',
  'role',
  'active',
  'phoneNumber',
  'birthDate',
]

const SEARCH_DEBOUNCE = 500
const DEFAULT_PAGE_SIZE = 100

const OwnersTable = () => {
  const classes = useStyles()
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_DEBOUNCE)

  const [currentPage, setCurrentPage] = useState(0)

  const { owners, totalCount, isLoading } = useOwners({
    name: debouncedSearchValue,
    limit: pageSize,
    skip: currentPage * pageSize,
  })
  const { selectedOwners, setSelectedOwners } = useOwnersContext()

  const columns = useOwnersColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={owners || []}
        columns={columns}
        selection={selectedOwners}
        onSelectionChange={setSelectedOwners}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default OwnersTable
