import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

import Header from 'components/Header'
import RecipesConfirmDeleteDialog from 'components/recipes/RecipesConfirmDeleteDialog'

import { useRecipesContext } from 'hooks/recipes/useRecipesContext'

const DIALOGS = {
  DELETE: 'deleteRecipes',
}

const RecipesHeader = () => {
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(null)
  const { selectedRecipes, setSelectedRecipes, mutate } = useRecipesContext()

  const handleOnClickCreateRecipe = async () => {
    history.push('/recipes/new')
  }

  const handleOnCloseDialog = () => setOpenDialog(null)

  const handleOnChange = () => {
    mutate()
    setSelectedRecipes([])
    setOpenDialog(null)
  }

  return (
    <>
      <Header title="Recipes">
        <Button
          disabled={!selectedRecipes.length}
          variant="outlined"
          color="secondary"
          onClick={() => setOpenDialog(DIALOGS.DELETE)}
        >
          Delete
        </Button>
        <Button
          onClick={handleOnClickCreateRecipe}
          variant="contained"
          color="primary"
        >
          Create Recipe
        </Button>
      </Header>
      {openDialog === DIALOGS.DELETE && (
        <RecipesConfirmDeleteDialog
          open
          ids={selectedRecipes}
          onClose={handleOnCloseDialog}
          onDelete={handleOnChange}
        />
      )}
    </>
  )
}

export default RecipesHeader
