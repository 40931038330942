import axios from 'axios'

export const initialOwner = {
  name: '',
  email: '',
  links: [],
  contacts: [],
}

export const addOwner = async (ownerData) => {
  const { data } = await axios.post('/api/owners', ownerData)
  return data
}

export const updateOwner = async (ownerToUpdate) => {
  const { _id: ownerId } = ownerToUpdate
  const { data: updatedOwners } = await axios.put(
    `/api/owners/${ownerId}`,
    ownerToUpdate
  )
  return updatedOwners
}

export const deleteOwnersByIds = async (ownerIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/owners', { data: { ids: ownerIds } })
  return deletedCount
}
