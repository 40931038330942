import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import useClipsColumns from 'hooks/clips/useClipsColumns'
import { useClipsContext } from 'hooks/clips/useClipsContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id', 'originalName', 'contentTypes']
const columnNames = [
  ...defaultHiddenColumnNames,
  'thumbnail',
  'name',
  'artists',
  'owners',
  'genres',
  'regions',
  'duration',
  'playlistCount',
  'createdAt',
  'updatedAt',
]

const SEARCH_DEBOUNCE = 500

const ClipsTable = () => {
  const classes = useStyles()
  const [tempSearchValue, setTempSearchValue] = useState('')

  const {
    clips,
    pageSize,
    isLoading,
    totalCount,
    setPageSize,
    currentPage,
    selectedClips,
    setCurrentPage,
    setSearchValue,
    setSelectedClips,
  } = useClipsContext()

  const debouncedSetSearchValue = useDebouncedCallback(
    setSearchValue,
    SEARCH_DEBOUNCE
  )

  const handleOnChangeTempSearchValue = (value) => {
    setTempSearchValue(value)
    debouncedSetSearchValue(value)
  }

  const columns = useClipsColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={clips || []}
        columns={columns}
        selection={selectedClips}
        onSelectionChange={setSelectedClips}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={tempSearchValue}
        onChangeSearchValue={handleOnChangeTempSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default ClipsTable
