import React from 'react'
import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import { useRegions } from 'hooks/regions/useRegions'
import useRegionsColumns from 'hooks/regions/useRegionsColumns'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id']
const columnNames = [
  ...defaultHiddenColumnNames,
  'edit',
  'name',
  'parentRegions',
  'playlistCount',
  'active',
]

const RegionsTable = ({ selection, onSelectionChange }) => {
  const classes = useStyles()
  const { regions, isLoading } = useRegions({
    metadataFields: ['playlistCount'],
  })

  const columns = useRegionsColumns(columnNames)

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={regions || []}
        columns={columns}
        isLoading={isLoading}
        selection={selection}
        onSelectionChange={onSelectionChange}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default RegionsTable
