import React from 'react'

import classnames from 'classnames'

import { Button, makeStyles } from '@material-ui/core'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import { useEventStream } from 'hooks/useEventStream'

const useStyles = makeStyles(() => ({
  sideArrowBase: {
    position: 'absolute',
    height: '100%',
    width: 50,
    opacity: 0,
    transition: '0.3s',
    backgroundColor: 'rgba(0,0,0,0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.75)',
    },
    zIndex: 10,
  },
  sideArrowRightAlignment: {
    right: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideArrowLeftAlignment: {
    left: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  showArrows: {
    opacity: 1,
  },
  arrowIcon: {
    color: 'grey',
  },
}))

const ArrowsOverlay = ({ visible, prevRef, nextRef }) => {
  const classes = useStyles()
  const { addClickEvent } = useEventStream()

  return (
    <>
      <Button
        id="playlistsNextPageButton"
        className={classnames(
          classes.sideArrowBase,
          classes.sideArrowRightAlignment,
          visible && classes.showArrows
        )}
        ref={nextRef}
        onClickCapture={addClickEvent}
      >
        <FaChevronRight size={30} className={classes.arrowIcon} />
      </Button>
      <Button
        id="playlistsPrevPageButton"
        className={classnames(
          classes.sideArrowBase,
          classes.sideArrowLeftAlignment,
          visible && classes.showArrows
        )}
        ref={prevRef}
        onClickCapture={addClickEvent}
      >
        <FaChevronLeft size={30} className={classes.arrowIcon} />
      </Button>
    </>
  )
}

export default ArrowsOverlay
