import { useMemo } from 'react'
import useSWR from 'swr'

const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100

export const useUsers = ({
  name,
  mapById,
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
} = {}) => {
  const queries = [`skip=${skip}`, `limit=${limit}`]

  if (name) queries.push(`name=${name}`)

  const key = '/api/users?' + queries.join('&')
  const { data, isValidating, ...rest } = useSWR(key)

  const users = useMemo(() => {
    if (!mapById) return data?.result
    const usersMappedById = data?.result.reduce((acc, curr) => {
      return { ...acc, [curr._id]: curr }
    }, {})
    return usersMappedById
  }, [data, mapById])

  return {
    users,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useUsersById = (id) => {
  const { data, isValidating, ...rest } = useSWR(id && `/api/users/${id}`)

  return {
    user: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}
