import React from 'react'
import { makeStyles } from '@material-ui/core'

import ClipsTable from 'components/clips/ClipsTable'
import ClipsHeader from 'components/clips/ClipsHeader'

import { ClipsProvider } from 'hooks/clips/useClipsContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Clips = () => {
  const classes = useStyles()

  return (
    <ClipsProvider>
      <div className={classes.root}>
        <ClipsHeader />
        <ClipsTable />
      </div>
    </ClipsProvider>
  )
}

export default Clips
