import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

import Header from 'components/Header'
import ArtistsConfirmDeleteDialog from 'components/artists/ArtistsConfirmDeleteDialog'

import { addArtist } from 'utils/artists'

import { useArtistsContext } from 'hooks/artists/useArtistsContext'

const DIALOGS = {
  DELETE: 'deleteArtists',
}

const ArtistsHeader = () => {
  const history = useHistory()

  const [openDialog, setOpenDialog] = useState(null)
  const { mutate, selectedArtists, setSelectedArtists } = useArtistsContext()

  const handleOnCloseDialog = () => setOpenDialog(null)

  const handleOnChange = () => {
    mutate()
    setSelectedArtists([])
    handleOnCloseDialog()
  }

  const handleOnClickCreateArtist = async () => {
    const initialArtist = {
      name: 'New Artist',
    }
    const newArtist = await addArtist(initialArtist)
    history.push(`/artists/${newArtist._id}`)
  }

  return (
    <>
      <Header title="Artists">
        <Button
          variant="outlined"
          color="secondary"
          disabled={!selectedArtists.length}
          onClick={() => setOpenDialog(DIALOGS.DELETE)}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnClickCreateArtist}
        >
          Add new artist
        </Button>
      </Header>
      {openDialog === DIALOGS.DELETE && (
        <ArtistsConfirmDeleteDialog
          open
          ids={selectedArtists}
          onDelete={handleOnChange}
          onClose={handleOnCloseDialog}
        />
      )}
    </>
  )
}

export default ArtistsHeader
