import React from 'react'
import { DndProvider } from 'react-dnd'
import { Switch } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Demo from 'pages/Demo'
import Login from 'pages/Login'
import Register from 'pages/Register'
import MainPage from 'pages/MainPage'
import ResetPassword from 'pages/ResetPassword'
import ForgotPassword from 'pages/ForgotPassword'

import PublicRoute from 'components/PublicRoute'
import PrivateRoute from 'components/PrivateRoute'

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Switch>
        <PublicRoute path="/login">
          <Login />
        </PublicRoute>
        <PublicRoute path="/register">
          <Register />
        </PublicRoute>
        <PublicRoute path="/forgot-password">
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute path="/reset">
          <ResetPassword />
        </PublicRoute>
        <PrivateRoute path="/demo/:regionId?">
          <Demo />
        </PrivateRoute>
        <PrivateRoute path="/">
          <MainPage />
        </PrivateRoute>
      </Switch>
    </DndProvider>
  )
}

export default App
