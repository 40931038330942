import React from 'react'

import List from '../controls/List'
import RegionListItem from './RegionsListItem'

import { useDemo } from 'hooks/demo/useDemo'

const RegionsBar = () => {
  const { currentRegionsIds } = useDemo()

  return (
    <List>
      {currentRegionsIds.map((_, index) => (
        <RegionListItem key={index} regionIndex={index} />
      ))}
      <RegionListItem placeholder />
    </List>
  )
}

export default RegionsBar
