/* eslint-disable react/display-name */
import React, { memo, useCallback, useMemo } from 'react'

import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { areEqual, VariableSizeList as List } from 'react-window'
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'

import ClipItem from 'components/clips/ClipsList/ClipItem'
import ClipSkeleton from 'components/clips/ClipsList/ClipSkeleton'

const ROW_HEIGHT = 64
const THRESHOLD = 100
const OVERSCAN_COUNT = 25

const Row = memo(({ index: totalIndex, style, data }) => {
  const { clips, isLoading, onRemove, topChildren, topChildrenHeight } = data

  const index = totalIndex - (topChildren && 1)

  if (index === -1) {
    const topChildrenStyle = { ...style, height: topChildrenHeight }
    return <div style={topChildrenStyle}>{topChildren}</div>
  }

  const clip = clips[index]

  if (!clip || isLoading) return <ClipSkeleton style={style} index={index} />

  const handleOnRemove = onRemove && (() => onRemove(index))

  return (
    <Draggable draggableId={index.toString()} index={index}>
      {(provided) => (
        <ClipItem
          provided={provided}
          clip={clip}
          style={style}
          index={index}
          onRemove={handleOnRemove}
        />
      )}
    </Draggable>
  )
}, areEqual)

const ClipsList = ({
  clips,
  loadClips,
  totalCount,
  isLoading,
  onReorder,
  onRemove,
  topChildren,
  topChildrenHeight,
}) => {
  const onDragEnd = useCallback(
    (result) => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      ) {
        return
      }

      onReorder(result.source.index, result.destination.index)
    },
    [onReorder]
  )

  const loadMoreItems = isLoading ? () => {} : (skip) => loadClips(skip)

  const isItemLoaded = (index) => {
    return (topChildren && index === 0) || !!clips[index]
  }

  const getItemSize = (index) => {
    if (topChildren && index === 0) return topChildrenHeight
    return ROW_HEIGHT
  }

  const itemData = useMemo(
    () => ({
      clips,
      isLoading,
      onRemove,
      topChildren,
      topChildrenHeight,
    }),
    [clips, isLoading, onRemove, topChildren, topChildrenHeight]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <ClipItem
            provided={provided}
            isDragging={snapshot.isDragging}
            clip={clips[rubric.source.index]}
            index={rubric.source.index}
          />
        )}
      >
        {(droppableProvided) => (
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalCount + 1}
                loadMoreItems={loadMoreItems}
                threshold={THRESHOLD}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    ref={ref}
                    width={width}
                    height={height}
                    itemSize={getItemSize}
                    itemCount={totalCount + 1}
                    overscanCount={OVERSCAN_COUNT}
                    estimatedItemSize={ROW_HEIGHT}
                    onItemsRendered={onItemsRendered}
                    itemData={itemData}
                    innerRef={droppableProvided.innerRef}
                  >
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default memo(ClipsList)
