import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles, Typography } from '@material-ui/core'

import FormCard from 'components/FormCard'
import TrippiclipsTable from 'components/table/Table'

import { useClips } from 'hooks/clips/useClips'
import useClipsColumns from 'hooks/clips/useClipsColumns'

const useStyles = makeStyles({
  container: {
    boxShadow: 'none',
  },
})

const OwnerClips = () => {
  const classes = useStyles()
  const { id: ownerId } = useParams()

  const isNew = ownerId === 'new'
  const { clips: relatedClips } = useClips(!isNew && { owner: ownerId })

  const columns = useClipsColumns([
    'smallThumbnail',
    'name',
    'genres',
    'duration',
  ])

  return (
    <FormCard title="Related Clips" collapsible disabled={isNew}>
      {relatedClips?.length ? (
        <TrippiclipsTable
          classes={{ container: classes.container }}
          rows={relatedClips || []}
          columns={columns}
          hideSelection
          hideToolbar
        />
      ) : (
        <Typography>No related clips</Typography>
      )}
    </FormCard>
  )
}

export default OwnerClips
