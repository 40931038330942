import React, { useContext, createContext, useState, useEffect } from 'react'

import { DEFAULT_TABLE_PAGE_SIZE } from 'components/table/Table'

import { useRecipes } from 'hooks/recipes/useRecipes'

const RecipesContext = createContext()

const RecipesProvider = ({ children }) => {
  const [query, setQuery] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [selectedRecipes, setSelectedRecipes] = useState([])
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE)

  const [currentPage, setCurrentPage] = useState(0)

  const { recipes, totalCount, isLoading, mutate } = useRecipes({
    name: searchValue,
    limit: pageSize,
    skip: currentPage * pageSize,
    ...query,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [query, searchValue])

  const value = {
    recipes,
    query,
    mutate,
    setQuery,
    isLoading,
    totalCount,
    setPageSize,
    selectedRecipes,
    setCurrentPage,
    setSearchValue,
    setSelectedRecipes,
  }

  return (
    <RecipesContext.Provider value={value}>{children}</RecipesContext.Provider>
  )
}

const RecipesConsumer = ({ children }) => {
  return (
    <RecipesContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'RecipesConsumer must be used within a RecipesProvider'
          )
        }
        return children(context)
      }}
    </RecipesContext.Consumer>
  )
}

const useRecipesContext = () => {
  const context = useContext(RecipesContext)
  if (context === undefined) {
    throw new Error('useRecipesContext must be used within a RecipesProvider')
  }
  return context
}

export { RecipesProvider, RecipesConsumer, useRecipesContext }
