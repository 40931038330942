import React from 'react'
import { useField } from 'formik'
import update from 'immutability-helper'

import { makeStyles, Button } from '@material-ui/core'

import Select from 'components/Select'
import FormCard from 'components/FormCard'
import TextInput from 'components/TextInput'
import PhoneNumberInput from 'components/PhoneNumberInput'

const useStyles = makeStyles((theme) => ({
  addButton: {
    alignSelf: 'flex-end',
  },
  contactListContainer: {
    marginTop: theme.spacing(2),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  contactCard: {
    padding: theme.spacing(2),
    border: '1px solid',
    borderRadius: '3px',
    borderColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  removeContactButton: {
    marginTop: theme.spacing(5.1),
    height: theme.spacing(5),
  },
  contactTypeContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'column',
  },
}))

const createNewContact = () => ({
  type: '',
  name: '',
  phoneNumber: '',
  email: ',',
})

const CONTACT_TYPES = ['Manager', 'Press', 'Booking', 'Other']

const Contacts = () => {
  const classes = useStyles()
  const [, meta, helpers] = useField('contacts')

  const handleOnAddContact = () => {
    const updatedContacts = update(meta.value, { $push: [createNewContact()] })
    helpers.setValue(updatedContacts)
  }

  const handleOnRemoveContact = (index) => () => {
    const updatedContacts = update(meta.value, { $splice: [[index, 1]] })
    helpers.setValue(updatedContacts)
  }

  return (
    <FormCard title={'Contacts'} collapsible>
      <Button
        className={classes.addButton}
        color="primary"
        variant="outlined"
        onClick={handleOnAddContact}
        disabled={meta.error}
      >
        Add contact
      </Button>
      <div className={classes.contactListContainer}>
        {meta.value.map((_, index) => {
          return (
            <div className={classes.contactCard} key={index}>
              <div className={classes.contactTypeContainer}>
                <Select
                  label="Type"
                  name={`contacts[${index}].type`}
                  options={CONTACT_TYPES}
                  disableClearable
                  fullwidth
                  required
                />
              </div>
              <TextInput
                label="Name"
                name={`contacts[${index}].name`}
                required
              />
              <TextInput label="Email" name={`contacts[${index}].email`} />
              <PhoneNumberInput
                name={`contacts[${index}].phoneNumber`}
                label="Phone number"
                debounce={false}
              />
              <Button
                className={classes.removeContactButton}
                onClick={handleOnRemoveContact(index)}
                variant="outlined"
              >
                Delete
              </Button>
            </div>
          )
        })}
      </div>
    </FormCard>
  )
}

export default Contacts
