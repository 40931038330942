import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable from 'components/table/Table'

import useRecipesColumns from 'hooks/recipes/useRecipesColumns'
import { useRecipesContext } from 'hooks/recipes/useRecipesContext'

const useStyles = makeStyles({
  tableContainer: {
    width: '100%',
  },
})

const defaultHiddenColumnNames = ['_id', 'rating', 'editors']
const SEARCH_DEBOUNCE = 500

const RecipesTable = () => {
  const classes = useStyles()
  const [tempSearchValue, setTempSearchValue] = useState('')

  const {
    recipes,
    pageSize,
    isLoading,
    totalCount,
    setPageSize,
    currentPage,
    selectedRecipes,
    setCurrentPage,
    setSearchValue,
    setSelectedRecipes,
  } = useRecipesContext()

  const debouncedSetSearchValue = useDebouncedCallback(
    setSearchValue,
    SEARCH_DEBOUNCE
  )

  const handleOnChangeTempSearchValue = (value) => {
    setTempSearchValue(value)
    debouncedSetSearchValue(value)
  }

  const columns = useRecipesColumns()

  return (
    <div className={classes.tableContainer}>
      <TrippiclipsTable
        rows={recipes || []}
        columns={columns}
        selection={selectedRecipes}
        onSelectionChange={setSelectedRecipes}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        searchValue={tempSearchValue}
        onChangeSearchValue={handleOnChangeTempSearchValue}
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
    </div>
  )
}

export default RecipesTable
