export const playlistStatusOptions = [
  {
    value: 'active',
    label: 'Active',
    color: 'primary',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
]

export const playlistTypeOptions = {
  NORMAL: 'normal',
  ARTIST: 'artist',
  SMART: 'smart',
}

