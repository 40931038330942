import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { HiDotsHorizontal } from 'react-icons/hi'

const ClipOptionsMenu = ({ onRemove }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const menuItems = [
    onRemove && (
      <MenuItem key={1} onClick={onRemove}>
        Remove from this playlist
      </MenuItem>
    ),
  ].filter(Boolean)

  if (!menuItems.length) return null

  return (
    <>
      <IconButton
        aria-controls="playlist-options"
        aria-haspopup="true"
        disableRipple
        onClick={(event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}
      >
        <HiDotsHorizontal />
      </IconButton>
      <Menu
        id="playlist-options"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems}
      </Menu>
    </>
  )
}

export default ClipOptionsMenu
