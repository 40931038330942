import { useCallback, useState } from 'react'

const VERSION = '3'
const LAYOUT_KEY = 'dashboardLayout'
const VERSION_KEY = 'dashboardVersion'

const initialLayout = [
  { w: 1, h: 2, x: 2, y: 4, i: 'clipCount' },
  { w: 1, h: 2, x: 2, y: 0, i: 'ownersCount' },
  { w: 1, h: 2, x: 3, y: 0, i: 'artistsCount' },
  { w: 1, h: 2, x: 2, y: 2, i: 'playlistsCount' },
  { w: 1, h: 6, x: 1, y: 0, i: 'clipCountByGenre' },
  { w: 1, h: 2, x: 3, y: 4, i: 'totalClipDuration' },
  { w: 1, h: 2, x: 3, y: 2, i: 'totalPlaylistsDuration' },
  { w: 1, h: 6, x: 0, y: 0, i: 'contentAmountByGenreInHours' },
  { w: 4, h: 4, x: 0, y: 6, i: 'regionsTable' },
  { w: 4, h: 3, x: 0, y: 10, i: 'columnsTable' },
]

const getInitialState = () => {
  const clientCurrentVersion = localStorage.getItem(VERSION_KEY)
  const clientCurrentLayout = localStorage.getItem(LAYOUT_KEY)

  if (clientCurrentVersion !== VERSION || !clientCurrentLayout) {
    localStorage.setItem(VERSION_KEY, VERSION)
    localStorage.setItem(LAYOUT_KEY, JSON.stringify(initialLayout))

    return initialLayout
  }

  return JSON.parse(clientCurrentLayout)
}

export const useDashboardLayout = () => {
  const [layout, setLayout] = useState(getInitialState)

  const changeLayout = useCallback((newLayout) => {
    localStorage.setItem(LAYOUT_KEY, JSON.stringify(newLayout))

    setLayout(newLayout)
  }, [])

  return {
    layout,
    changeLayout,
  }
}
