const useRegionsColumns = (columnNames) => {
  const columns = [
    {
      name: 'index',
      width: 44,
      fixed: 'left',
      title: '\x09',
      groupingEnabled: false,
      sortingEnabled: false,
      getCellValue: ({ index }) => index || '',
    },
    { name: '_id', title: 'ID' },
    { name: 'role', title: 'Role' },
    { name: 'resource', title: 'Resource' },
    { name: 'action', title: 'Action' },
    { name: 'possession', title: 'Possession' },
  ]

  if (columnNames?.length) {
    const filteredColumns = columns.filter(({ name }) =>
      columnNames.includes(name)
    )
    return filteredColumns
  }

  return columns
}

export default useRegionsColumns
