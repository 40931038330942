import axios from 'axios'

export const sendEventBatch = async (records) => {
  const { data } = await axios.post('/api/records/CLIENT_CLICKSTREAM/batch', {
    batch: { records },
  })
  return data
}

export const sendEvent = async (record) => {
  const { data } = await axios.post('/api/records/CLIENT_CLICKSTREAM', {
    record,
  })
  return data
}

export const getUserLocation = async () => {
  const { data } = await axios.get('https://geolocation-db.com/json/')
  return data
}
