import useSWR from 'swr'

const DEFAULT_SKIP = 0
const DEFAULT_LIMIT = 100
const DEFAULT_POPULATE = ['owners', 'playlists']

export const useRecipes = ({
  skip = DEFAULT_SKIP,
  limit = DEFAULT_LIMIT,
  populateFields = DEFAULT_POPULATE,
  name,
} = {}) => {
  const options = [`skip=${skip}`, `limit=${limit}`]

  if (name) options.push(`name=${name}`)

  if (populateFields?.length) {
    options.push(`populate=${populateFields?.join(',')}`)
  }

  const key = `/api/recipes?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(key)

  return {
    recipes: data?.result,
    totalCount: data?.totalCount,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useRecipeById = (
  id,
  { populateFields = DEFAULT_POPULATE } = {}
) => {
  const options = []

  if (populateFields?.length) {
    options.push(`populate=${populateFields?.join(',')}`)
  }

  const key = `/api/recipes/${id}?${options.join('&')}`
  const { data, isValidating, ...rest } = useSWR(id && key)

  return {
    recipe: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}
