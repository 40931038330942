import React, { memo } from 'react'

import { Skeleton } from '@material-ui/lab'
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core'
import { BsPlayFill } from 'react-icons/bs'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: theme.spacing(1),
  },
  grid: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(3),
    },
  },
  index: {
    width: 30,
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  thumbnail: {
    '& > *': {
      transform: 'none',
      aspectRatio: '1 / 1',
      height: 48,
      objectFit: 'cover',
    },
  },
  name: {
    display: 'flex',
    alignItems: 'normal',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  options: {
    visibility: 'hidden',
  },
  play: {
    width: 30,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'none',
  },
}))

const ClipSkeleton = ({ style, index }) => {
  const classes = useStyles()

  return (
    <div style={style}>
      <div className={classes.root}>
        <Grid className={classes.grid} container direction="row">
          <Grid item className={classes.index}>
            {index + 1}
          </Grid>
          <Grid item className={classes.play}>
            <IconButton size="small" disableRipple>
              <BsPlayFill />
            </IconButton>
          </Grid>
          <Grid item className={classes.thumbnail}>
            <Skeleton type="react" />
          </Grid>
          <Grid item className={classes.name} md="4">
            <Skeleton type="text" width={100} />
            <Skeleton type="text" width={70} />
          </Grid>
          <Grid item>
            <Skeleton type="text" width={85} />
          </Grid>
          <Box flex="1" />
          <Grid item>
            <Skeleton type="text" width={40} />
          </Grid>
          <Grid item className={classes.options}>
            <Skeleton type="react" width={40} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default memo(ClipSkeleton)
