import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  spacer: {
    flex: '1',
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
}))

const Header = ({ title, description, children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <div className={classes.spacer} />
      {children}
    </Box>
  )
}

export default Header
