import React, { useState } from 'react'
import classnames from 'classnames'

import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import ArtistDialog from 'components/artist/ArtistDialog'

import { useArtistContext } from 'hooks/artists/useArtistContext'
import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    margin: -theme.spacing(5),
    padding: theme.spacing(5),
    position: 'relative',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&:hover': {
      '& $editOverlay': {
        opacity: 1,
      },
    },
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    objectFit: 'cover',
    zIndex: -999,
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  infoContainerWithBackground: {
    color: 'white',
    marginTop: theme.spacing(12),
  },
  loader: {
    alignSelf: 'center',
    margin: theme.spacing(10),
  },
  title: {
    fontSize: 64,
    color: 'inherit',
  },
  topSubtitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 12,
    color: 'inherit',
  },
}))

const ArtistInfo = () => {
  const classes = useStyles()
  const { clips } = usePlaylistContext()
  const { artist, isLoadingArtist } = useArtistContext()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  if (isLoadingArtist) {
    return <CircularProgress classes={{ root: classes.loader }} />
  }

  const backgroundImage = clips?.[0]?.thumbnailUrl

  return (
    <>
      <Box className={classes.root} onClick={() => setIsDialogOpen(true)}>
        <div
          className={classnames(
            classes.infoContainer,
            backgroundImage && classes.infoContainerWithBackground
          )}
        >
          <img src={backgroundImage} className={classes.backgroundImage} />
          <Typography className={classes.topSubtitle}>Artist</Typography>
          <Typography className={classes.title} variant="h1">
            {artist.name}
          </Typography>
        </div>
      </Box>
      <ArtistDialog
        id={artist._id}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  )
}

export default ArtistInfo
