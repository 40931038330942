/* eslint-disable react/display-name */
import React from 'react'

import { NameInput, NameLabel } from './Name'
import { OwnerInput, OwnerLabel } from './Owner'
import { GenreInput, GenreLabel } from './Genre'
import { ArtistInput, ArtistLabel } from './Artist'
import { RegionInput, RegionLabel } from './Region'

const options = [
  {
    label: 'Genre',
    name: 'genre',
    renderInput: (props) => <GenreInput {...props} />,
    renderValueLabel: (props) => <GenreLabel {...props} />,
  },
  {
    label: 'Name',
    name: 'name',
    renderInput: (props) => <NameInput {...props} />,
    renderValueLabel: (props) => <NameLabel {...props} />,
  },
  {
    label: 'Artist',
    name: 'artist',
    renderInput: (props) => <ArtistInput {...props} />,
    renderValueLabel: (props) => <ArtistLabel {...props} />,
  },
  {
    label: 'Owner',
    name: 'owner',
    renderInput: (props) => <OwnerInput {...props} />,
    renderValueLabel: (props) => <OwnerLabel {...props} />,
  },
  {
    label: 'Region',
    name: 'region',
    renderInput: (props) => <RegionInput {...props} />,
    renderValueLabel: (props) => <RegionLabel {...props} />,
  },
]

export default options
