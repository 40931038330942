import React from 'react'

import Select from 'components/Select'

import { useGenres } from 'hooks/genres/useGenres'

const SelectGenres = ({ name, label, placeholder, ...rest }) => {
  const { genres, isLoading: isLoadingGenres } = useGenres({ mapById: true })

  const genresOptions = genres ? Object.keys(genres) : []

  return (
    <Select
      name={name}
      label={label}
      placeholder={placeholder}
      options={genresOptions}
      disabled={isLoadingGenres}
      getOptionLabel={(genreId) => genres?.[genreId]?.name}
      {...rest}
    />
  )
}

export default SelectGenres
