import yup from './yup'

const userSchema = (isNew) =>
  yup.object({
    name: yup.string('Enter name').required('Name name is required'),
    username: yup
      .string('Enter username')
      .required('Username name is required'),
    email: yup
      .string('Enter email address')
      .email('Invalid email address')
      .required('Email address name is required'),
    birthDate: yup
      .date('Enter your birth date')
      .required('Birth date is required'),
    phoneNumber: yup
      .string('Enter your phone number')
      .phoneNumber('Invalid phone number'),
    role: yup
      .string('Enter user role')
      .required('User role is required')
      .nullable(),
    ...(isNew && {
      password: yup
        .string('Enter password')
        .required('Password name is required'),
    }),
  })

export default userSchema
