import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import App from './App'
import theme from './theme'
import SWRWrapper from 'hooks/SWRWrapper'
import { AuthProvider } from 'hooks/useAuthContext'
import { SnackbarProvider } from 'hooks/useSnackbar'
import { EventStreamProvider } from 'hooks/useEventStream'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <SWRWrapper>
          <BrowserRouter>
            <SnackbarProvider>
              <AuthProvider>
                <EventStreamProvider>
                  <App />
                </EventStreamProvider>
              </AuthProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </SWRWrapper>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
