import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

import Menu from '../controls/Menu'
import ListItem from '../controls/ListItem'
import MenuItem from '../controls/MenuItem'

import { useDemo } from 'hooks/demo/useDemo'
import { useEventStream } from 'hooks/useEventStream'
import { useRegionById, useRegionsByParentId } from 'hooks/regions/useRegions'

const useStyles = makeStyles((theme) => ({
  loader: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const RegionListItem = ({ regionIndex, placeholder }) => {
  const classes = useStyles()
  const { addClickEvent } = useEventStream()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    currentRegionsIds,
    setCurrentRegion,
    currentRegion,
    setFreezeOverlay,
    isLoadingCurrentRegion,
  } = useDemo()

  const parentRegionId = placeholder
    ? currentRegionsIds.at(-1)
    : currentRegionsIds[regionIndex - 1]
  const { regions: brotherRegions, isLoading: isLoadingBrotherRegions } =
    useRegionsByParentId(parentRegionId)

  const filteredBrotherRegions = useMemo(
    () => brotherRegions?.filter((region) => region._id !== currentRegion?._id),
    [brotherRegions, currentRegion]
  )

  const regionId = currentRegionsIds[regionIndex]
  const isCurrentRegion = regionId && currentRegion?._id === regionId

  const { region, isLoading } = useRegionById(regionId)
  const label = placeholder ? 'Select...' : region?.name

  const handleSubregionsMenuClick = (event) => {
    if (regionIndex === 0) {
      setCurrentRegion(regionId, regionId)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSubregionClick = (newRegionId) => () => {
    setCurrentRegion(regionId, newRegionId)
    handleCloseMenu()
  }

  useEffect(() => {
    setFreezeOverlay(!!anchorEl)
  }, [anchorEl, setFreezeOverlay])

  const showLoader =
    placeholder && (isLoadingCurrentRegion || isLoadingBrotherRegions)

  if (showLoader) {
    return (
      <CircularProgress
        className={classes.loader}
        color="inherit"
        size="28px"
      />
    )
  }

  if (isLoading || (placeholder && !filteredBrotherRegions?.length)) return null

  return (
    <>
      <ListItem
        button
        selected={isCurrentRegion}
        onClick={handleSubregionsMenuClick}
      >
        {label}
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.regionsMenu }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {filteredBrotherRegions?.map((subRegion) => (
          <MenuItem
            id={`subregionsMenuButton_${subRegion._id}_${subRegion.name}`}
            key={subRegion._id}
            classes={{
              root: classes.regionsMenuItem,
            }}
            onClick={handleSubregionClick(subRegion._id)}
            onClickCapture={addClickEvent}
          >
            {subRegion.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default RegionListItem
