import React, { useState } from 'react'

import { Switch, Tooltip } from '@material-ui/core'

import { updateUser } from 'utils/users'

import { useAuth } from 'hooks/useAuthContext'
import { useUsers } from 'hooks/users/useUsers'
import { useSnackbar } from 'hooks/useSnackbar'

const getLabel = (active) => (active ? 'deactivate' : 'activate')

const ActiveCell = ({ id, active }) => {
  const { popSnackbar } = useSnackbar()
  const { currentUser } = useAuth()
  const { users, mutate: mutateUsers } = useUsers()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleOnChange = async ({ target }) => {
    setTooltipOpen(false)
    setIsSubmitting(true)
    const newActive = target.checked
    const label = getLabel(!newActive)

    try {
      const userToUpdate = {
        _id: id,
        active: newActive,
      }
      const updatedUser = await updateUser(userToUpdate)

      const updatedUsers = users.map((user) => {
        if (user._id === id) {
          return { ...user, active: newActive }
        }
        return user
      })
      mutateUsers(updatedUsers)
      popSnackbar(
        'success',
        `User ${updatedUser.username} successfully ${label}d`
      )
    } catch (error) {
      popSnackbar('error', `Failed to ${label} user`)
    }
    setIsSubmitting(false)
  }

  return (
    <Tooltip
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      title={`${getLabel(active)} user`}
      arrow
    >
      <Switch
        disabled={isSubmitting || currentUser._id === id}
        size="small"
        checked={!!active}
        onChange={handleOnChange}
      />
    </Tooltip>
  )
}

export default ActiveCell
