import yup from './yup'

const clipValidationSchema = yup.object({
  name: yup.string('Enter track name').required('Clip name is required'),
  originalName: yup.string('Enter track original name'),
  youtubeUrl: yup
    .string('Enter track youtube URL')
    .url('Invalid URL')
    .required('Youtube clip URL is required'),
  spotifyUrl: yup.string('Enter track spotify URL').url('Invalid URL'),
  lyrics: yup.string(),
  youtubeViews: yup
    .array()
    .of(
      yup.object().shape({
        date: yup.date().required(),
        views: yup.number().min(0),
      })
    )
    .required()
    .min(0),
  regions: yup
    .array()
    .min(1, 'You must select at least 1 region')
    .required('You must select at least 1 region'),
  contentTypes: yup
    .array()
    .min(1, 'You must select at least 1 content type')
    .required('You must select at least 1 content type'),
  genres: yup
    .array()
    .min(1, 'You must select at least 1 genre')
    .required('You must select at least 1 genre'),
  artists: yup
    .array()
    .of(
      yup
        .object({
          _id: yup.string().required(),
        })
        .nullable()
    )
    .min(1, 'You must select at least 1 artist')
    .required('You must select at least 1 artist'),
  owners: yup
    .array()
    .of(
      yup
        .object({
          _id: yup.string().required(),
        })
        .nullable()
    )
    .min(1, 'You must select at least 1 owner')
    .required('You must select at least 1 owner'),
  composers: yup.array().of(
    yup
      .object({
        _id: yup.string().required(),
      })
      .nullable()
  ),
  authors: yup.array().of(
    yup
      .object({
        _id: yup.string().required(),
      })
      .nullable()
  ),
})

export default clipValidationSchema
