import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@material-ui/core'

import Header from 'components/Header'
import QuerySearch from 'components/QuerySearch'
import ClipsConfirmDeleteDialog from 'components/clips/ClipsConfirmDeleteDialog'
import AddClipsToPlaylistDialog from 'components/clips/AddClipsToPlaylistDialog'

import { useClipsContext } from 'hooks/clips/useClipsContext'

const DIALOGS = {
  ADD_TO_PLAYLIST: 'addToPlaylist',
  DELETE_CLIPS: 'deleteClips',
}

const ClipsHeader = () => {
  const [openDialog, setOpenDialog] = useState(null)
  const { selectedClips, setSelectedClips, mutate } = useClipsContext()

  const handleOnCloseDialog = () => setOpenDialog(null)

  const handleOnChange = () => {
    mutate()
    setSelectedClips([])
  }

  return (
    <>
      <Header title="Clips">
        <QuerySearch />
        <Button
          variant="outlined"
          color="primary"
          disabled={!selectedClips.length}
          onClick={() => setOpenDialog(DIALOGS.ADD_TO_PLAYLIST)}
        >
          Add to playlist
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={!selectedClips.length}
          onClick={() => setOpenDialog(DIALOGS.DELETE_CLIPS)}
        >
          Delete
        </Button>
        <Button
          component={Link}
          to={'/clips/new'}
          variant="contained"
          color="primary"
        >
          Add new clip
        </Button>
      </Header>
      {openDialog === DIALOGS.DELETE_CLIPS && (
        <ClipsConfirmDeleteDialog
          ids={selectedClips}
          onClose={handleOnCloseDialog}
          onDelete={handleOnChange}
          open
        />
      )}
      {openDialog === DIALOGS.ADD_TO_PLAYLIST && (
        <AddClipsToPlaylistDialog
          ids={selectedClips}
          onClose={handleOnCloseDialog}
          onSave={handleOnChange}
          open
        />
      )}
    </>
  )
}

export default ClipsHeader
