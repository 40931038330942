import axios from 'axios'

export const addRecipe = async (recipeData) => {
  const { data } = await axios.post('/api/recipes', recipeData)
  return data
}

export const updateRecipe = async (recipeToUpdate) => {
  const { _id: recipeId } = recipeToUpdate
  const { data: updatedRecipe } = await axios.put(
    `/api/recipes/${recipeId}`,
    recipeToUpdate
  )
  return updatedRecipe
}

export const deleteRecipesByIds = async (recipeIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/recipes', { data: { ids: recipeIds } })
  return deletedCount
}
