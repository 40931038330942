import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useClipsById } from 'hooks/clips/useClips'
import { useEventStream } from 'hooks/useEventStream'
import { useRegionById } from 'hooks/regions/useRegions'
import { usePlaylistsByRegion } from 'hooks/playlists/usePlaylist'

const DemoContext = createContext()

const useCurrentRegions = () => {
  const location = useLocation()
  const regionIds = useMemo(
    () => location.pathname.split('demo/')[1]?.split('/') || [],
    [location.pathname]
  )

  return regionIds
}

const useSetCurrentRegion = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  return useCallback(
    (prevRegion, nextRegion) => {
      const nextPathname =
        pathname.split(prevRegion)[0] + (prevRegion ? '' : '/') + nextRegion
      history.push(nextPathname)
    },
    [history, pathname]
  )
}

const DemoProvider = ({ children }) => {
  const currentRegionsIds = useCurrentRegions()
  const [genresIds, setGenresIds] = useState([])
  const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(0)
  const [currentClipIndex, setCurrentClipIndex] = useState(0)
  const [freezeOverlay, setFreezeOverlay] = useState(false)

  const { addClipViewEvent } = useEventStream()

  const currentRegionId = currentRegionsIds.length && currentRegionsIds.at(-1)
  const { region: currentRegion, isLoading: isLoadingCurrentRegion } =
    useRegionById(currentRegionId)
  const { playlists: playlistsInCurrentRegion } = usePlaylistsByRegion(
    currentRegionId,
    genresIds
  )

  useEffect(() => {
    setCurrentClipIndex(0)
    setCurrentPlaylistIndex(0)
  }, [currentRegionId, genresIds])

  const currentPlaylist = useMemo(
    () =>
      playlistsInCurrentRegion &&
      playlistsInCurrentRegion[currentPlaylistIndex],
    [currentPlaylistIndex, playlistsInCurrentRegion]
  )

  const currentClipId = currentPlaylist?.clips?.at(currentClipIndex)
  const { clip: currentClip, isLoading: isLoadingCurrentClip } =
    useClipsById(currentClipId)

  const currentPlaylistClipCount = currentPlaylist?.clips?.length || 0
  const currentRegionPlaylistCount = playlistsInCurrentRegion?.length || 0

  const nextClip = useCallback(() => {
    const isLastClipInPlaylist =
      currentClipIndex === currentPlaylistClipCount - 1

    if (isLastClipInPlaylist) {
      const isLastPlaylistInRegion =
        currentPlaylistIndex === currentRegionPlaylistCount - 1

      const nextPlaylistIndex = isLastPlaylistInRegion
        ? 0
        : currentPlaylistIndex + 1

      setCurrentPlaylistIndex(nextPlaylistIndex)
      setCurrentClipIndex(0)
    } else {
      setCurrentClipIndex((prev) => prev + 1)
    }
  }, [
    currentClipIndex,
    currentPlaylistIndex,
    currentPlaylistClipCount,
    currentRegionPlaylistCount,
  ])

  const prevClip = useCallback(() => {
    const isFirstClipInPlaylist = currentClipIndex === 0

    if (isFirstClipInPlaylist) {
      const isFirstPlaylistInRegion = currentPlaylistIndex === 0

      const prevPlaylistIndex = isFirstPlaylistInRegion
        ? currentRegionPlaylistCount - 1
        : currentPlaylistIndex - 1

      setCurrentPlaylistIndex(prevPlaylistIndex)
      const prevPlaylistClipCount =
        playlistsInCurrentRegion[prevPlaylistIndex].clips.length

      setCurrentClipIndex(prevPlaylistClipCount - 1)
    } else {
      setCurrentClipIndex((prev) => prev - 1)
    }
  }, [
    currentClipIndex,
    currentPlaylistIndex,
    playlistsInCurrentRegion,
    currentRegionPlaylistCount,
  ])

  useEffect(() => {
    if (currentClipId && currentPlaylist?._id && currentRegionId) {
      addClipViewEvent(currentClipId, currentPlaylist?._id, currentRegionId)
    }
  }, [addClipViewEvent, currentClipId, currentPlaylist?._id, currentRegionId])

  const hasNext = currentRegionPlaylistCount > 1 || currentPlaylistClipCount > 1

  const setCurrentRegion = useSetCurrentRegion()

  const value = {
    genresIds,
    setGenresIds,
    currentRegionsIds,
    setCurrentRegion,
    currentClip,
    setCurrentClipIndex,
    isLoadingCurrentClip,
    hasNext,
    nextClip,
    prevClip,
    currentRegion,
    currentPlaylist,
    setCurrentPlaylistIndex,
    playlistsInCurrentRegion,
    freezeOverlay,
    setFreezeOverlay,
    isLoadingCurrentRegion,
  }

  return <DemoContext.Provider value={value}>{children}</DemoContext.Provider>
}

const useDemo = () => {
  const context = useContext(DemoContext)
  if (context === undefined) {
    throw new Error('useDemo must be used within a DemoProvider')
  }
  return context
}

export { DemoProvider, useDemo }
