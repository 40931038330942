import React from 'react'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core'

import TextInput from 'components/TextInput'

import { useAuth } from 'hooks/useAuthContext'
import { useSnackbar } from 'hooks/useSnackbar'

import loginSchema from 'schemas/login'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
    },
  },
  fieldsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: 0,
      width: '100%',
    },
  },
  registerButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    textDecoration: 'none',
  },
  forgotPasswordButton: {
    alignSelf: 'center',
    textDecoration: 'none',
    marginTop: theme.spacing(2),
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
}))

const initialValues = {
  username: '',
  password: '',
}

const Login = () => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const { login, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress />
      </Backdrop>
    )
  }

  const handleSubmit = async ({ username, password }) => {
    const { authenticated } = await login(username, password)
    if (!authenticated)
      popSnackbar('error', 'Wrong username/password. Please try again')
  }

  return (
    <Container className={classes.formContainer} component="main" maxWidth="xs">
      <Formik
        isInitialValid={false}
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Typography variant="h2" fontWeight="bold">
              Login
            </Typography>
            <div className={classes.fieldsContainer}>
              <TextInput name="username" label="Username" required fullWidth />
              <TextInput
                name="password"
                label="Password"
                type="password"
                required
                fullWidth
              />
            </div>
            <Button
              disabled={!formik.isValid || formik.isSubmitting}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Submit
            </Button>
            <Link
              className={classes.forgotPasswordButton}
              to="/forgot-password"
            >
              Forgot password
            </Link>
            <Link className={classes.registerButton} to="/register">
              Request access
            </Link>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Login
