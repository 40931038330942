import axios from 'axios'

export const addPlaylist = async (playlistData) => {
  const { data } = await axios.post('/api/playlists', playlistData)
  return data
}

export const updatePlaylist = async (playlistToUpdate) => {
  const { _id: playlistId } = playlistToUpdate
  const { data: updatedPlaylist } = await axios.put(
    `/api/playlists/${playlistId}`,
    playlistToUpdate
  )
  return updatedPlaylist
}

export const updatePlaylistClipsOrder = async (
  playlistId,
  origin,
  destination
) => {
  const { data: updatedPlaylist } = await axios.patch(
    `/api/playlists/${playlistId}/clips/reorder`,
    { origin, destination }
  )
  return updatedPlaylist
}

export const removeClipFromPlaylist = async (playlistId, index) => {
  const { data: updatedPlaylist } = await axios.delete(
    `/api/playlists/${playlistId}/clip/${index}`
  )
  return updatedPlaylist
}

export const deletePlaylistsByIds = async (playlistIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/playlists', { data: { ids: playlistIds } })
  return deletedCount
}

export const setPlaylistsStatusByIds = async (playlistIds, status) => {
  const {
    data: { updatedCount },
  } = await axios.patch('/api/playlists/status', { ids: playlistIds, status })
  return updatedCount
}

export const addClipsToPlaylist = async (playlistId, clips) => {
  const { data } = await axios.patch(`/api/playlists/${playlistId}/clips/add`, {
    clipIds: clips,
  })
  return data
}
