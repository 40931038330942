import { List, withStyles } from '@material-ui/core'

export default withStyles((theme) => ({
  root: {
    borderRadius: 30,
    backgroundColor: theme.palette.controls.background,
    backdropFilter: 'blur(15px)',
    display: 'flex',
    alignItems: 'start',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
}))(List)
