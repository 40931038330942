import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'

import Thumbnail from 'components/Thumbnail'
import StatusChip from 'components/playlist/StatusChip'

import { useGenres } from 'hooks/genres/useGenres'
import { useRegions } from 'hooks/regions/useRegions'

const usePlaylistsColumns = (columnNames) => {
  const { regions: regionsOptions } = useRegions({ mapById: true })
  const { genres: genresOptions } = useGenres({ mapById: true })

  return useMemo(() => {
    let columns = [
      {
        name: 'index',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue: ({ index }) => index || '',
      },
      { name: '_id', title: 'ID' },
      {
        name: 'thumbnail',
        title: 'Thumbnail',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ thumbnail, _id }) {
          return (
            <Link to={`/playlists/${_id}`}>
              <Thumbnail src={thumbnail} width="100px" sqaured />
            </Link>
          )
        },
      },
      {
        name: 'smallThumbnail',
        title: '\x09',
        width: 56,
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ thumbnail }) {
          return <Thumbnail src={thumbnail} height="25px" sqaured />
        },
      },
      {
        name: 'name',
        title: 'Playlist Name',
        getCellValue({ name, _id }) {
          return (
            <MuiLink component={Link} size="small" to={`/playlists/${_id}`}>
              {name}
            </MuiLink>
          )
        },
      },
      {
        name: 'editors',
        title: 'Editors',
        getCellValue: ({ editors }) =>
          editors.map((editor) => editor?.name).join(', '),
      },
      {
        name: 'genres',
        title: 'Genres',
        getCellValue: ({ genres }) => {
          if (!genresOptions) return ''

          return genres
            .reduce((acc, genreId) => {
              const regionName = genresOptions[genreId]?.name
              if (!regionName) return acc
              return [...acc, regionName]
            }, [])
            .join(', ')
        },
      },
      {
        name: 'regions',
        title: 'Regions',
        getCellValue: ({ regions }) => {
          if (!regionsOptions || !regions) return ''

          const regionNames = regions.reduce((acc, regionId) => {
            const regionName = regionsOptions[regionId]?.name
            return regionName ? [...acc, regionName] : acc
          }, [])

          return regionNames.join(', ')
        },
      },
      {
        name: 'updatedAt',
        title: 'Last edited',
        getCellValue: ({ updatedAt }) =>
          updatedAt && moment.utc(updatedAt).format('DD/MM/YYYY'),
      },
      {
        name: 'createdAt',
        title: 'Created at',
        getCellValue: ({ createdAt }) =>
          createdAt && moment.utc(createdAt).format('DD/MM/YYYY'),
      },
      {
        name: 'clipCount',
        title: 'Clip count',
        getCellValue: ({ clips }) => clips?.length || 0,
      },
      {
        name: 'totalDuration',
        title: 'Total duration',
        getCellValue: ({ totalDuration }) =>
          moment.utc(totalDuration || 0).format('HH:mm:ss'),
      },
      {
        name: 'status',
        fixed: 'right',
        width: 100,
        title: 'Status',
        align: 'center',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ status }) {
          return <StatusChip status={status} />
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, genresOptions, regionsOptions])
}

export default usePlaylistsColumns
