import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { Alert } from '@material-ui/lab'
import { Button, Container, makeStyles, Typography } from '@material-ui/core'

import TextInput from 'components/TextInput'
import DateInput from 'components/DateInput'
import PhoneNumberInput from 'components/PhoneNumberInput'

import { register } from 'utils/users'
import { useSnackbar } from 'hooks/useSnackbar'

import registerSchema from 'schemas/register'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
    },
  },
  fieldsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: 0,
      width: '100%',
    },
  },
  registerButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    textDecoration: 'none',
  },
  successAlert: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const initialValues = {
  name: '',
  email: '',
  password: '',
  username: '',
  phoneNumber: '',
  birthDate: null,
  confirmPassword: '',
}

const Register = () => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const [success, setSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (values) => {
    setIsSubmitting(true)
    try {
      // eslint-disable-next-line no-unused-vars
      const { confirmPassword, ...userToRegister } = values
      await register(userToRegister)

      setSuccess(true)
    } catch (error) {
      if (error.response.status === 422) {
        popSnackbar('error', error.response.data.message)
      } else {
        popSnackbar('error', 'Could not register user')
      }
    }

    setIsSubmitting(false)
  }

  return (
    <Container className={classes.formContainer} component="main" maxWidth="xs">
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={registerSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h2"
              fontWeight="bold"
              classes={{ root: classes.title }}
            >
              Request access
            </Typography>
            <Typography variant="body1">
              You can make a request to join the community
            </Typography>
            {success ? (
              <Alert className={classes.successAlert} severity="success">
                Successfully requested to join.
              </Alert>
            ) : (
              <>
                <div className={classes.fieldsContainer}>
                  <TextInput name="name" label="Name" required fullWidth />
                  <TextInput
                    name="username"
                    label="Username"
                    required
                    fullWidth
                  />
                  <TextInput
                    name="email"
                    label="Email Address"
                    required
                    fullWidth
                  />
                  <TextInput
                    name="password"
                    label="Password"
                    type="password"
                    required
                    fullWidth
                  />
                  <TextInput
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    required
                    fullWidth
                  />
                  <DateInput name="birthDate" label="Birth Date" required />
                  <PhoneNumberInput
                    name="phoneNumber"
                    label="Phone Number"
                    required
                  />
                </div>
                <Button
                  disabled={!formik.isValid || isSubmitting || success}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </>
            )}
            <Link className={classes.registerButton} to="/login">
              I already have an account
            </Link>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Register
