import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useArtists } from 'hooks/artists/useArtists'
import { useSnackbar } from 'hooks/useSnackbar'

import { deleteArtistsByIds } from 'utils/artists'

const ArtistsConfirmDeleteDialog = ({ ids, open, onDelete, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateArtists } = useArtists()

  const handleDelete = async () => {
    try {
      await deleteArtistsByIds(ids)
      mutateArtists()
      popSnackbar('success', 'Artist deleted successfuly')
      onDelete ? onDelete() : onClose()
    } catch (error) {
      popSnackbar('error', 'Could not deleted artist')
    }
  }

  return (
    <ConfirmDialog
      title="Delete artists"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these artists?
    </ConfirmDialog>
  )
}

export default ArtistsConfirmDeleteDialog
