import yup from './yup'
import link from './link'
import contact from './contact'

const artistValidationSchema = yup.object({
  name: yup.string('Enter name').required('Name is required'),
  user: yup
    .object({
      _id: yup.string(),
    })
    .nullable()
    .default(undefined),
  email: yup.string('Enter email').email('Invalid email'),
  phoneNumber: yup.string().phoneNumber('Invalid phone number'),
  links: yup.array().of(link),
  contacts: yup.array().of(contact),
  playlist: yup
    .object({
      _id: yup.string(),
    })
    .nullable()
    .default(undefined)
    .required(),
})

export default artistValidationSchema
