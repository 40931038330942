import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'

import SelectOwners from 'components/SelectOwners'
import AddOwnerDialog from 'components/admin/owners/AddOwnerDialog'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  addButton: {
    marginTop: theme.spacing(5),
    height: theme.spacing(5),
    whiteSpace: 'nowrap',
  },
}))

const Owners = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className={classes.container}>
        <SelectOwners
          name="owners"
          label="Owners"
          placeholder="Select one owner or more"
          multiple
          fullWidth
          getOptionLabel={(owner) => owner.name}
          required
        />
        <Button
          className={classes.addButton}
          color="primary"
          variant="outlined"
          onClick={() => setIsOpen(true)}
        >
          Add owner
        </Button>
      </div>
      {isOpen && <AddOwnerDialog open onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default Owners
