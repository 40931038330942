import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const searchParamsObject = {}

    for (let [key, value] of searchParams) {
      searchParamsObject[key] = value
    }

    return searchParamsObject
  }, [search])
}

export default useQuery
