import React, { useState } from 'react'
import { useField } from 'formik'
import moment from 'moment'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      flex: 1,
      marginRight: theme.spacing(1),
    },
  },
}))

const formatOptions = {
  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
}

const TimeIntervalInput = ({ name, label, required, helperText, ...props }) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField(name)

  const [format, setFormat] = useState('minutes')
  const [value, setValue] = useState(moment.duration(meta.value).as('minutes'))

  const error = meta.touched && meta.error

  const handleOnChangeFormat = (event) => {
    const newFormat = event.target.value
    setFormat(newFormat)
    setValue(moment.duration(meta.value).as(newFormat))
  }

  const handleOnChangeValue = (event) => {
    const newValue = event.target.value
    setValue(+newValue)

    const newValueInMs = Math.ceil(moment.duration(newValue, format))
    helpers.setValue(newValueInMs).then(() => helpers.setTouched(true))
  }

  return (
    <FormControl required={required} margin="normal" error={!!error}>
      <FormLabel>{label}</FormLabel>
      <div className={classes.inputsWrapper}>
        <TextField
          size="small"
          margin="dense"
          type="number"
          variant="outlined"
          {...field}
          {...props}
          value={value}
          onChange={handleOnChangeValue}
        />
        <TextField
          select
          variant="outlined"
          size="small"
          margin="dense"
          value={format}
          onChange={handleOnChangeFormat}
        >
          {Object.keys(formatOptions).map((key) => (
            <MenuItem key={key} value={key}>
              {formatOptions[key]}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default TimeIntervalInput
