import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

import PlaylistClips from 'components/playlist/PlaylistClips'
import ArtistPageHeader from 'components/artist/ArtistPageHeader'

import { useArtistContext } from 'hooks/artists/useArtistContext'
import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const useStyles = makeStyles(() => ({
  loaderContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const HEADER_HEIGHT = 400

const ArtistPageContent = () => {
  const classes = useStyles()
  const { isLoadingArtist } = useArtistContext()
  const { isLoadingPlaylist } = usePlaylistContext()

  if (isLoadingArtist || isLoadingPlaylist) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <PlaylistClips
      topChildren={<ArtistPageHeader height={HEADER_HEIGHT} />}
      topChildrenHeight={HEADER_HEIGHT}
    />
  )
}

export default ArtistPageContent
