import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem } from '@material-ui/core'

import ArtistDialog from 'components/artist/ArtistDialog'
import ArtistsConfirmDeleteDialog from 'components/artists/ArtistsConfirmDeleteDialog'

import { useArtistContext } from 'hooks/artists/useArtistContext'

const dialogs = {
  EDIT_DIALOG: 'edit',
  DELETE_DIALOG: 'delete',
}

const OptionsMenu = ({ anchorEl, onClose }) => {
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(null)
  const { artistId } = useArtistContext()

  const handleOnClose = () => {
    setOpenDialog(null)
    onClose()
  }

  const handleOnClickEdit = () => setOpenDialog(dialogs.EDIT_DIALOG)

  const handleOnClickDelete = () => setOpenDialog(dialogs.DELETE_DIALOG)

  const handleOnDelete = () => history.push('/artists')

  return (
    <>
      <Menu
        id="artist-options"
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
      >
        <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
        <MenuItem onClick={handleOnClickDelete}>Delete</MenuItem>
      </Menu>
      {openDialog === dialogs.DELETE_DIALOG && (
        <ArtistsConfirmDeleteDialog
          open
          ids={[artistId]}
          onClose={handleOnClose}
          onDelete={handleOnDelete}
        />
      )}
      {openDialog === dialogs.EDIT_DIALOG && (
        <ArtistDialog id={artistId} open onClose={handleOnClose} />
      )}
    </>
  )
}

export default OptionsMenu
