import React from 'react'
import { Formik } from 'formik'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import TextInput from 'components/TextInput'

import { useSnackbar } from 'hooks/useSnackbar'
import { useArtists } from 'hooks/artists/useArtists'

import { addArtist, initialArtist } from 'utils/artists'

import artistValidationSchema from 'schemas/artist'

const AddArtistDialog = ({ open, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateArtists } = useArtists()

  const handleOnSubmit = async (artistData) => {
    try {
      await addArtist(artistData)
      mutateArtists()
      popSnackbar('success', 'Successfully added artist')
      onClose()
    } catch (error) {
      popSnackbar('error', 'Failed to save artist')
    }
  }

  return (
    <Formik
      initialValues={initialArtist}
      isInitialValid={false}
      validationSchema={artistValidationSchema}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {(formik) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Add artist</DialogTitle>
          <DialogContent>
            <TextInput
              name="name"
              label="Artist name"
              placeholder="Enter artist name in english"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={formik.submitForm}
              disabled={!formik.isValid}
              color="primary"
              variant="contained"
            >
              Add artist
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default AddArtistDialog
