import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'

import Thumbnail from 'components/Thumbnail'
import OptionsCell from 'components/table/clips/OptionsCell'

import { useGenres } from 'hooks/genres/useGenres'
import { useRegions } from 'hooks/regions/useRegions'

const useClipsColumns = (columnNames) => {
  const { genres: genresOptions } = useGenres({ mapById: true })
  const { regions: regionsMappedById } = useRegions({ mapById: true })

  return useMemo(() => {
    let columns = [
      {
        name: 'index',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue: ({ index }) => index || '',
      },
      {
        name: 'thumbnail',
        title: 'Thumbnail',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ thumbnailUrl, _id }) {
          return (
            <Link to={`/clips/${_id}`}>
              <Thumbnail src={thumbnailUrl} height="100px" sqaured />
            </Link>
          )
        },
      },
      {
        name: 'smallThumbnail',
        title: '\x09',
        width: 56,
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ thumbnailUrl }) {
          return <Thumbnail src={thumbnailUrl} height="25px" sqaured />
        },
      },
      { name: '_id', title: 'ID' },
      {
        name: 'name',
        title: 'Name',
        getCellValue({ name, _id }) {
          return (
            <MuiLink component={Link} size="small" to={`/clips/${_id}`}>
              {name}
            </MuiLink>
          )
        },
      },
      { name: 'originalName', title: 'Original Name' },
      {
        name: 'artists',
        title: 'Artists',
        getCellValue: ({ artists }) => {
          if (!artists) return ''

          return artists.map((artist, index) => {
            return (
              <>
                <Link
                  key={artist.id}
                  component={MuiLink}
                  to={`/artists/${artist._id}`}
                >
                  {artist.name}
                </Link>
                {index < artists.length - 1 && ', '}
              </>
            )
          })
        },
      },
      {
        name: 'owners',
        title: 'Owners',
        getCellValue: ({ owners }) => {
          if (!owners) return ''

          return owners.map((artist, index) => {
            return (
              <>
                <Link
                  key={artist.id}
                  component={MuiLink}
                  to={`/admin/owners/${artist._id}`}
                >
                  {artist.name}
                </Link>
                {index < owners.length - 1 && ', '}
              </>
            )
          })
        },
      },
      {
        name: 'genres',
        title: 'Genres',
        getCellValue: ({ genres }) => {
          if (!genresOptions) return ''

          return genres
            .reduce((acc, genreId) => {
              const regionName = genresOptions[genreId]?.name
              if (!regionName) return acc
              return [...acc, regionName]
            }, [])
            .join(', ')
        },
      },
      {
        name: 'regions',
        title: 'Regions',
        getCellValue: ({ regions }) => {
          if (!regionsMappedById || !regions) return ''

          const regionNames = regions.reduce((acc, regionId) => {
            const regionName = regionsMappedById[regionId]?.name
            return regionName ? [...acc, regionName] : acc
          }, [])

          return regionNames.join(', ')
        },
      },
      {
        name: 'contentTypes',
        title: 'Content Types',
        getCellValue: ({ contentTypes }) => contentTypes?.join(', ') || '',
      },
      { name: 'playlistCount', title: '# of related Playlists' },
      {
        name: 'duration',
        title: 'Duration',
        getCellValue: ({ duration }) => moment.utc(duration).format('mm:ss'),
      },
      {
        name: 'updatedAt',
        title: 'Last edited',
        getCellValue: ({ updatedAt }) =>
          updatedAt && moment.utc(updatedAt).format('DD/MM/YYYY'),
      },
      {
        name: 'createdAt',
        title: 'Created at',
        getCellValue: ({ createdAt }) =>
          createdAt && moment.utc(createdAt).format('DD/MM/YYYY'),
      },
      { name: 'youtubeUrl', title: 'URL' },
      {
        name: 'options',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        width: 44,
        fixed: 'right',
        getCellValue({ _id }) {
          return <OptionsCell id={_id} />
        },
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, genresOptions, regionsMappedById])
}

export default useClipsColumns
