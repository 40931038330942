import React, { useState } from 'react'
import { makeStyles, Button } from '@material-ui/core'

import Header from 'components/Header'
import PermissionDialog from 'components/admin/permissions/PermissionDialog'
import PermissionsTable from 'components/admin/permissions/PermissionsTable'
import PermissionConfirmDeleteDialog from 'components/admin/permissions/PermissionConfirmDeleteDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const DIALOG_OPTIONS = {
  DELETE_PERMISSIONS: 'DELETE_PERMISSIONS',
  ADD_PERMISSION: 'ADD_PERMISSION',
}

const Permissions = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState('')
  const [selection, setSelection] = useState([])

  const handleOnDelete = () => {
    setOpenDialog(DIALOG_OPTIONS.DELETE_PERMISSIONS)
  }

  const handleOnAdd = () => {
    setOpenDialog(DIALOG_OPTIONS.ADD_PERMISSION)
  }

  const handleOnClose = () => {
    setOpenDialog('')
  }

  return (
    <div className={classes.root}>
      <Header title="Permissions">
        <Button
          variant="outlined"
          color="secondary"
          disabled={!selection.length}
          onClick={handleOnDelete}
        >
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleOnAdd}>
          Add new permission
        </Button>
      </Header>
      <PermissionsTable
        selection={selection}
        onSelectionChange={setSelection}
      />
      <PermissionConfirmDeleteDialog
        ids={selection}
        onClose={handleOnClose}
        onDelete={() => {
          setSelection([])
          handleOnClose()
        }}
        open={openDialog === DIALOG_OPTIONS.DELETE_PERMISSIONS}
      />
      {openDialog === DIALOG_OPTIONS.ADD_PERMISSION && (
        <PermissionDialog open onClose={handleOnClose} />
      )}
    </div>
  )
}

export default Permissions
