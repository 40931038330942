import React from 'react'
import { makeStyles } from '@material-ui/core'

import PlaylistsTable from 'components/playlists/PlaylistsTable'
import PlaylistsHeader from 'components/playlists/PlaylistsHeader'

import { PlaylistsProvider } from 'hooks/playlists/usePlaylistsContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Playlists = () => {
  const classes = useStyles()

  return (
    <PlaylistsProvider>
      <div className={classes.root}>
        <PlaylistsHeader />
        <PlaylistsTable />
      </div>
    </PlaylistsProvider>
  )
}

export default Playlists
