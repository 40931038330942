import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

import Header from 'components/Header'
import PlaylistsSetStatusDialog from 'components/playlists/PlaylistsSetStatusDialog'
import PlaylistsConfirmDeleteDialog from 'components/playlists/PlaylistsConfirmDeleteDialog'

import { useAuth } from 'hooks/useAuthContext'
import { usePlaylistsContext } from 'hooks/playlists/usePlaylistsContext'

import { addPlaylist } from 'utils/playlists'

const DIALOGS = {
  DELETE: 'deletePlaylists',
  SET_STATUS: 'setPlaylistsStatus',
}

const PlaylistsHeader = () => {
  const history = useHistory()
  const { currentUser } = useAuth()
  const [openDialog, setOpenDialog] = useState(null)
  const { selectedPlaylists, setSelectedPlaylists, mutate } =
    usePlaylistsContext()

  const handleOnClickCreatePlaylist = async () => {
    const initialPlaylist = {
      name: 'My Playlist',
      status: 'draft',
      editors: [currentUser],
    }
    const newPlaylist = await addPlaylist(initialPlaylist)
    history.push(`/playlists/${newPlaylist._id}`)
  }

  const handleOnCloseDialog = () => setOpenDialog(null)

  const handleOnChange = () => {
    mutate()
    setSelectedPlaylists([])
    setOpenDialog(null)
  }

  return (
    <>
      <Header title="Playlists">
        <Button
          disabled={!selectedPlaylists.length}
          variant="outlined"
          onClick={() => setOpenDialog(DIALOGS.SET_STATUS)}
        >
          Set status
        </Button>
        <Button
          disabled={!selectedPlaylists.length}
          variant="outlined"
          color="secondary"
          onClick={() => setOpenDialog(DIALOGS.DELETE)}
        >
          Delete
        </Button>
        <Button
          onClick={handleOnClickCreatePlaylist}
          variant="contained"
          color="primary"
        >
          Create Playlist
        </Button>
      </Header>
      {openDialog === DIALOGS.DELETE && (
        <PlaylistsConfirmDeleteDialog
          open
          ids={selectedPlaylists}
          onClose={handleOnCloseDialog}
          onDelete={handleOnChange}
        />
      )}
      {openDialog === DIALOGS.SET_STATUS && (
        <PlaylistsSetStatusDialog
          open
          ids={selectedPlaylists}
          onClose={handleOnCloseDialog}
          onUpdate={handleOnChange}
        />
      )}
    </>
  )
}

export default PlaylistsHeader
