import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'

import Select from 'components/Select'
import { useArtists } from 'hooks/artists/useArtists'

const LIMIT = 10
const DEBOUNCE = 500

const SelectArtists = ({ name, label, ...props }) => {
  const history = useHistory()
  const [inputValue, setInputValue] = useState('')
  const [debouncedName] = useDebounce(inputValue, DEBOUNCE)

  const { artists } = useArtists({ name: debouncedName, limit: LIMIT })

  const artistsOptions = artists || []

  return (
    <Select
      name={name}
      label={label}
      onInputChange={(_, value) => setInputValue(value)}
      placeholder={`Select one ${name} or more`}
      multiple
      autoSelect
      getOptionSelected={(option, value) => value._id === option._id}
      options={artistsOptions}
      getOptionLabel={(value) => value.name}
      onClickTag={(value) => history.push(`/artists/${value._id}`)}
      {...props}
    />
  )
}

export default SelectArtists
