import React, { useMemo } from 'react'

import { useGenres } from 'hooks/genres/useGenres'
import EditCell from 'components/table/genres/EditCell'

const useRegionsColumns = (columnNames) => {
  const { genres } = useGenres({ mapById: true })

  return useMemo(() => {
    let columns = [
      {
        name: 'index',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue: ({ index }) => index || '',
      },
      {
        name: 'edit',
        width: 44,
        fixed: 'left',
        title: '\x09',
        groupingEnabled: false,
        sortingEnabled: false,
        getCellValue({ _id }) {
          return <EditCell id={_id} />
        },
      },
      { name: '_id', title: 'ID' },
      { name: 'name', title: 'Genre name' },
      { name: 'playlistCount', title: '# of Related Playlists' },
      { name: 'clipCount', title: '# of Related Clips' },
      {
        name: 'relatedGenres',
        title: 'Related Genres',
        getCellValue: ({ relatedGenres }) =>
          relatedGenres.map((id) => genres && genres[id]?.name).join(', ') ||
          '-',
      },
    ]

    if (columnNames?.length) {
      columns = columns.filter(({ name }) => columnNames.includes(name))
    }

    return columns
  }, [columnNames, genres])
}

export default useRegionsColumns
