import React, { useMemo, useState } from 'react'
import { makeStyles, Button } from '@material-ui/core'

import Header from 'components/Header'
import UsersTable from 'components/admin/users/UsersTable'
import UserDialog from 'components/admin/users/UserDialog'
import UsersConfirmDeleteDialog from 'components/admin/users/UsersConfirmDeleteDialog'

import { useAuth } from 'hooks/useAuthContext'
import { useUsersContext } from 'hooks/users/useUsersContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const dialogs = {
  ADD_USER: 'addUser',
  DELETE_USERS: 'deleteUsers',
}

const Users = () => {
  const classes = useStyles()
  const { currentUser } = useAuth()
  const { selectedUsers, setSelectedUsers } = useUsersContext()
  const [openDialog, setOpenDialog] = useState('')

  const usersToDelete = useMemo(
    () => selectedUsers.filter((user) => user !== currentUser?._id),
    [currentUser?._id, selectedUsers]
  )

  return (
    <div className={classes.root}>
      <Header title="Users">
        <Button
          variant="outlined"
          color="secondary"
          disabled={!usersToDelete.length}
          onClick={() => setOpenDialog(dialogs.DELETE_USERS)}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(dialogs.ADD_USER)}
        >
          Add new user
        </Button>
      </Header>
      {openDialog === dialogs.ADD_USER && (
        <UserDialog open onClose={() => setOpenDialog('')} />
      )}
      {openDialog === dialogs.DELETE_USERS && (
        <UsersConfirmDeleteDialog
          open
          ids={usersToDelete}
          onDelete={() => {
            setSelectedUsers([])
            setOpenDialog('')
          }}
          onClose={() => setOpenDialog('')}
        />
      )}
      <UsersTable />
    </div>
  )
}

export default Users
