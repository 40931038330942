import React, { useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import {
  SearchState,
  PagingState,
  SortingState,
  CustomPaging,
  GroupingState,
  SelectionState,
  IntegratedSorting,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  Toolbar,
  PagingPanel,
  SearchPanel,
  ColumnChooser,
  GroupingPanel,
  TableGroupRow,
  TableSelection,
  TableHeaderRow,
  DragDropProvider,
  TableFixedColumns,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'

const DEFAULT_PAGE_SIZES = [25, 50, 100]
export const DEFAULT_TABLE_PAGE_SIZE = DEFAULT_PAGE_SIZES[1]

const TrippiclipsTable = ({
  rows,
  columns,
  pageSize,
  selection,
  totalCount,
  searchValue,
  currentPage,
  onChangePageSize,
  onSelectionChange,
  onChangeSearchValue,
  onChangeCurrentPage,
  defaultHiddenColumnNames,
  classes = {},
  hideToolbar = false,
  hideHeaderRow = false,
  hideSelection = false,
  hidePagination = false,
  defaultSorting = [],
  defaultGrouping = [],
}) => {
  const tableColumnExtensions = useMemo(() => {
    return columns.map((column) => ({
      columnName: column.name,
      ...column,
    }))
  }, [columns])

  const { leftColumns, rightColumns } = useMemo(() => {
    return tableColumnExtensions.reduce(
      (acc, curr) => {
        if (curr.fixed === 'right') acc.rightColumns.push(curr.columnName)
        if (curr.fixed === 'left') acc.leftColumns.push(curr.columnName)
        return acc
      },
      { leftColumns: [], rightColumns: [] }
    )
  }, [tableColumnExtensions])

  const handleOnSearchValueChange = (value) => {
    onChangeCurrentPage(0)
    onChangeSearchValue(value)
  }

  return (
    <Paper className={classes.container}>
      <Grid rows={rows} columns={columns} getRowId={({ _id }) => _id}>
        <SearchState
          value={searchValue}
          onValueChange={onChangeSearchValue && handleOnSearchValueChange}
        />
        <DragDropProvider />
        <SortingState
          columnExtensions={tableColumnExtensions}
          defaultSorting={defaultSorting}
        />
        <SelectionState
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
        <GroupingState
          defaultGrouping={defaultGrouping.map((columnName) => ({
            columnName,
          }))}
          columnExtensions={tableColumnExtensions}
        />
        {!hidePagination && (
          <PagingState
            defaultPageSize={DEFAULT_TABLE_PAGE_SIZE}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageSizeChange={onChangePageSize}
            onCurrentPageChange={onChangeCurrentPage}
          />
        )}

        <IntegratedSorting />
        <IntegratedGrouping />
        {!hidePagination && <CustomPaging totalCount={totalCount} />}
        {!searchValue && <IntegratedFiltering />}
        <IntegratedSelection />

        <Table columnExtensions={tableColumnExtensions} />
        {!hideHeaderRow && <TableHeaderRow showSortingControls />}
        {!hideSelection && <TableSelection showSelectAll />}
        <TableGroupRow />
        {!hidePagination && <PagingPanel pageSizes={DEFAULT_PAGE_SIZES} />}

        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <TableFixedColumns
          leftColumns={leftColumns}
          rightColumns={rightColumns}
        />

        {!hideToolbar && <Toolbar />}
        {!hideToolbar && <GroupingPanel showGroupingControls />}
        {!hideToolbar && <SearchPanel />}
        {!hideToolbar && <ColumnChooser />}
      </Grid>
    </Paper>
  )
}

export default TrippiclipsTable
