import React from 'react'

import PlaylistClips from 'components/playlist/PlaylistClips'
import PlaylistPageHeader from 'components/playlist/PlaylistPageHeader'

const HEADER_HEIGHT = 350

const PlaylistPageContent = () => {
  return (
    <PlaylistClips
      topChildren={<PlaylistPageHeader height={HEADER_HEIGHT} />}
      topChildrenHeight={HEADER_HEIGHT}
    />
  )
}

export default PlaylistPageContent
