import React from 'react'

import { Chip } from '@material-ui/core'

import { playlistTypeOptions } from 'consts/playlists'

const playlistTypeChipOptions = {
  [playlistTypeOptions.SMART]: {
    label: 'Smart',
    color: '#1DB954',
  },
  [playlistTypeOptions.ARTIST]: {
    label: 'Artist',
    color: '#ff7700',
  },
}

const StatusChip = ({ type, ...props }) => {
  const option = playlistTypeChipOptions[type]

  if (!option) return null

  const { label, color } = option

  return (
    <Chip
      label={label}
      style={{ color, borderColor: color, marginLeft: 5, marginRight: 5 }}
      size="small"
      variant="outlined"
      {...props}
    />
  )
}

export default StatusChip
