import axios from 'axios'

export const initialRegion = {
  name: '',
  parentRegions: [],
}

export const addRegion = async (regionData) => {
  const { data } = await axios.post('/api/regions', regionData)
  return data
}

export const updateRegion = async (regionToUpdate) => {
  const { _id: regionId } = regionToUpdate
  const { data: updatedRegions } = await axios.put(
    `/api/regions/${regionId}`,
    regionToUpdate
  )
  return updatedRegions
}

export const deleteRegionsByIds = async (regionIds) => {
  const {
    data: { deletedCount },
  } = await axios.delete('/api/regions', { data: { ids: regionIds } })
  return deletedCount
}
