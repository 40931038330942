import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem } from '@material-ui/core'
import PlaylistsConfirmDeleteDialog from 'components/playlists/PlaylistsConfirmDeleteDialog'
import AddClipsToPlaylistDialog from './AddClipsToPlaylistDialog'
import { usePlaylistContext } from 'hooks/playlists/usePlaylistContext'

const dialogs = {
  DELETE_DIALOG: 'delete',
  ADD_CLIPS_DIALOG: 'addClips',
}

const OptionsMenu = ({ anchorEl, onClose }) => {
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState('')
  const { playlistId, mutatePlaylist, loadClips } = usePlaylistContext()

  const handleOnClose = () => {
    setOpenDialog(null)
    onClose()
  }

  const handleOnSave = () => {
    loadClips()
    mutatePlaylist()
  }

  const handleOnDelete = () => {
    history.push('/playlists')
  }

  return (
    <>
      <Menu
        id="playlist-options"
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
      >
        <MenuItem onClick={() => setOpenDialog(dialogs.ADD_CLIPS_DIALOG)}>
          Add clips
        </MenuItem>
        <MenuItem onClick={() => setOpenDialog(dialogs.DELETE_DIALOG)}>
          Delete
        </MenuItem>
      </Menu>
      {openDialog === dialogs.DELETE_DIALOG && (
        <PlaylistsConfirmDeleteDialog
          open
          ids={[playlistId]}
          onClose={handleOnClose}
          onDelete={handleOnDelete}
        />
      )}
      {openDialog === dialogs.ADD_CLIPS_DIALOG && (
        <AddClipsToPlaylistDialog
          open
          playlistId={playlistId}
          onClose={handleOnClose}
          onSave={handleOnSave}
        />
      )}
    </>
  )
}

export default OptionsMenu
