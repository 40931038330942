import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { IconButton, makeStyles } from '@material-ui/core'

import OptionsMenu from 'components/artist/OptionsMenu'

import { useArtistContext } from 'hooks/artists/useArtistContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const ArtistToolbar = () => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)

  const { artistId } = useArtistContext()

  const handleOnClick = (event) => setMenuAnchor(event.currentTarget)

  const handleOnClose = () => setMenuAnchor(null)

  return (
    <>
      <div className={classes.root}>
        <IconButton
          aria-controls="artist-options"
          aria-haspopup="true"
          disableRipple
          onClick={handleOnClick}
        >
          <HiDotsHorizontal />
        </IconButton>
      </div>
      <OptionsMenu
        id={artistId}
        anchorEl={menuAnchor}
        onClose={handleOnClose}
      />
    </>
  )
}

export default ArtistToolbar
