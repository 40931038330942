import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useSnackbar } from 'hooks/useSnackbar'
import { useGenres } from 'hooks/genres/useGenres'

import { deleteGenresByIds } from 'utils/genres'

const GenreConfirmDeleteDialog = ({ ids, open, onClose, onDelete }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateGenres } = useGenres()

  const handleDelete = async () => {
    try {
      await deleteGenresByIds(ids)
      mutateGenres()
      popSnackbar('success', 'Genre deleted successfuly')
      onDelete ? onDelete() : onClose()
    } catch (error) {
      popSnackbar('error', 'Could not deleted genre')
    }
    onClose()
  }

  return (
    <ConfirmDialog
      title="Delete genres"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these genres?
      <br />
      All playlist, clips, and other genres referencing these genres will be
      affected.
    </ConfirmDialog>
  )
}

export default GenreConfirmDeleteDialog
