import React from 'react'
import { Formik } from 'formik'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress,
  Dialog,
} from '@material-ui/core'

import TextInput from 'components/TextInput'
import SelectGenres from 'components/admin/genres/SelectGenres'

import { useSnackbar } from 'hooks/useSnackbar'
import { useGenreById, useGenres } from 'hooks/genres/useGenres'

import {
  addGenre,
  initialGenre,
  genreValidationSchema,
  updateGenre,
} from 'utils/genres'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const GenreDialog = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()

  const isNew = !id
  const { mutate: mutateGenres } = useGenres({
    mapById: true,
  })
  const {
    genre,
    isLoading: isLoadingGenre,
    mutate: mutateGenreById,
  } = useGenreById(!isNew && id)

  const isLoading = isLoadingGenre

  const handleSubmit = async (genreData) => {
    let action
    let messages
    try {
      if (isNew) {
        action = addGenre
        messages = {
          success: 'Successfully added genre',
          error: 'Failed to save genre',
        }
      } else {
        action = updateGenre
        messages = {
          success: 'Successfully updated genre',
          error: 'Failed to update genre',
        }
      }
      await action(genreData)
      mutateGenres()
      mutateGenreById()
      popSnackbar('success', messages.success)
    } catch (error) {
      popSnackbar('error', messages.error)
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${isNew ? 'Add' : 'Edit'} Genre`}</DialogTitle>
      {!isNew && isLoading ? (
        <DialogContent className={classes.loading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          validationSchema={genreValidationSchema}
          initialValues={isNew ? initialGenre : genre}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Enter genre name"
                  required
                />
                <SelectGenres
                  name="relatedGenres"
                  label="Related Genres"
                  placeholder="Select related genres"
                  multiple
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default GenreDialog
