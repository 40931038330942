import { useMemo } from 'react'

import useSWR from 'swr'

export const useRegions = ({ mapById = false, metadataFields = [] } = {}) => {
  const queries = []

  const metadata = metadataFields?.join(',')
  if (metadata?.length) queries.push(`metadata=${metadata}`)

  const key = '/api/regions?' + queries.join('&')
  const { data, isValidating, error, ...rest } = useSWR(key)

  const regions = useMemo(() => {
    if (!mapById) return data

    return data?.reduce((acc, curr) => {
      return { ...acc, [curr._id]: curr }
    }, {})
  }, [data, mapById])

  return {
    regions,
    isLoading: isValidating && !data,
    isError: !!error,
    ...rest,
  }
}

export const useRegionById = (id) => {
  const { data, isValidating, ...rest } = useSWR(id && `/api/regions/${id}`)

  return {
    region: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useRegionByName = (name) => {
  const { data, isValidating, ...rest } = useSWR(
    name && `/api/regions?name=${name}`
  )

  return {
    region: data?.length && data[0],
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useRegionsByParentId = (parentId) => {
  const { data, isValidating, ...rest } = useSWR(
    parentId && `/api/regions?parentId=${parentId}`
  )

  return {
    regions: data,
    isLoading: isValidating && !data,
    ...rest,
  }
}

export const useDefaultRegion = () => useRegionByName('Worldwide')
