import { ListItem, withStyles } from '@material-ui/core'

export default withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    borderRadius: 30,
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
    '&:focus': {
      backgroundColor: theme.palette.controls.focus,
    },
    '&:hover': {
      backgroundColor: theme.palette.controls.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.controls.selected,
      '&:hover': {
        backgroundColor: theme.palette.controls.hoverSelected,
      },
      boxShadow: theme.shadows[1],
    },
  },
}))(ListItem)
