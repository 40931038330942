import React from 'react'
import { Form, Formik } from 'formik'

import PopupCard from '../PopupCard'
import SelectArtists from 'components/SelectArtists'

import yup from 'schemas/yup'
import { useArtistsById } from 'hooks/artists/useArtists'
import { CircularProgress } from '@material-ui/core'

const validationSchema = yup.object({
  artist: yup
    .object({
      _id: yup.string().min(1).required(),
    })
    .required(),
})

export const ArtistInput = ({ initialValues, onChange, onCancel }) => {
  const paramName = 'artist'

  const { artist: initialArtist, isLoading } = useArtistsById(
    initialValues.artist
  )

  const handleOnSubmit = (values) => {
    onChange(paramName, values[paramName]._id)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Formik
          validateOnMount
          validateOnChange
          initialValues={{ [paramName]: initialArtist }}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <PopupCard onCancel={onCancel}>
              <SelectArtists
                name={paramName}
                label="Artist"
                fullWidth
                size="small"
                disableClearable
                multiple={false}
                placeholder="Select a artist"
              />
            </PopupCard>
          </Form>
        </Formik>
      )}
    </>
  )
}

export const ArtistLabel = ({ value }) => {
  const { artist } = useArtistsById(value)
  return artist?.name || '-'
}
