import React, { useState } from 'react'
import { makeStyles, Button } from '@material-ui/core'

import Header from 'components/Header'
import RegionsTable from 'components/regions/RegionsTable'
import RegionDialog from 'components/regions/RegionDialog'

import RegionConfirmDeleteDialog from 'components/regions/RegionConfirmDeleteDialog'
import { useDefaultRegion } from 'hooks/regions/useRegions'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    padding: theme.spacing(5),
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const Regions = () => {
  const classes = useStyles()
  const { region: defaultRegion } = useDefaultRegion()
  const [openDialog, setOpenDialog] = useState('')
  const [selection, setSelection] = useState([])

  const handleOnDelete = () => {
    setOpenDialog('deleteRegions')
  }

  const handleOnAdd = () => {
    setOpenDialog('addRegion')
  }

  const handleOnClose = () => {
    setOpenDialog('')
  }

  const selectionForDeletion = selection.filter(
    (id) => id !== defaultRegion?._id
  )

  return (
    <div className={classes.root}>
      <Header title="Regions">
        <Button
          variant="outlined"
          color="secondary"
          disabled={!selectionForDeletion.length}
          onClick={handleOnDelete}
        >
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleOnAdd}>
          Add new region
        </Button>
        {openDialog === 'addRegion' && (
          <RegionDialog open onClose={handleOnClose} />
        )}
      </Header>
      <RegionsTable selection={selection} onSelectionChange={setSelection} />
      <RegionConfirmDeleteDialog
        ids={selection}
        onClose={handleOnClose}
        onDelete={() => {
          setSelection([])
          handleOnClose()
        }}
        open={openDialog === 'deleteRegions'}
      />
    </div>
  )
}

export default Regions
