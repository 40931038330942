import React, { useContext, createContext } from 'react'

import { useArtistsById } from 'hooks/artists/useArtists'
import { usePlaylists } from 'hooks/playlists/usePlaylist'

const ArtistContext = createContext()

const ArtistProvider = ({ id, children }) => {
  const {
    artist,
    isLoading: isLoadingArtist,
    mutate: mutateArtist,
  } = useArtistsById(id)

  const {
    playlists: playlistsFeaturingArtist,
    isLoading: isLoadingPlaylistsFeaturingArtist,
    mutate: mutatePlaylistsFeaturingArtist,
  } = usePlaylists({ featuredArtist: id })

  const {
    playlists: playlistsRelatedToArtist,
    isLoading: isLoadingPlaylistsRelatedToArtist,
    mutate: mutatePlaylistsRelatedToArtist,
  } = usePlaylists({ relatedArtist: id })

  const isLoading = isLoadingArtist || isLoadingPlaylistsFeaturingArtist

  const value = {
    artistId: id,
    isLoading,
    artist,
    isLoadingArtist,
    mutateArtist,
    playlistsFeaturingArtist,
    isLoadingPlaylistsFeaturingArtist,
    mutatePlaylistsFeaturingArtist,
    playlistsRelatedToArtist,
    isLoadingPlaylistsRelatedToArtist,
    mutatePlaylistsRelatedToArtist,
  }

  return (
    <ArtistContext.Provider value={value}>{children}</ArtistContext.Provider>
  )
}

const ArtistConsumer = ({ children }) => {
  return (
    <ArtistContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('ArtistConsumer must be used within a ArtistProvider')
        }
        return children(context)
      }}
    </ArtistContext.Consumer>
  )
}

const useArtistContext = () => {
  const context = useContext(ArtistContext)
  if (context === undefined) {
    throw new Error('useArtistContext must be used within a ArtistProvider')
  }
  return context
}

export { ArtistProvider, ArtistConsumer, useArtistContext }
