import React, { useState } from 'react'
import { Formik, Form } from 'formik'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Dialog,
} from '@material-ui/core'

import ArtistForm from 'components/artist/ArtistForm'

import { updateArtist } from 'utils/artists'
import artistValidationSchema from 'schemas/artist'

import { useSnackbar } from 'hooks/useSnackbar'
import { useArtistContext } from 'hooks/artists/useArtistContext'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignSelf: 'center',
    margin: theme.spacing(15),
  },
}))

const ArtistDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { popSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { artist, mutateArtist } = useArtistContext()

  const handleSubmit = async (artistData) => {
    setIsSubmitting(true)

    try {
      await updateArtist(artistData)
      mutateArtist()
      popSnackbar('success', 'Successfully updated artist')
    } catch (error) {
      popSnackbar('error', 'Failed to update artist')
    }

    setIsSubmitting(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit details</DialogTitle>
      {artist && (
        <Formik
          validationSchema={artistValidationSchema}
          initialValues={artist}
          isInitialValid={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <ArtistForm />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid || isSubmitting}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default ArtistDialog
