import React from 'react'

import ConfirmDialog from 'components/ConfirmDialog'

import { useClips } from 'hooks/clips/useClips'
import { useSnackbar } from 'hooks/useSnackbar'

import { deleteClipsByIds } from 'utils/clips'

const ClipsConfirmDeleteDialog = ({ ids, open, onDelete, onClose }) => {
  const { popSnackbar } = useSnackbar()
  const { mutate: mutateClips } = useClips()

  const handleDelete = async () => {
    try {
      await deleteClipsByIds(ids)
      mutateClips()
      onDelete && onDelete()
      popSnackbar('success', 'Clip deleted successfuly')
    } catch (error) {
      popSnackbar('error', 'Could not deleted clip')
    }
    onClose()
  }

  return (
    <ConfirmDialog
      title="Delete clips"
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      labels={{ confirm: 'Delete', reject: 'Cancel' }}
    >
      Are you sure you want to delete these clips?
      <br />
      All playlist and other entities referencing these clips will be affected.
    </ConfirmDialog>
  )
}

export default ClipsConfirmDeleteDialog
