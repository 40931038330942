import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import TrippiclipsTable, {
  DEFAULT_TABLE_PAGE_SIZE,
} from 'components/table/Table'

import usePlaylistsColumns from 'hooks/playlists/usePlaylistsColumns'
import { useClipRelatedPlaylists } from 'hooks/playlists/usePlaylist'

const useStyles = makeStyles({
  container: {
    boxShadow: 'none',
  },
})

const PlaylistsList = ({ clipId }) => {
  const classes = useStyles()
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE)
  const [currentPage, setCurrentPage] = useState(0)

  const { playlists, totalCount, isLoading } = useClipRelatedPlaylists({
    skip: pageSize * currentPage,
    limit: pageSize,
    clipId,
  })

  const columns = usePlaylistsColumns([
    'thumbnail',
    'name',
    'regions',
    'genres',
  ])

  return (
    <div>
      <TrippiclipsTable
        classes={{ container: classes.container }}
        rows={playlists || []}
        columns={columns}
        isLoading={isLoading}
        hideSelection
        hideToolbar
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        currentPage={currentPage}
        onChangeCurrentPage={setCurrentPage}
        totalCount={totalCount}
      />
    </div>
  )
}

export default PlaylistsList
