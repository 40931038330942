import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'

import Navbar from 'components/Navbar'
import SidebarMenu from 'components/SidebarMenu'

import Clip from './Clip'
import Clips from './Clips'
import Artist from './Artist'
import Artists from './Artists'
import Regions from './Regions'
import Recipes from './Recipes'
import Playlist from './Playlist'
import Playlists from './Playlists'
import Dashboard from './Dashboard'
import AdminMainPage from './admin/AdminMainPage'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'auto',
  },
  toolbarSpacer: {
    height: theme.spacing(6),
    flex: 'none',
  },
}))

const MainPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Navbar />
      <SidebarMenu />
      <main className={classes.main}>
        <div className={classes.toolbarSpacer} />
        <div className={classes.content}>
          <Switch>
            <Route path="/clips/:id">
              <Clip />
            </Route>
            <Route path="/clips">
              <Clips />
            </Route>
            <Route path="/playlists/:id">
              <Playlist />
            </Route>
            <Route path="/playlists">
              <Playlists />
            </Route>
            <Route path="/recipes/:id?">
              <Recipes />
            </Route>
            <Route path="/artists/:id">
              <Artist />
            </Route>
            <Route path="/artists">
              <Artists />
            </Route>
            <Route path="/regions">
              <Regions />
            </Route>
            <Route path="/admin">
              <AdminMainPage />
            </Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  )
}

export default MainPage
