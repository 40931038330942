import 'react-phone-number-input/style.css'

import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useDebouncedCallback } from 'use-debounce'
import PhoneInput from 'react-phone-number-input'

import { FormControl, FormHelperText, FormLabel } from '@material-ui/core'

import MuiTextFieldForPhoneInput from './MuiTextFieldForPhoneInput'

const PhoneNumberInput = ({
  name,
  label,
  required,
  debounce = 1000,
  helperText,
}) => {
  const [, meta, helpers] = useField(name)

  const [value, setValue] = useState(meta.value ?? '')

  const debouncedSetFieldValue = useDebouncedCallback((value) => {
    helpers.setValue(value).then(() => helpers.setTouched(true))
  }, debounce)

  useEffect(() => {
    setValue(meta.value ?? '')
  }, [meta.value])

  const error = meta.touched && meta.error

  const handleDebouncedOnChange = (newValue) => {
    setValue(newValue)
    debouncedSetFieldValue(newValue)
  }

  return (
    <FormControl required={required} margin="normal" error={!!error}>
      <FormLabel>{label}</FormLabel>
      <PhoneInput
        value={value ?? ''}
        onChange={handleDebouncedOnChange}
        inputComponent={MuiTextFieldForPhoneInput}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default PhoneNumberInput
